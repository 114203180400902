import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  Account,
  AccountService
} from "@onsip/common/services/api/resources/account/account.service";
import {
  AgentTransaction,
  AgentTransactionType
} from "@onsip/common/services/api/resources/agentTransaction/agent-transaction";
import { AgentTransactionService } from "@onsip/common/services/api/resources/agentTransaction/agent-transaction.service";
import { Subscription } from "rxjs";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";

// turning the enum into a string literal converts the enum values in an union type of the values
type FilterType = "any" | `${AgentTransactionType}`;

@Component({
  selector: "onsip-agent-commissions",
  templateUrl: "./agent-commissions-page.component.html",
  styleUrls: ["./agent-commissions-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentAdminCommissionsPageComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  @HostBinding("class.onsip-admin-host-grid")
  _dontUse = true;
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  filterType = ["any"].concat(Object.values(AgentTransactionType));
  dataSource = new MatTableDataSource<AgentTransaction>([]);
  agentAccount!: Account;
  displayedColumns = ["transactionId", "date", "type", "amount", "actions"];
  pageSize = 12;
  search = new FormControl("", { nonNullable: true });
  filterOption = new FormControl<FilterType>("any", { nonNullable: true });
  allCommissions!: Array<AgentTransaction>;
  selectedRow: AgentTransaction | undefined;

  private unsubscriber = new Subscription();

  constructor(
    private agentTransactionService: AgentTransactionService,
    private accountService: AccountService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.accountService.accountRead().then(res => {
      if (res.status === "success") {
        const agentAccount = Object.values(res.data)[0];
        this.agentAccount = agentAccount;
        if (agentAccount.agentId) {
          this.agentTransactionService
            .agentTransactionBrowse({ AgentId: agentAccount.agentId })
            .then(result => {
              if (result.status === "success") {
                this.allCommissions = Object.values(result.data).sort(
                  (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
                );
                this.dataSource.data = this.allCommissions;
              } else {
                this.snackbarService.openSnackBar(result.data.message, "error");
                this.agentTransactionService.clearErrors();
              }
            });
        }
      } else {
        console.error(res.data.message);
        this.snackbarService.openSnackBar(res.data.message, "error");
        this.accountService.clearErrors();
      }
    });
    this.unsubscriber.add(
      this.search.valueChanges.subscribe(value => {
        this.dataSource.filter = value.trim().toLowerCase();
        this.dataSource.paginator?.firstPage();
      })
    );

    this.unsubscriber.add(
      this.filterOption.valueChanges.subscribe((value: FilterType) => {
        if (value === "any") {
          this.dataSource.data = this.allCommissions;
        } else {
          this.dataSource.data = this.allCommissions.filter(
            commission => commission.type === value
          );
        }
        this.dataSource.paginator?.firstPage();
      })
    );

    this.initSorting();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  trackByTransactionId(index: number, item: AgentTransaction): string {
    return item.agentTransactionId;
  }

  private initSorting(): void {
    this.dataSource.sortingDataAccessor = (item: AgentTransaction, columnName: string) =>
      this.getItemValueByColumn(item, columnName);
  }

  private getItemValueByColumn(item: AgentTransaction, columnName: string): string | number {
    switch (columnName) {
      case "transactionId":
        return parseInt(item.agentTransactionId);
      case "date":
        return new Date(item.date).getTime();
      case "type":
        return item.type;
      case "amount":
        return parseFloat(item.amount);
      default:
        return parseInt(item.agentTransactionId);
    }
  }
}
