import { Directive, forwardRef, Input } from "@angular/core";
import { NG_VALIDATORS } from "@angular/forms";

@Directive({
  selector: "[onsipValidateTimeShift][ngModel],[onsipValidateTimeShift][formControl]",
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => ValidateTimeShiftDirective),
      multi: true
    }
  ]
})
export class ValidateTimeShiftDirective {
  @Input() timeShiftValidator: any;
  @Input() bhrShift: any;

  validate(): void {
    return this.timeShiftValidator(this.bhrShift);
  }
}
