<!-- Main header (2 parts) -->
<ng-template #callHistoryHeader>
  <onsip-header-main class="call-history-header">
    @if (!isMobile) {
    <onsip-header-page-title>
      {{
        (hasVoicemailbox | async)
          ? ("ONSIP_I18N.CALL_HISTORY__VOICEMAILS" | translate | sentenceCase)
          : ("ONSIP_I18N.CALL_HISTORY" | translate | sentenceCase)
      }}
    </onsip-header-page-title>
    }
    <onsip-header-action-bar>
      <mat-form-field appearance="outline">
        <input
          matInput
          [formControl]="callHistorySearch"
          [placeholder]="'ONSIP_I18N.CALL_HISTORY' | translate"
          autocomplete="off"
        />
        <mat-icon matSuffix color="primary">search</mat-icon>
      </mat-form-field>
    </onsip-header-action-bar>
  </onsip-header-main>
</ng-template>
<ng-template #voicemailHeader>
  <onsip-header-main class="voicemail-header">
    @if (!isMobile) {
    <onsip-header-page-title>&nbsp;</onsip-header-page-title>
    }
    <!-- use this to maintain space -->
    <onsip-header-action-bar>
      <mat-form-field appearance="outline">
        <input
          matInput
          [formControl]="voicemailSearch"
          [placeholder]="'ONSIP_I18N.VOICEMAIL' | translate | sentenceCase"
          autocomplete="off"
        />
        <mat-icon matSuffix color="primary">search</mat-icon>
      </mat-form-field>
      <button
        mat-stroked-button
        color="primary"
        onsip-header-secondary-action
        onsipVoicemailSettingsModalOpen
      >
        <mat-icon>settings</mat-icon>
        {{ "ONSIP_I18N.SETTINGS" | translate }}
      </button>
    </onsip-header-action-bar>
  </onsip-header-main>
</ng-template>
<!-- Desktop -->
@if (!isMobile) {
<ng-container *ngTemplateOutlet="callHistoryHeader"></ng-container>
@if (hasVoicemailbox | async) {
<ng-container *ngTemplateOutlet="voicemailHeader"></ng-container>
}
<onsip-recent-calls
  [class.full-width]="!!(hasVoicemailbox | async) === false"
  [search]="(callHistorySearch.valueChanges | async)!"
></onsip-recent-calls>
@if (hasVoicemailbox | async) {
<onsip-voicemail [search]="(voicemailSearch.valueChanges | async)!"></onsip-voicemail>
} }
<!-- Mobile -->
@if (isMobile) {
<onsip-header-main>
  <onsip-header-page-title>
    {{
      (hasVoicemailbox | async)
        ? ("ONSIP_I18N.CALL_HISTORY__VOICEMAILS" | translate | sentenceCase)
        : ("ONSIP_I18N.CALL_HISTORY" | translate | sentenceCase)
    }}
  </onsip-header-page-title>
</onsip-header-main>
@if (hasVoicemailbox | async) {
<mat-tab-group onsip-tab-group-custom-width onsip-tab-group-primary>
  <mat-tab class="onsip-mat-tab">
    <ng-template mat-tab-label>
      <h4>{{ "ONSIP_I18N.CALL_HISTORY" | translate }}</h4>
    </ng-template>
    <ng-container *ngTemplateOutlet="callHistoryHeader"></ng-container>
    <onsip-recent-calls
      [isMobile]="true"
      [search]="(callHistorySearch.valueChanges | async)!"
    ></onsip-recent-calls>
  </mat-tab>
  @if (hasVoicemailbox | async) {
  <mat-tab class="onsip-mat-tab" label="Voicemail">
    <ng-template mat-tab-label>
      <h4>{{ "ONSIP_I18N.VOICEMAIL" | translate }}</h4>
      @if (msgCount | async; as count) {
      <span [matBadge]="count" matBadgePosition="after" matBadgeOverlap="false"></span>
      }
    </ng-template>
    <ng-container *ngTemplateOutlet="voicemailHeader"></ng-container>
    <onsip-voicemail
      [isMobile]="true"
      [search]="(voicemailSearch.valueChanges | async)!"
    ></onsip-voicemail>
  </mat-tab>
  }
</mat-tab-group>
} @if (!!(hasVoicemailbox | async) === false) {
<onsip-recent-calls [isMobile]="true"></onsip-recent-calls>
} }
