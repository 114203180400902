import {
  Component,
  OnInit,
  OnDestroy,
  HostBinding,
  ChangeDetectionStrategy,
  ViewChild
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FormControl } from "@angular/forms";
import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { map, switchMapTo } from "rxjs/operators";
import { QueueInfo, Presentity } from "../../../../common/interfaces/presentity";
import {
  AdvQueueService,
  AdvQueueObject
} from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { SubscriptionControllerService } from "../../../../common/services/subscription-controller.service";
import { ContactService } from "../../../../common/services/contact/contact.service";
import { UserCustomizationService } from "../../../../common/services/api/resources/userCustomization/user-customization.service";

import { QueueSnapshot, QueueAgent, QueueOverview } from "./queue-realtime-types.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  templateUrl: "./queue-realtime.component.html",
  styleUrls: ["./queue-realtime.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueRealtimeComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.queueTableData.paginator = paginator;
  }

  readonly waitDefaultTime = "00:00";
  queueSnapshots: Array<QueueSnapshot> = [];
  queueTableData = new MatTableDataSource<QueueSnapshot>([]);
  tableLoaded = new BehaviorSubject<boolean>(false);
  hasQueue = new BehaviorSubject<boolean>(false);
  pageSize = 15;
  displayedColumns: Array<string> = [
    "queue-name",
    "queue-max-wait",
    "queue-waiting",
    "queue-on-call",
    "queue-abandoned",
    "queue-completed",
    "queue-failover"
  ];
  expandedElements: Array<QueueSnapshot> = [];
  hasQueueReport = false;
  isMobile!: Observable<boolean>; // less than 600px width
  isTablet!: Observable<boolean>; // between 600px and 1280px width
  search = new FormControl("");

  private showMobile = false;
  private unsubscriber = new Subscription();

  constructor(
    private queueService: AdvQueueService,
    private subscriptionControllerService: SubscriptionControllerService,
    private contactService: ContactService,
    private userCustomization: UserCustomizationService,
    private breakpointObserver: BreakpointObserver
  ) {}

  // sorts agent in order of Available, On Call, then Offline
  agentStatusSort(agentA: QueueAgent, agentB: QueueAgent): number {
    if (agentA.callStatus === agentB.callStatus) {
      if (agentA.name < agentB.name) {
        return -1;
      } else if (agentB.name < agentA.name) {
        return 1;
      }
      return 0;
    } else if (agentA.callStatus === "Offline") {
      return 1;
    } else if (agentB.callStatus === "Offline") {
      return -1;
    } else if (agentA.callStatus === "On Call") {
      return 1;
    } else if (agentB.callStatus === "On Call") {
      return -1;
    }
    return 0;
  }

  applyFilter(event: string | null) {
    if (event) {
      const filterValue = event.toLowerCase();
      this.queueTableData.filter = filterValue;
      this.queueTableData.paginator?.firstPage();
    } else {
      this.queueTableData.filter = "";
    }
  }

  ngOnInit(): void {
    this.unsubscriber.add(
      this.queueService
        .getSmartQueueObs()
        .pipe(
          map(queues => {
            this.queueSnapshots = [];
            this.createSnapshots(queues);
          }),
          switchMapTo(this.subscriptionControllerService.state)
        )
        .subscribe(subscriptionState => {
          subscriptionState.presentity
            .filter(pres => pres.event === "onsip-queue")
            .forEach(pres => {
              this.updateSubscription(pres);
            });
        })
    );
    this.unsubscriber.add(
      this.userCustomization.orgState.subscribe(substate => {
        this.queueSnapshots.forEach(queue => {
          queue.orderedAgents.forEach(agent => {
            const agentWithUid = this.contactService.findUsingAOR(agent.uri);
            const agentUserCustomization = agentWithUid
              ? substate.find(user => user.userId === agentWithUid.userId)
              : undefined;
            const newAvatar = agentUserCustomization
              ? agentUserCustomization.userAvatarUrl
              : agentWithUid?.avatarUrl;
            if (agent.avatarUrl !== newAvatar) agent.avatarUrl = newAvatar;
          });
        });
      })
    );

    this.unsubscriber.add(this.search.valueChanges.subscribe(value => this.applyFilter(value)));

    this.isMobile = this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .pipe(map(result => result.matches));
    this.isTablet = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Medium])
      .pipe(map(result => result.matches));

    this.unsubscriber.add(
      this.isMobile.subscribe(isMobile => {
        this.showMobile = isMobile;
        this.queueTableData.data = this.queueSnapshots;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  openQueueReport(): void {
    this.hasQueueReport = !this.hasQueueReport;
  }

  showMobileRow(): boolean {
    return this.showMobile;
  }

  showDesktopRow(): boolean {
    return !this.showMobile;
  }

  /* END APP ENGINE FUNCTIONS */

  private createSnapshots(queues: Array<AdvQueueObject>): void {
    queues.forEach(subscribingQueue => {
      const sipAddress: string = subscribingQueue.address,
        snapshot: QueueSnapshot = {
          AdvQueue: subscribingQueue,
          name: subscribingQueue.displayName,
          uri: sipAddress,
          firstTime: true,
          // WILL BE OVERWRITTEN
          overview: {
            waiting: [
              {
                name: "",
                eventTime: Infinity,
                enqueueTime: Infinity
              }
            ],
            // these lists look like the one above
            onCall: [],
            completed: [],
            abandoned: [],
            failed: [] // regardless of whether there's a failover address
          },
          // WILL BE OVERWRITTEN
          agents: [
            {
              name: "",
              uri: "",
              avatarUrl: "",
              callStatus: "",
              loggedIn: false,
              eventTime: Infinity,
              lastLogin: Date.now()
            }
          ],
          orderedAgents: []
        };
      // If we fail to run the subscribe/notify, we don't want to display placeholder data.
      snapshot.overview.waiting = [];
      snapshot.agents = [];

      this.queueSnapshots.push(snapshot);
    });
    this.queueTableData.data = this.queueSnapshots;
    this.hasQueue.next(this.queueSnapshots.length > 0);
  }

  private updateSubscription(presentity: Presentity): void {
    // DEBUG FOR https://app.shortcut.com/onsip/story/21320/queue-waiting-time-inaccurate
    try {
      console.log("queue-realtime-debug", JSON.stringify(presentity));
    } catch (e) {
      // ignore
    }

    const snapshot: QueueSnapshot | undefined = this.queueSnapshots.find(
      snap => snap.uri === presentity.aor
    );
    if (!snapshot) return;
    const update = presentity.eventData as QueueInfo;
    if (!update.overview.waiting) {
      return;
    }
    snapshot.overview = update.overview;
    snapshot.agents = update.agents;
    if (snapshot.firstTime) {
      snapshot.orderedAgents = snapshot.agents;
    }

    snapshot.orderedAgents.forEach(agent => {
      if (agent.uri) {
        const agentContactInfo = this.contactService.findUsingAOR(agent.uri);
        if (agentContactInfo && agent.avatarUrl !== agentContactInfo.avatarUrl) {
          agent.avatarUrl = agentContactInfo.avatarUrl;
        }
      }
      const updatedAgent: QueueAgent | undefined = update.agents.find(
        (uAgent: any) => uAgent.uri === agent.uri
      );
      if (!updatedAgent) {
        return;
      }
      const updatedAgentParams = Object.keys(updatedAgent) as Array<keyof QueueAgent>;
      updatedAgentParams.forEach(<T extends keyof QueueAgent>(param: T) => {
        if (agent[param] !== updatedAgent[param]) agent[param] = updatedAgent[param];
      });
    });
    snapshot.orderedAgents.sort(this.agentStatusSort);

    snapshot.firstTime = false;

    const overview: QueueOverview = snapshot.overview;

    if (overview.waiting.length > 0) {
      const waitTimes = overview.waiting.map(caller => caller.eventTime);
      snapshot.displayWaitTime = Math.min(...waitTimes);
    } else {
      snapshot.displayWaitTime = this.waitDefaultTime;
    }
    this.tableLoaded.next(true);
  }
}
