import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import { AccountService } from "@onsip/common/services/api/resources/account/account.service";
import { Account } from "@onsip/common/services/api/resources/account/account.service";
import { SubAgentAccountService } from "@onsip/common/services/api/resources/subAgent/sub-agent-account.service";
import { combineLatest, lastValueFrom, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import { views } from "@onsip/web/app/phone/views";
import { currencyFormatter } from "../../shared/helpers/currency-formatter";
import {
  getHighestRolePriority,
  isAgentAdmin,
  isAtLeastAccountAdminRole,
  isSubAgentAdmin,
  Role
} from "@onsip/common/services/api/role";
import { UserService, User } from "@onsip/common/services/api/resources/user/user.service";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Router } from "@angular/router";
import { SubAgentAccount } from "@onsip/common/services/api/resources/subAgent/sub-agent-account";
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../shared/const";

export interface AccountWithSubAgent extends Account {
  subAgentName?: string;
  subAgentAccountId?: string;
  dateAssigned?: string;
  contactName: string;
  contactOrganization: string;
}

export interface SubAgentObject {
  subAgentName: string;
  subAgentAccountId: string;
  dateAssigned: string;
}

@Component({
  selector: "onsip-agent-accounts",
  templateUrl: "./agent-accounts-page.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./agent-accounts-page.component.scss"]
})
export class AgentAdminAccountsPageComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (!this.dataSource?.sort && sort && this.defaultSortingColumn) {
      sort.sort({ id: this.defaultSortingColumn, disableClear: false, start: "asc" });
    }
    this.dataSource.sort = sort;
  }

  views = views;
  currencyFormatter = currencyFormatter;
  defaultSortingColumn = "accountId";
  allAccounts!: Array<AccountWithSubAgent>;
  dataSource = new MatTableDataSource<AccountWithSubAgent | SubAgentAccount>([]);
  displayedColumns = [
    "accountId",
    "subAgent",
    "contactName",
    "accountName",
    "creditLimit",
    "prepaidBal",
    "invoiceBal",
    "actions"
  ];
  selectedRow: AccountWithSubAgent | undefined;
  search = new FormControl("", { nonNullable: true });
  pageSize = PAGE_SIZE;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  agentId!: string;
  accountIdAdminUserMap: Map<string, User> = new Map<string, User>();
  isSubAgent = false;
  subAgentAccountId!: string;

  private unsubscriber = new Subscription();

  constructor(
    private accountService: AccountService,
    private subAgentAccountService: SubAgentAccountService,
    private userService: UserService,
    private apiSessionService: ApiSessionService,
    private snackbarService: SnackbarService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initFields();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  trackByAccountId(index: number, item: AccountWithSubAgent | SubAgentAccount): string {
    return item.accountId;
  }

  spoofAccount(result: AccountWithSubAgent | SubAgentAccount, event: Event): void {
    event.stopPropagation();
    const accountId = "subAccountId" in result ? result.subAccountId : result.accountId;
    this.findUserData(accountId).then(user => {
      if (user && getHighestRolePriority(user.roles) !== Role.SuperUser) {
        this.apiSessionService
          .agentSessionSubstituteUser(user.userId)
          .then(() => {
            const role = this.apiSessionService.stateValue.role;
            if (role) {
              // when a SubAgent spoofs, they should not be routed to Agent Accounts since they do not have permission to spoof other SubAgents
              if (this.isSubAgent) {
                this.router.navigate([views.ADMIN_PBX_OVERVIEW]);
                return;
              }
              if (isAgentAdmin([role]) || isSubAgentAdmin([role])) {
                this.router.navigate([views.AGENT_ACCOUNTS]);
              } else if (isAtLeastAccountAdminRole([role])) {
                // should be pbx overview page when that is done
                this.router.navigate([views.ADMIN_PBX_OVERVIEW]);
              } else {
                this.router.navigate([views.HOME]);
              }
            }
          })
          .catch(err => {
            this.snackbarService.openSnackBar(err, "error");
          });
      } else {
        this.snackbarService.openSnackBar("No eligible users in account.", "error");
      }
    });
  }

  getSubAgentClass() {
    return {
      "subagent-table": this.isSubAgent
    };
  }

  private async initFields(): Promise<void> {
    await this.checkIfSubAgent();
    if (this.isSubAgent) {
      await this.initSubAgentDataSource();
    } else {
      this.initAgentDataSource();
      this.getAgentAccounts();
    }
    this.initSorting();
    this.initSearch();
  }

  private checkIfSubAgent(): Promise<void> {
    return lastValueFrom(
      this.userService.selfUser.pipe(
        take(1),
        map(user => {
          this.isSubAgent = isSubAgentAdmin(user.roles);
          if (this.isSubAgent) {
            this.displayedColumns = [
              "accountId",
              "contactName",
              "accountName",
              "creditLimit",
              "prepaidBal",
              "invoiceBal"
            ];
            this.subAgentAccountId = user.accountId;
          }
          this.cdRef.markForCheck();
        })
      )
    );
  }

  private initSubAgentDataSource(): Promise<void> {
    return this.subAgentAccountService
      .subAgentAccountBrowse({ AccountId: this.subAgentAccountId })
      .then(res => {
        if (res.status === "success") {
          const subAgentAccounts = Object.values(res.data);
          this.dataSource.data = subAgentAccounts;
          this.cdRef.markForCheck();
        } else {
          console.error(res.data.message);
        }
      });
  }

  private getAgentAccounts(): void {
    this.accountService.accountBrowse().then(res => {
      if (res.status === "success") {
        this.agentId = Object.values(res.data)[0].agentId || "";
        this.subAgentAccountService.subAgentAccountBrowse({ AgentId: this.agentId });
      } else {
        console.error(res.data.message);
        this.accountService.clearErrors();
      }
    });
  }
  private initAgentDataSource(): void {
    this.unsubscriber.add(
      combineLatest({
        accounts: this.accountService.state.pipe(map(state => Object.values(state.state))),
        subAgentAccounts: this.subAgentAccountService.state.pipe(
          map(state => Object.values(state.state))
        )
      })
        .pipe(
          map(({ accounts, subAgentAccounts }) => {
            // map of subAgent key: subAccountID to val:subAccountContactName
            const subAgentIdToSubAcctMap: Map<string, SubAgentObject> = new Map<
              string,
              SubAgentObject
            >();
            subAgentAccounts.map(subAgentAccount => {
              subAgentIdToSubAcctMap.set(subAgentAccount.subAccountId, {
                subAgentName: subAgentAccount.accountContactName,
                subAgentAccountId: subAgentAccount.accountId,
                dateAssigned: subAgentAccount.created
              });
            });
            // loop through accounts, if the accountID is in the map, add accountContactName of subAgentAccount
            // to account object as subAgentName
            const accountsWithSubAgents = accounts
              .filter(account => !!account.accountId && account.agentId === this.agentId)
              .map(account => {
                const subAgentObj = subAgentIdToSubAcctMap.get(account.accountId);
                if (subAgentObj) {
                  return {
                    contactName: account.contact.name,
                    contactOrganization: account.contact.organization,
                    ...subAgentObj,
                    ...account
                  };
                } else {
                  return {
                    contactName: account.contact.name,
                    contactOrganization: account.contact.organization,
                    ...account
                  };
                }
              });
            return accountsWithSubAgents;
          })
        )
        .subscribe(accountsWithSub => {
          this.allAccounts = accountsWithSub;
          this.dataSource.data = accountsWithSub;
          this.cdRef.markForCheck();
        })
    );
  }

  private initSearch(): void {
    this.unsubscriber.add(
      this.search.valueChanges.subscribe(value => {
        this.dataSource.filter = value.trim().toLowerCase();
        this.dataSource.paginator?.firstPage();
      })
    );
  }

  private initSorting(): void {
    this.dataSource.sortingDataAccessor = (
      item: AccountWithSubAgent | SubAgentAccount,
      columnName: string
    ) => this.getItemValueByColumn(item, columnName);
  }

  private getItemValueByColumn(
    item: AccountWithSubAgent | SubAgentAccount,
    columnName: string
  ): string | number {
    switch (columnName) {
      case "accountId":
        return parseInt(
          this.isSubAgent && "subAccountId" in item ? item.subAccountId : item.accountId
        );
      case "subAgent":
        return "subAgentName" in item && item.subAgentName ? item.subAgentName : 0;
      case "contactName":
        return this.isSubAgent && "subAccountContactName" in item
          ? item.subAccountContactName
          : "contact" in item
          ? item.contact.name
          : "";
      case "accountName":
        return this.isSubAgent && "subAccountContactOrganization" in item
          ? item.subAccountContactOrganization
          : "contact" in item
          ? item.contact.organization
          : "";
      case "creditLimit":
        return parseInt(
          this.isSubAgent && "subAccountCreditLimit" in item
            ? item.subAccountCreditLimit
            : "creditLimit" in item
            ? item.creditLimit
            : "0"
        );
      case "prepaidBal":
        return parseInt(
          this.isSubAgent && "subAccountBalance" in item
            ? item.subAccountBalance
            : "balance" in item
            ? item.balance
            : "0"
        );
      case "invoiceBal":
        return parseInt(
          this.isSubAgent && "subAccountReceivable" in item
            ? item.subAccountReceivable
            : "receivable" in item
            ? item.receivable
            : "0"
        );
      default:
        return parseInt(
          this.isSubAgent && "subAccountId" in item ? item.subAccountId : item.accountId
        );
    }
  }

  private findUserData(accountId: string): Promise<User | undefined> {
    return this.userService
      .userBrowse({ AccountId: accountId, OrganizationId: undefined })
      .then(res => {
        if (res.status === "success") {
          // only allow SubAgents to spoof Account Admins
          const enabledUsers = Object.values(res.data).filter(
            user =>
              user.status === "enabled" &&
              (isSubAgentAdmin(user.roles) || user.roles.some(role => role === Role.AccountAdmin))
          );
          let accountAdminUser!: User;
          let minAccountId = Number.MAX_SAFE_INTEGER;
          enabledUsers.forEach(user => {
            if (parseInt(user.accountId) < minAccountId) {
              minAccountId = parseInt(user.accountId);
              accountAdminUser = user;
            }
          });
          return accountAdminUser ? accountAdminUser : undefined;
        } else {
          console.error(res.data.message);
          this.userService.clearErrors();
          return undefined;
        }
      });
  }
}
