import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { User, UserService } from "@onsip/common/services/api/resources/user/user.service";
import {
  getHighestRolePriority,
  isAgentAdmin,
  isAtLeastAccountAdminRole,
  isSubAgentAdmin,
  Role
} from "@onsip/common/services/api/role";
import { views } from "@onsip/web/app/phone/views";
import { filter, map, Observable, of, Subscription, take } from "rxjs";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";

export interface SuperUserTableData {
  contactName?: string;
  userId?: string;
  status?: string;
  accountId?: string;
  /** org name for the user */
  contactOrg?: string;
  domain?: string;
  email?: string;
  orgId?: string;
  /** contact name for the org */
  orgContact?: string;
  /** contact name for account */
  accountContact?: string;
  /** org name for the account */
  businessName?: string;
  /** this is the username for the user */
  username?: string;
  /** permission level of user */
  isAdmin?: boolean;
}

export type SuperUserTableType = "user" | "org" | "account" | "advUser" | "advOrg" | "advAccount";
@Component({
  selector: "onsip-super-user-table",
  templateUrl: "./super-user-table.component.html",
  styleUrls: ["./super-user-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperUserTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @Input() data: Array<SuperUserTableData> = [];
  /** informs what type of information the table is showing */
  @Input() tableType!: SuperUserTableType;

  dataSource = new MatTableDataSource<SuperUserTableData>([]);
  displayedColumns: Array<string> = [];
  views = views;

  private unsubscriber = new Subscription();

  constructor(
    private apiSessionService: ApiSessionService,
    private snackbarService: SnackbarService,
    private router: Router,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.updateDataSource();
  }

  ngOnChanges(): void {
    this.updateDataSource();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  updateDataSource(): void {
    this.dataSource.data = this.data;
    if (this.data.length) {
      this.displayedColumns = Object.keys(this.data[0]).filter(key => key !== "isAdmin");
    } else {
      this.displayedColumns = [];
    }
    this.dataSource.paginator?.firstPage();
  }

  /** apply different css classes to table depending on table type params */
  getTableClass() {
    return {
      "user-table": this.tableType === "user",
      "org-table": this.tableType === "org",
      "account-table": this.tableType === "account",
      "adv-user-table": this.tableType === "advUser",
      "adv-org-table": this.tableType === "advOrg",
      "adv-account-table": this.tableType === "advAccount"
    };
  }

  getRowClasses(row: SuperUserTableData) {
    return {
      "disabled-status": row.status === "disabled",
      "admin-user": row.isAdmin
    };
  }

  /** determine if column is sticky, only the first column should be sticky */
  isSticky(columnName: string): Observable<boolean> {
    // columns are not sticky at XSmall
    return this.breakpointObserver.observe(Breakpoints.XSmall).pipe(
      map(value => {
        const isXSmall = value.matches;
        if (isXSmall) return false;
        const firstColumn = this.displayedColumns[0];
        return firstColumn === columnName;
      })
    );
  }

  spoofUser(result: SuperUserTableData): void {
    if (result.status === "enabled" && result.userId) {
      this.unsubscriber.add(
        this.findUserData(result.userId)
          .pipe(take(1))
          .subscribe(user => {
            if (user && getHighestRolePriority(user.roles) !== Role.SuperUser) {
              this.apiSessionService
                .sessionSubstituteUser(user.userId)
                .then(() => {
                  const role = this.apiSessionService.stateValue.role;
                  if (role) {
                    if (isAgentAdmin([role])) {
                      this.router.navigate([views.AGENT_ACCOUNTS]);
                    } else if (isSubAgentAdmin([role])) {
                      this.router.navigate([views.SUB_AGENT_VIEW]);
                    } else if (isAtLeastAccountAdminRole([role])) {
                      // should be pbx overview page when that is done
                      this.router.navigate([views.ADMIN_PBX_OVERVIEW]);
                    } else {
                      this.router.navigate([views.HOME]);
                    }
                  }
                })
                .catch(err => {
                  this.snackbarService.openSnackBar(err, "error");
                });
            }
          })
      );
    }
  }

  // determine if the userId is clickable or not based on role and status of user
  getClickableClass(result: SuperUserTableData) {
    if (result.userId) {
      return this.findUserData(result.userId).pipe(
        map(user => {
          if (user) {
            return {
              "user-id-spoof":
                user.status === "enabled" && getHighestRolePriority(user.roles) !== Role.SuperUser
            };
          } else {
            return of({});
          }
        }),
        take(1)
      );
    } else {
      return of({});
    }
  }

  private findUserData(userId: string): Observable<User | undefined> {
    return this.userService.state.pipe(
      filter(state => !state.loading),
      map(state => Object.values(state.state).find(user => user.userId === userId))
    );
  }
}
