@if (messages) {
<section class="app-view voicemail">
  <!-- MAIN VM SECTION -->
  <div class="voicemail-section mat-typography">
    <!-- <nav class="voicemail-controls-container primary-50-bg">
      <span class="multiple-ua-container">
        <onsip-multiple-user-agent *ngIf="selectedMessage && expandedElement">
        </onsip-multiple-user-agent>
      </span>
    </nav> -->
    <!-- VM TABLE -->
    <onsip-table-two
      multiTemplateDataRows
      [dataSource]="voicemailTableData"
      [hoverable]="true"
      class="voicemail-table"
      [gridTemplateColumns]="'0.5fr 0.75fr 0.25fr repeat(3, 1fr)'"
    >
      <!-- Checked Column -->
      <ng-container matColumnDef="checked">
        <mat-header-cell class="checked-header" *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="
              voicemailSelect.hasValue() && voicemailSelect.selected.length === messages.length
            "
          ></mat-checkbox>
        </mat-header-cell>
        <mat-cell class="checked-cell" *matCellDef="let voicemail">
          <mat-checkbox
            color="primary"
            (change)="$event ? voicemailSelect.toggle(voicemail) : null"
            [checked]="voicemailSelect.isSelected(voicemail)"
            (click)="$event.stopPropagation()"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell class="date-header" *matHeaderCellDef>{{
          "ONSIP_I18N.DATE" | translate
        }}</mat-header-cell>
        <mat-cell class="date-cell" *matCellDef="let voicemail">{{
          voicemail.displayDate
        }}</mat-cell>
      </ng-container>
      <!-- Unread Column -->
      <ng-container matColumnDef="unread">
        <mat-header-cell class="unread-header" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="unread-cell" *matCellDef="let voicemail">
          @if (!voicemail.previouslyListened) {
          <mat-icon color="primary" class="unlistened-icon">lens</mat-icon>
          } @if (voicemail.previouslyListened) {
          <mat-icon></mat-icon>
          }
        </mat-cell>
      </ng-container>
      <!-- Time Column -->
      <ng-container matColumnDef="time">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.TIME" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let voicemail">{{ voicemail.displayTime }}</mat-cell>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.FROM" | translate }}</mat-header-cell>
        <mat-cell
          *matCellDef="let voicemail"
          class="name-column"
          [class.bold]="voicemail.shouldBeBold"
          >{{ voicemail.fromName }}</mat-cell
        >
      </ng-container>
      <!-- Duration Column -->
      <ng-container matColumnDef="duration">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.DURATION" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let voicemail">{{ voicemail.duration | duration }}</mat-cell>
      </ng-container>
      <!-- Expanded -->
      <ng-container matColumnDef="expanded">
        <onsip-table-shelf-cell
          *matCellDef="let voicemail"
          [attr.colspan]="displayedColumns.length"
          [expanded]="voicemail === selectedMessage"
          [override]="true"
        >
          @if (voicemail && voicemail.url && voicemail === selectedMessage) {
          <div class="voicemail-message-audio-container">
            <onsip-voicemail-message-audio
              [audioFile]="messageAudioSource"
              [voicemailMessage]="voicemail"
              [messageVolume]="volume"
            >
            </onsip-voicemail-message-audio>
          </div>
          } @if (!voicemail.url && voicemail.State === 'NOT_PRESENT') {
          <div>
            <span>{{
              "ONSIP_I18N.YOUR_MESSAGE_COULD_NOT_BE_RETRIEVED_AT_THIS_TIME" | translate
            }}</span>
          </div>
          }
        </onsip-table-shelf-cell>
      </ng-container>
      <!-- All Selected Row -->
      <ng-container matColumnDef="allSelected">
        <onsip-table-shelf-cell
          *matCellDef="let voicemail"
          [attr.colspan]="displayedColumns.length"
          [expanded]="
            voicemailSelect.hasValue() &&
            voicemailSelect.selected.length > 1 &&
            voicemailTableData.data.length > 0 &&
            voicemailTableData.data[0].id === voicemail.id
          "
          [override]="true"
        >
          <div class="all-selected-expanded">
            {{ voicemailSelect.selected.length === messages.length ? "All" : "" }}
            <b>{{ voicemailSelect.selected.length }}</b> voicemails selected.
            <a class="select-undo-button" (click)="masterToggle(true)">Undo</a>
          </div>
        </onsip-table-shelf-cell>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <cdk-cell *cdkCellDef="let voicemail">
          <div class="mobile-line heading" (click)="selectMessage(voicemail)">
            <div class="voicemail-heading">
              <span [class.bold]="voicemail.shouldBeBold"
                >{{ "ONSIP_I18N.FROM" | translate }} {{ voicemail.fromName }}</span
              >
            </div>
            <button
              mat-icon-button
              color="primary"
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{ voicemail: voicemail }"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div class="mobile-line">
            <span>{{ "ONSIP_I18N.DATE" | translate }}</span>
            <span class="right">{{ voicemail.displayDate }}</span>
          </div>
          <div class="mobile-line">
            <span>{{ "ONSIP_I18N.TIME" | translate }}</span>
            <span class="right">{{ voicemail.displayTime }}</span>
          </div>
          <div class="mobile-line">
            <span>{{ "ONSIP_I18N.DURATION" | translate }}</span>
            <span class="right">{{ voicemail.duration | duration }}</span>
          </div>
          @if (voicemail.state && selectedMessage === voicemail) {
          <div class="mobile-line">
            @if (voicemail.url) {
            <onsip-voicemail-message-audio
              style="display: flex; width: 100%"
              [audioFile]="messageAudioSource"
              [voicemailMessage]="voicemail"
              [messageVolume]="volume"
              [isMobile]="true"
            >
            </onsip-voicemail-message-audio>
            } @if (!voicemail.url && voicemail.state === 'NOT_PRESENT') {
            <span>{{
              "ONSIP_I18N.YOUR_MESSAGE_COULD_NOT_BE_RETRIEVED_AT_THIS_TIME" | translate
            }}</span>
            }
          </div>
          }
        </cdk-cell>
        <mat-menu #menu="matMenu">
          <ng-template matMenuContent let-voicemail="voicemail">
            <button
              id="onsip--voicemail--return-voice-call"
              aria-label="start voice call"
              onsipNewCall
              [contact]="voicemail.contact"
              [remoteAor]="voicemail.fromAor"
              [remoteDisplayName]="voicemail.fromName"
              mat-menu-item
              class="voicemail-mobile-button"
            >
              <mat-icon onsip-icon-outlined>phone</mat-icon
              ><span>{{ "ONSIP_I18N.CALL_BACK" | translate }}</span>
            </button>
            <button
              id="onsip--voicemail--return-video-call"
              aria-label="start video call"
              onsipNewCall
              [contact]="voicemail.contact"
              [remoteAor]="voicemail.fromAor"
              [remoteDisplayName]="voicemail.fromName"
              [video]="true"
              mat-menu-item
              class="voicemail-mobile-button"
            >
              <mat-icon onsip-icon-outlined>videocam</mat-icon
              ><span>{{ "ONSIP_I18N.VIDEO_CALL" | translate }}</span>
            </button>
            <button
              mat-menu-item
              [onsipAddContact]="voicemail.contact"
              class="voicemail-mobile-button"
            >
              <mat-icon>add_circle_outline</mat-icon
              ><span>{{
                voicemail.contact && voicemail.contact.custom
                  ? ("ONSIP_I18N.ADD_CUSTOM_CONTACT" | translate)
                  : ("ONSIP_I18N.ADD_NEW_CONTACT" | translate)
              }}</span>
            </button>
            <button
              mat-menu-item
              (click)="voicemailSelect.select(voicemail); deleteSelectedMessages()"
              class="voicemail-mobile-button"
            >
              <mat-icon onsip-icon-outlined>delete</mat-icon>
              <span>{{ "ONSIP_I18N.DELETE_SELECTED_VOICEMAIL" | translate }}</span>
            </button>
            <button mat-menu-item [disabled]="true" class="voicemail-mobile-button">
              <mat-icon onsip-icon-outlined>view_list</mat-icon>
              <span>{{ "ONSIP_I18N.VIEW_CALL_DETAILS" | translate }}</span>
            </button>
          </ng-template>
        </mat-menu>
      </ng-container>
      <mat-header-row *matHeaderRowDef="isMobile ? null : displayedColumns"></mat-header-row>
      <onsip-table-shelf
        *matRowDef="let row; columns: ['allSelected']; when: showDesktopRow.bind(this)"
      ></onsip-table-shelf>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; when: showDesktopRow.bind(this)"
        (click)="selectMessage(row)"
      ></mat-row>
      <onsip-table-shelf
        *matRowDef="let row; columns: ['expanded']; when: showDesktopRow.bind(this)"
      ></onsip-table-shelf>
      <onsip-row
        *matRowDef="let row; columns: ['mobile']; when: showMobileRow.bind(this)"
      ></onsip-row>
    </onsip-table-two>
    <!-- EMPTY STATE -->
    @if (messages.length === 0) {
    <div class="empty-state">
      <h4>{{ "ONSIP_I18N.NO_NEW_VOICEMAILS" | translate }}</h4>
    </div>
    }
    <!-- VOICEMAIL CONTROLS -->
    @if (!isMobile) {
    <div class="voicemail-controls">
      <span class="voicemail-volume-container">
        <mat-icon aria-label="volume_mute">volume_mute</mat-icon>
        <onsip-volume-slider [voicemail]="true" class="voicemail-volume-control">
        </onsip-volume-slider>
        <mat-icon class="volume-up" aria-label="volume_up">volume_up</mat-icon>
      </span>
      <div class="voicemail-control-button-container">
        <button
          id="onsip--voicemail--call-back"
          mat-stroked-button
          color="primary"
          aria-label="Call Back"
          class="voicemail-callback-button"
          onsipNewCall
          [contact]="selectedMessage ? selectedMessage.contact : undefined"
          [remoteAor]="selectedMessage ? selectedMessage.fromAor : undefined"
          [remoteDisplayName]="selectedMessage ? selectedMessage.fromName : undefined"
          [customDisable]="selectedMessage === undefined"
        >
          <mat-icon class="phone-icon" aria-label="phone">phone</mat-icon>
          <span>{{ "ONSIP_I18N.CALL_BACK" | translate }}</span>
        </button>
        <button
          id="onsip--voicemail--delete"
          mat-icon-button
          [matTooltip]="'ONSIP_I18N.DELETE_SELECTED_VOICEMAIL' | translate"
          matTooltipClass="onsip-tooltip-background"
          [disabled]="!voicemailSelect.hasValue()"
          class="voicemail-delete-icon"
          color="primary"
          (click)="deleteSelectedMessages()"
        >
          <mat-icon class="delete-icon" aria-label="delete">delete</mat-icon>
        </button>
      </div>
    </div>
    }
    <div class="paginator-container">
      <mat-paginator
        #paginator
        [length]="voicemailTableData.data.length"
        [pageSize]="pageSize | async"
        [hidePageSize]="true"
        [showFirstLastButtons]="false"
        onsip-paginator-primary-icon
      >
      </mat-paginator>
    </div>
  </div>
</section>
}
