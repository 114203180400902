import { HostListener, Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, RoutesRecognized } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Config } from "@onsip/common/config";
import { UUID } from "@onsip/common/libraries/sip";
import {
  isConnectedCallEvent,
  isConnectingCallEvent,
  isEndCallEvent,
  isNewCallEvent
} from "@onsip/common/libraries/sip/call-event";
import { CallState } from "@onsip/common/libraries/sip/call-state";
import {
  ConnectedUserAgentEvent,
  VoicemailMessageSummaryNotificationUserAgentEvent
} from "@onsip/common/libraries/sip/user-agent-event";
import { AccountDetailsService } from "@onsip/common/services/api/resources/accountDetails/account-details.service";
import { E911Service } from "@onsip/common/services/api/resources/e911/e911.service";
import { PackageService } from "@onsip/common/services/api/resources/package/package.service";
import { AdvQueueService } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { TermsAndConditionsService } from "@onsip/common/services/api/resources/termsAndConditions/terms-and-conditions.service";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { UserAddressService } from "@onsip/common/services/api/resources/userAddress/user-address.service";
import { VoicemailService } from "@onsip/common/services/api/resources/voicemail/voicemail.service";
import { isAdminRole, isAtLeastAccountAdminRole } from "@onsip/common/services/api/role";
import { CallControllerService } from "@onsip/common/services/call-controller.service";
import { IdentityService } from "@onsip/common/services/identity.service";
import { LogService } from "@onsip/common/services/logging";
import { UserPresenceService } from "@onsip/common/services/sayso/user-presence.service";
import { SupportService } from "@onsip/common/services/support/support.service";
import { views } from "@onsip/web/app/phone/views";
import { initializeApp } from "@onsip/web/libraries/firebase/app";
import { sentryConfigureScope } from "@onsip/web/platform-dependent/sentry";
import {
  filter,
  map,
  NEVER,
  Subscription,
  switchMap,
  take,
  withLatestFrom,
  combineLatest,
  of,
  from
} from "rxjs";
import { distinctUntilChanged, pairwise, pluck } from "rxjs/operators";
import { EavesdropService } from "../queue/realtime/eavesdrop.service";
import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { OnsipChatService } from "../shared/components/chat/onsip-chat.service";
import { E911Component } from "../shared/components/e911/e911.component";
import { E911AdminModalComponent } from "../shared/components/e911-admin/e911-admin-modal/e911-admin-modal.component";
import { FreeTrialService } from "../shared/components/freeTrial/free-trial.service";
import {
  ModalMaterialComponent,
  ModalMaterialReturnData
} from "../shared/components/modal/modal-material.component";
import { SnackbarService } from "../shared/components/snackbar/snackbar.service";
import { CallGroupService } from "../shared/controller/call-group.service";
import { CallMessageService } from "../shared/controller/call-message.service";
import { SdhFactoryHelperService } from "../shared/controller/sdh-factory-helper.service";
import { AppCallingService } from "../shared/services/appCalling/app-calling.service";
import {
  AutoAnswerService,
  AutoAnswerState
} from "../shared/services/autoAnswer/auto-answer.service";
import { CallVisibilityService } from "../shared/services/callVisibility/call-visibility.service";
import { GumService } from "../shared/services/gum/gum.service";
import { LocalStorageService } from "../shared/services/localStorage/local-storage.service";
import { VideoConferenceService } from "../videoConference/video-conference.service";

// bootstrapped services so they are initialized from the start
import { CallAudioService } from "../call/call-audio.service";
import { DoNotDisturbService } from "@onsip/common/services/do-not-disturb.service";
import { SubscriptionControllerService } from "@onsip/common/services/subscription-controller.service";
import { ContactService } from "@onsip/common/services/contact/contact.service";
import { NotificationsService } from "../shared/services/notifications/notifications.service";
import { QueueWarningsService } from "../queue/warnings/queue-warnings.service";
import { FirebaseBootstrapService } from "@onsip/common/services/sayso/firebase-bootstrap.service";
import { ConfigurationService } from "../sayso/services/configuration.service";
import { PhoneOrganizationService } from "@onsip/web/app/phone/api/phone-organization.service";
import { CurrentOrganizationService } from "@onsip/common/services/api/apiParams/current-organization.service";

@Injectable({ providedIn: "root" })
export class InitPhoneService implements OnDestroy {
  private callIsActive = false;
  private callIsConnected = false;
  private version!: string;
  private latestRingingUuid!: string | undefined;

  private unsubscriber = new Subscription();

  constructor(
    private videoConferenceService: VideoConferenceService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private userService: UserService,
    private queueService: AdvQueueService,
    private log: LogService,
    private router: Router,
    private callControllerService: CallControllerService,
    private voicemailService: VoicemailService,
    private gumService: GumService,
    private snackbarService: SnackbarService,
    private accountDetails: AccountDetailsService,
    private freeTrialService: FreeTrialService,
    private userAddress: UserAddressService,
    private tacService: TermsAndConditionsService,
    private e911Service: E911Service,
    private userPresenceService: UserPresenceService,
    private packageService: PackageService,
    private autoAnswerService: AutoAnswerService,
    private eavesdropService: EavesdropService,
    private callVisibilityService: CallVisibilityService,
    private callGroupService: CallGroupService,
    private analyticsService: AnalyticsService,
    private identity: IdentityService,
    private localStorageService: LocalStorageService,
    private callMessageService: CallMessageService,
    private onsipChatService: OnsipChatService,
    private sdhFactoryHelperService: SdhFactoryHelperService,
    private supportService: SupportService,
    private appCallingService: AppCallingService,
    private currentOrganizationService: CurrentOrganizationService,
    // @ts-ignore: noUnusedLocals
    private callAudioService: CallAudioService,
    // @ts-ignore: noUnusedLocals
    private doNotDisturbService: DoNotDisturbService,
    // @ts-ignore: noUnusedLocals
    private subscriptionControllerService: SubscriptionControllerService,
    // @ts-ignore: noUnusedLocals
    private contactService: ContactService,
    // @ts-ignore: noUnusedLocals
    private notificationsService: NotificationsService,
    // @ts-ignore: noUnusedLocals
    private queueWarningsService: QueueWarningsService,
    // @ts-ignore: noUnusedLocals
    private firebaseBootstrapService: FirebaseBootstrapService,
    // @ts-ignore: noUnusedLocals
    private config: ConfigurationService,
    // @ts-ignore: noUnusedLocals
    private phoneOrganizationService: PhoneOrganizationService
  ) {
    this.initServices();
    this.handleE911AddLocations();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  // Confirm when user closes window during a call. Terminate the call when the window closes.
  @HostListener("window:beforeunload", ["$event"]) onBeforeUnload(event: BeforeUnloadEvent) {
    if (this.callIsConnected || this.videoConferenceService.hasVideoConference()) {
      event.preventDefault();
    }
  }

  // these listeners prevent dragged files from loading the file
  // other dragged items, or files dragged to the avatar box, still function
  @HostListener("window:dragover", ["$event"]) onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  @HostListener("window:drop", ["$event"]) onDrop(event: DragEvent): void {
    event.preventDefault();
  }

  initServices() {
    this.initFirebase();
    this.initMenu();
    this.initWarningBar();
    //   this.initE911();
    this.initTac();
    this.platformGetVersion();
    this.platformUpdateNote();
    this.initUserPresence();
    this.initPackageService();
    this.initCallController();
    this.initUserAgent();
    this.initOrgAdminRouting();
  }

  private initFirebase(): void {
    initializeApp(["auth", "database", "firestore"]);
  }

  private initMenu(): void {
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        const showOrgAdminTabs = isAdminRole(user.roles);
        if (showOrgAdminTabs) {
          this.platformIsAdmin();
        }
      })
    );

    this.unsubscriber.add(
      this.queueService.getSmartQueueObs().subscribe(queues => {
        const hasAdvancedQueues = queues.length > 0;
        if (hasAdvancedQueues) {
          this.platformHasQueues();
        }
      })
    );

    // when we get a new voicemail notify we must refetch, there's no way around it
    this.unsubscriber.add(
      this.callControllerService
        .getUserAgentEventObservable()
        .pipe(filter(e => e.id === VoicemailMessageSummaryNotificationUserAgentEvent.id))
        .subscribe(() => this.voicemailService.advVoicemailboxBrowseWithUserId())
    );

    this.platformSetListeners();
  }

  private initWarningBar(): void {
    // GUM Warnings
    this.unsubscriber.add(
      this.gumService.state.pipe(filter(state => !!state.warningMessage)).subscribe(state => {
        this.snackbarService.openSnackBar(state.warningMessage || "", "error");
      })
    );
  }

  private initTac() {
    this.userService.selfUser
      .pipe(
        map(user => !(new Date(user.created) < new Date(user.ackHostedTerms))),
        switchMap(isShowTac => {
          if (isShowTac) {
            this.tacService.termsAndConditionsRead();
            return this.tacState.pipe(map(state => state.state["hosted"].text));
          } else {
            return of("");
          }
        }),
        take(1)
      )
      .subscribe(res => {
        if (res) {
          this.openTacModal(res);
        } else {
          this.initE911();
        }
      });
  }

  private openTacModal(tac: string) {
    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: "Terms and Conditions",
        message: tac,
        primaryBtnText: "ACCEPT",
        primaryBtnColor: "primary",
        primaryBtnFlat: true,
        secondaryBtnText: this.translateService.instant("LOG_OUT")
      },
      disableClose: true
    });

    modal
      .afterClosed()
      .pipe(
        map(response => !!response && response.doPrimaryAction),
        take(1)
      )
      .subscribe(isAccept => {
        if (isAccept) {
          this.userService.userAckHostedTerms();
          this.userService.userRead();
          this.initE911();
        } else {
          this.logout();
        }
      });
  }

  private initE911(): void {
    this.accountDetails.UserReadAccountDetails();
    this.unsubscriber.add(
      this.freeTrialService.state
        .pipe(
          filter(state => state.initialized),
          take(1),
          switchMap(freeTrialState => {
            // we don't want to show e911 things for free trial users
            if (freeTrialState.isFreeTrialAccount && !freeTrialState.trialExpired) {
              return NEVER;
            }
            return this.userService.selfUser.pipe(
              withLatestFrom(
                this.accountDetails.state.pipe(
                  filter(state => !state.loading),
                  map(state => Object.values(state.state)[0])
                ),
                this.userAddress.selfUser.pipe(
                  map(
                    // we only assign E911 Location to the main user address and not its alias
                    state =>
                      state.length > 0
                        ? state.sort(
                            (a, b) => parseInt(a?.userAddressId) - parseInt(b?.userAddressId)
                          )[0].e911LocationId
                        : ""
                  )
                )
              ),
              map(([user, accountDetails, e911LocId]) => ({
                showE911Tac:
                  // show E911 terms and condition if
                  // user hasn't accepted terms and condition and
                  !(new Date(user.created) < new Date(user.ackHostedE911)) &&
                  // user has e911 provisioning or has a prepaid or seconds balance
                  (user.e911Provisioning ||
                    parseInt(accountDetails.prepaidBalance) > 0 ||
                    parseInt(accountDetails.secondsBalance) > 0),
                showSelectE911Locations:
                  // only show E911Location selections if user has
                  // acknowledged terms and conditions
                  new Date(user.ackHostedE911) > new Date(user.created) &&
                  // user has e911 provisioning enabled
                  user.e911Provisioning &&
                  // and user has no e911Location set
                  !e911LocId
              })),
              take(1)
            );
          }),
          switchMap(({ showE911Tac, showSelectE911Locations }) => {
            if (showE911Tac) {
              this.tacService.termsAndConditionsRead();
              return this.tacState.pipe(
                map(state => state.state["hosted_e911"].text),
                switchMap(tac => {
                  // Open terms & conditions dialog
                  const modal = this.dialog.open(ModalMaterialComponent, {
                    panelClass: ["mat-typography", "onsip-dialog-universal-style"],
                    data: {
                      title: "E-911 Terms and Conditions",
                      message: tac,
                      primaryBtnText: "ACCEPT",
                      primaryBtnColor: "primary",
                      primaryBtnFlat: true,
                      secondaryBtnText: this.translateService.instant("ONSIP_I18N.REMIND_ME_LATER")
                    },
                    disableClose: true
                  });

                  return modal.afterClosed().pipe(
                    take(1),
                    map(response => !!response && response.doPrimaryAction)
                  );
                })
              );
            } else if (showSelectE911Locations) {
              this.showE911Locations();
              return NEVER;
            }
            return NEVER;
          }),
          switchMap(doPrimaryAction => {
            if (doPrimaryAction) {
              // Accepted Terms and conditions
              this.userService.userAckHostedE911();
              // need to call userRead to update service state
              this.userService.userRead();
              return this.userService.selfUser.pipe(
                map(user => user.e911Provisioning),
                take(1)
              );
            }
            return NEVER;
          })
        )
        .subscribe(userE911Provisoning => {
          if (userE911Provisoning) this.showE911Locations();
        })
    );
  }

  // The user is assumed to be "busy" if they are dealing with a call OR a conference.
  // Here we update their RTC presence to indicate they are busy in this instance of the app.
  private initUserPresence(): void {
    this.unsubscriber.add(
      // eslint-disable-next-line deprecation/deprecation
      combineLatest({
        callBusy: this.callControllerService.state.pipe(
          map(state => (state.calls.length ? true : false))
        ),
        conferenceBusy: this.videoConferenceService.state.pipe(
          map(state => (state.connected ? true : false))
        )
      })
        .pipe(
          map(({ callBusy, conferenceBusy }) => callBusy || conferenceBusy),
          distinctUntilChanged()
        )
        .subscribe(isBusy => {
          this.userPresenceService.setInstanceBusy(isBusy);
        })
    );
  }

  private initCallController(): void {
    this.unsubscriber.add(
      this.callControllerService.state.subscribe(state => {
        this.callIsActive = !!this.callControllerService.getUnheldCall();
        this.callIsConnected = state.calls.some(call => call.connected);
      })
    );

    // check to see if any calls that attempt to hold are preemptively terminated, and open a snackbar
    // to show the error if they are.
    this.unsubscriber.add(
      this.callControllerService
        .getCallEventObservable()
        .pipe(withLatestFrom(this.callControllerService.state))
        .subscribe(([event, state]) => {
          const badHold = state.calls.some(
            call =>
              call.uuid === event.uuid &&
              call.holdInProgress &&
              event.reason &&
              event.reason === "terminated" &&
              !call.blindTransferInProgress
          );
          if (badHold) {
            this.snackbarService.openSnackBar(
              "The call was terminated because it could not be held.",
              "error"
            );
          }
        })
    );

    let autoAnswerState: AutoAnswerState | undefined;
    this.unsubscriber.add(
      this.autoAnswerService.state.subscribe(state => (autoAnswerState = state))
    );

    this.unsubscriber.add(
      this.callControllerService.getCallEventObservable().subscribe(event => {
        const call: CallState | undefined = this.callControllerService.getCallStateByUuid(
          event.uuid
        );
        if (!call || this.eavesdropService.isEavesdropCall(event.uuid)) {
          return;
        }
        let analyticsEvent: string | undefined;
        const analyticsOptions: any = {};
        if (event.uuid === this.latestRingingUuid) {
          this.latestRingingUuid = undefined;
        }

        if (isNewCallEvent(event) && call.incoming) {
          // RINGING
          analyticsEvent = "Receive";
          analyticsOptions.Video = call.videoExpected;
          this.latestRingingUuid = call.uuid;

          if (autoAnswerState && autoAnswerState.enabled) {
            if (!this.callIsActive) {
              this.callControllerService.acceptCall(call.uuid, {
                audio: true,
                video: autoAnswerState.hasVideo && call.videoExpected
              });
            } else {
              this.callControllerService.endCall(call.uuid);
              return;
            }
          } else {
            this.callVisibilityService.processNewCall(call);
          }
        } else if (
          isConnectingCallEvent(event) &&
          !this.callGroupService.findThreeWayConferencePartner(event.uuid)
        ) {
          // DIALING, not part of 3way conf
          analyticsEvent = "Make";
          analyticsOptions.Video = call.videoRequested;
        } else if (isConnectedCallEvent(event)) {
          if (!call.incoming) {
            analyticsEvent = "Answer";
          }
        } else if (isEndCallEvent(event)) {
          if (!!document.fullscreenElement && !this.callControllerService.getUnheldCall()) {
            // need to check if newcall is visible (previous check was broken)

            // HACK to circumvent typing
            const doc: any = document;

            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (doc.msExitFullscreen) {
              doc.msExitFullscreen();
            } else if (doc.mozCancelFullScreen) {
              doc.mozCancelFullScreen();
            } else if (doc.webkitExitFullscreen) {
              doc.webkitExitFullscreen();
            }
          }
        }

        if (analyticsEvent) {
          this.analyticsService.sendCallEvent(analyticsEvent, call, analyticsOptions);
        }
      })
    );
  }

  private platformGetVersion() {
    if (Config.IS_DESKTOP) {
      window.electron.onMessage("got-version", (event, version) => {
        this.version = version;
      });
      window.electron.sendMessage("get-version");
    }
  }

  private platformUpdateNote(): void {
    if (Config.IS_DESKTOP) {
      const versionXHR: XMLHttpRequest = new XMLHttpRequest();

      versionXHR.onreadystatechange = () => {
        if (versionXHR.readyState === 4) {
          if (versionXHR.status !== 200) {
            // latest version number not found because no internet possibly or something else
          }
          try {
            let str = "";

            if (Config.IS_MAC) {
              str = "OnSIP for Mac OS";
            } else if (Config.IS_WIN) {
              str = "OnSIP for Windows";
            } else {
              str = "OnSIP for Linux";
            }

            const response: string = versionXHR.response,
              indOf: number = response.indexOf(str + " v"),
              start: number = indOf + str.length + 2, // +2 removes the v
              end: number = start + 5,
              numStr: string = response.slice(start, end);

            if (this.compareVersions(this.version, numStr)) {
              const modal = this.dialog.open(ModalMaterialComponent, {
                panelClass: ["mat-typography", "onsip-dialog-universal-style"],
                data: {
                  title: this.translateService.instant("ONSIP_I18N.AN_UPDATE_IS_AVAILABLE"),
                  message: this.translateService.instant(
                    "ONSIP_I18N.PLEASE_UPDATE_YOUR_APP_FOR_AN_IMPROVED_EXPERIENCE"
                  ),
                  primaryBtnText: this.translateService.instant("ONSIP_I18N.DOWNLOAD"),
                  primaryBtnFlat: true
                }
              });

              this.unsubscriber.add(
                modal
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe(innerResponse => {
                    if (innerResponse && innerResponse.doPrimaryAction) {
                      // Accepted Terms and conditions
                      window.open("https://www.onsip.com/app/download", "_blank");
                    }
                  })
              );
            }
          } catch (e) {
            console.error("Version check failed:", e);
          }
        }
      };

      versionXHR.open("GET", "https://www.onsip.com/app/download");
      versionXHR.send();
    }
  }

  private showE911Locations(): void {
    this.unsubscriber.add(
      this.e911Service.selfE911Locations
        .pipe(withLatestFrom(this.userService.selfUser.pipe(take(1))), take(1))
        .subscribe(([locs, user]) => {
          if (locs.length === 0 && !isAtLeastAccountAdminRole(user.roles)) {
            this.openE911Dialog();
          } else if (locs.length === 0) {
            return;
          } else {
            this.openE911Dialog();
          }
        })
    );
  }

  private openE911Dialog() {
    // Open e911 location dialog
    this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: "Set E911 Location",
        component: E911Component, // re-using e911 component
        primaryBtnText: this.translateService.instant("ONSIP_I18N.SAVE"),
        primaryBtnColor: "primary",
        primaryBtnFlat: true,
        secondaryBtnText: this.translateService.instant("ONSIP_I18N.CANCEL"),
        noSentenceCaseTitle: true
      },
      disableClose: true
    });
  }

  private handleE911AddLocations(): void {
    this.unsubscriber.add(
      // eslint-disable-next-line deprecation/deprecation
      combineLatest({
        user: this.userService.selfUser.pipe(take(1)),
        isShowedE911AdminModal: from(this.localStorageService.getModalWarnings()).pipe(
          map(modals => !!modals?.isShowedE911AdminModal)
        )
      }).subscribe(({ user, isShowedE911AdminModal }) => {
        if (
          !isShowedE911AdminModal &&
          isAtLeastAccountAdminRole(user.roles) &&
          user.e911Provisioning
        ) {
          this.showE911AddLocationsModal();
        }
      })
    );
  }

  private showE911AddLocationsModal() {
    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: this.translateService.instant("ONSIP_I18N.ADD.E911"),
        component: E911AdminModalComponent,
        primaryBtnText: this.translateService.instant("ONSIP_I18N.E911__ADD_LOCATIONS"),
        primaryBtnColor: "primary",
        primaryBtnFlat: true,
        secondaryBtnText: this.translateService.instant("ONSIP_I18N.NOT_NOW"),
        noSentenceCaseTitle: true
      },
      disableClose: true
    });

    modal
      .afterClosed()
      .pipe(take(1))
      .subscribe((response: ModalMaterialReturnData) => {
        this.localStorageService.setModalsWarningsStorage({ isShowedE911AdminModal: true });
        if (response && response.doPrimaryAction) {
          this.router.navigate([views.E911_TABLE]);
        }
      });
  }

  private initUserAgent(): void {
    this.unsubscriber.add(
      this.identity.state
        .pipe(
          filter(identityState => identityState.addresses.length > 0),
          take(1),
          switchMap(identityState => {
            sentryConfigureScope(scope => {
              scope.setUser({
                username: identityState.addresses[0].aor
              });
            });

            return of(...identityState.addresses);
          }),
          switchMap(userAddress => {
            const aor: string = userAddress.aor;

            this.localStorageService.getUserAgentValues(aor).then(storedValues => {
              let uuid = storedValues.instanceId;
              // saucelabs testing account should use the same instance id to prevent multiple registration
              if (aor === "saucelabs@example.onsip.com") {
                // this was randomly generated
                uuid = "a7cf3039-95ad-4dac-904b-61acb951dd8a";
              }
              if (!uuid) {
                uuid = UUID.randomUUID();
                this.localStorageService.setUserAgent(aor, { instanceId: uuid });
              }

              this.callControllerService.makeAndAddUserAgent(
                {
                  authorizationUsername: userAddress.authUsername,
                  authorizationPassword: userAddress.authPassword,
                  displayName: userAddress.name,
                  // UA timeout should be handled by the backend not the userAddress
                  // hard setting the timeout to be more than the max possible failover time
                  noAnswerTimeout: 130,
                  userAgentString:
                    "OnSIP_App/" + Config.VERSION_NUMBER + "/" + Config.PLATFORM_STRING
                },
                aor,
                {
                  instanceId: uuid
                },
                {
                  handleIncomingMessage: message => {
                    /*
                     * This used to match message remoteIdentity uri with call remoteIdentity uri.
                     * This didn't work when calling the extension of the queue, so now it is unguarded (bad)
                     * At the time, there did not seem to be a way to match MESSAGE to call without remoteIdentity.
                     * Custom data and queue video are sent almost immediately after the call is set up, but if a situation
                     * arises where this is not true, this must be dealt with.
                     */
                    if (!message.request.body.startsWith("{")) {
                      // won't be json, just return;
                      return;
                    }
                    const call: CallState | undefined = this.callControllerService.getUnheldCall();
                    let jsonMessage: any;

                    try {
                      jsonMessage = JSON.parse(message.request.body || "");
                    } catch (e) {
                      this.log.error("Received message could not be parsed as JSON:", message);
                      return;
                    }

                    if (
                      Object.prototype.hasOwnProperty.call(jsonMessage, "onHold") &&
                      call &&
                      call.video
                    ) {
                      // queue video
                      this.callMessageService.onQueueVideo(call, jsonMessage);
                    } else if (jsonMessage.type === "app-message") {
                      // onsip chat
                      this.onsipChatService.onMessageReceived(message, jsonMessage);
                    }
                  }
                },
                this.sdhFactoryHelperService.createFactory()
              );
            });

            return this.callControllerService.getUserAgentEventObservable().pipe(
              filter(event => event.aor === aor && event.id === ConnectedUserAgentEvent.id),
              take(1),
              map(() => aor)
            );
          })
        )
        .subscribe(aor => {
          this.localStorageService.getUserAgentValues(aor).then(values => {
            if (!this.supportService.isWebrtcSupported() || !values.register) {
              this.callControllerService.switchUserAgentRegisterState(aor, false);
            }
          });
        })
    );

    this.unsubscriber.add(
      // eslint-disable-next-line deprecation/deprecation
      combineLatest({
        identityState: this.identity.state,
        callControllerState: this.callControllerService.state
      })
        .pipe(
          filter(
            ({ identityState, callControllerState }) =>
              callControllerState.userAgents.length === identityState.addresses.length &&
              identityState.addresses.length > 0
          ),
          take(1),
          switchMap(({ identityState }) => {
            this.localStorageService.getDefaultUA().then(storedDefaultAor => {
              const originalDefaultAor =
                storedDefaultAor && this.callControllerService.hasUserAgent(storedDefaultAor)
                  ? storedDefaultAor
                  : identityState.addresses[0].aor;

              this.identity.setDefaultIdentity(originalDefaultAor);

              let defaultAor: string | undefined;
              this.unsubscriber.add(
                // eslint-disable-next-line rxjs/no-nested-subscribe
                this.identity.state.subscribe(state => {
                  const newIdentity = state.defaultIdentity ? state.defaultIdentity.aor : undefined;
                  if (defaultAor !== newIdentity) {
                    defaultAor = newIdentity;
                    if (newIdentity !== undefined) {
                      this.localStorageService.setGeneralSettings({ defaultUA: newIdentity });
                    }
                  }
                })
              );
            });

            return this.appCallingService.state.pipe(
              pluck("enabled"),
              distinctUntilChanged(),
              map(isEnabled => ({ identityState, isEnabled }))
            );
          })
        )
        .subscribe(({ identityState, isEnabled }) => {
          identityState.addresses.forEach(address => {
            // TODO: breaks the philosophy to only get local storage values on load, but we don't store this anwyere else
            // in this situation, shouldBeRegistered on the ua does not cover it, as it will be false if appcalling was off
            this.localStorageService.getUserAgentValues(address.aor).then(uaValues => {
              this.callControllerService.switchUserAgentRegisterState(
                address.aor,
                isEnabled ? uaValues.register : false
              );
            });
          });
        })
    );
  }

  private initPackageService(): void {
    this.packageService.packageBrowseAssigned();
  }

  private initOrgAdminRouting(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe(([prevRoute, currRoute]) => {
        if (prevRoute instanceof RoutesRecognized && currRoute instanceof RoutesRecognized) {
          if (
            this.testRouteForSuperAdminPriv(prevRoute) ||
            this.testRouteForSuperAdminPriv(currRoute)
          ) {
            // Do not switch back to old orgId if spoofing other accounts
            // reset any saved org level admin org id used to handle multiple org accounts
            this.currentOrganizationService.adminOrgId = "";
            return;
          }
          const onAdministratorsTab = /\/administrators\//.test(currRoute.url);
          // we only want to switch orgId in the admin page. When outside, we should always use the logged in user's org id
          if (!onAdministratorsTab) {
            CurrentOrganizationService.currentOrgId.next(
              this.currentOrganizationService.firstOrgId
            );
          } else if (this.currentOrganizationService.adminOrgId) {
            // if going to an admin page, swap to a saved admin orgId if one exists
            CurrentOrganizationService.currentOrgId.next(
              this.currentOrganizationService.adminOrgId
            );
          }
        }
      });
  }

  private testRouteForSuperAdminPriv(route: RoutesRecognized): boolean {
    const url = route.url;
    return (
      url.includes(views.SUPER_USER_VIEW) ||
      url.includes(views.AGENT_VIEW) ||
      url.includes(views.SUB_AGENT_VIEW)
    );
  }

  private compareVersions(latestStr: string, downloadedStr: string): boolean {
    // this function should return true if we need to notify the user to update to the latest version
    const latestParts: Array<string> = latestStr.split("."),
      downloadedParts: Array<string> = downloadedStr.split(".");

    if (parseInt(latestParts[0]) < parseInt(downloadedParts[0])) {
      return true;
    } else if (
      parseInt(latestParts[0]) === parseInt(downloadedParts[0]) &&
      parseInt(latestParts[1]) < parseInt(downloadedParts[1])
    ) {
      return true;
    } else if (
      parseInt(latestParts[0]) === parseInt(downloadedParts[0]) &&
      parseInt(latestParts[1]) === parseInt(downloadedParts[1]) &&
      parseInt(latestParts[2]) < parseInt(downloadedParts[2])
    ) {
      return true;
    }

    return false;
  }

  private platformIsAdmin(): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("is-admin");
    }
  }

  private platformHasQueues(): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("enable-queues");
    }
  }

  private logout(): void {
    document.cookie =
      "sessionId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
    document.cookie =
      "userId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
    this.platformCloseWindow();
  }

  private platformCloseWindow(): void {
    if (Config.IS_WEB) {
      window.location.href = ".";
    }
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("log-out");
    }
  }

  private platformSetListeners(): void {
    if (Config.IS_DESKTOP) {
      window.electron.onMessage("open-dashboard", (event, message) => {
        this.log.info(message);
        this.router.navigate([views.HOME]);
      });
      window.electron.onMessage("open-app-settings", (event, message) => {
        this.log.info(message);
        this.router.navigate([views.APP_SETTINGS]);
      });
      window.electron.onMessage("open-queue-dashboard", (event, message) => {
        this.log.info(message);
        this.router.navigate([views.QUEUE_DASHBOARD]);
      });
      window.electron.onMessage("open-sayso", (event, message) => {
        this.log.info(message);
        this.router.navigate([views.SAYSO_DASHBOARD]);
      });
      window.electron.onMessage("open-team-page", (event, message) => {
        this.log.info(message);
        this.router.navigate([views.TEAM_PAGE]);
      });
    }
  }

  private get tacState() {
    return this.tacService.state.pipe(filter(state => !state.loading));
  }
}
