import { LogService } from "../../../../common/services/logging";
import { Checker } from "./checker/refreshWarnings";
import { CallControllerService } from "../../../../common/services/call-controller.service";
import { NotificationsService } from "../../shared/services/notifications/notifications.service";
import { AdvQueueService } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { Injectable } from "@angular/core";
import { filter, take } from "rxjs/operators";
import { UserAgent } from "../../../../common/libraries/sip/user-agent";
import { UserAgentSIP } from "../../../../common/libraries/sip/user-agent-sip";
import { AdvQueueWarningService } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning.service";
import {
  AdvQueueWarning,
  ApiEditAdvQueueWarningParams,
  EditAdvQueueWarningParams
} from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning";

export interface QueueBhrShift {
  days: Array<{
    isInShift: boolean;
    isAvailable: boolean;
  }>;
  allDay: boolean;
  time: Array<string>;
}

export interface WarningSharedObject {
  queue: {
    id: number;
    name: string;
    address: string;
  };
  thresholdType: string;
  thresholdValue: JSON;
  alertType: string;
  alertDestination: string;
  cooldownMinutes: number;
}
export interface WarningEditObject extends WarningSharedObject {
  editBhr: Array<QueueBhrShift>;
  timeZone: string;
}

export interface WarningViewObject extends WarningSharedObject {
  bhr: JSON;
}

export interface WarningObject {
  editObj: WarningEditObject;
  viewObj: WarningViewObject;
  displayMode: "view" | "edit";
  warningId?: string;
  saved: boolean;
}

export interface QueueObject {
  queueMap: Record<
    string,
    {
      name: string;
      id: number;
    }
  >;
  queues: Array<{
    name: string;
    id: number;
  }>;
}

export interface EditAdvQueueWarningResponse {
  queueAddress: string;
  warningId: string;
}

@Injectable({ providedIn: "root" })
export class QueueWarningsService {
  private thresholdCheckerProm: Promise<any>;
  private warningsProm: Promise<any>;
  private queueObjProm: Promise<void>;

  private queueObj: QueueObject = { queueMap: {}, queues: [] };
  private warningSubscriptions: Array<any> = [];
  private queueWarnings: Array<AdvQueueWarning> = [];
  private thresholdChecker!: any;
  private warningEmitter: EventTarget;
  private areNotificationsEnabled = false;
  private onWarningDeleteCallback: ((idx: number) => void) | undefined;

  constructor(
    private log: LogService,
    private callControllerService: CallControllerService,
    private notificationsService: NotificationsService,
    private queueService: AdvQueueService,
    private advQueueWarningService: AdvQueueWarningService
  ) {
    this.warningsProm = new Promise<void>(resolve => {
      this.advQueueWarningService.advQueueWarningBrowse({ Limit: 25000 }).then(response => {
        if (response.status === "success") {
          this.queueWarnings = Object.values(response.data);
          resolve();
        } else {
          this.log.error(response.data);
          resolve();
        }
      });
    });

    this.notificationsService.state.subscribe(state => {
      this.areNotificationsEnabled = !!state.notifications["on queue alert"];
    });

    /**
     * Create the emitter that the adv queue warnings threshold notifications module
     * uses to signal updates to threshold check states.
     * You're going to want to pass this into the threshold module.
     *
     * Events:
     *   'trigger': (advQueueWarning, notifyObject)
     */
    this.warningEmitter = new EventTarget();

    // When a warning is triggered, we create a desktop notification.
    this.warningEmitter.addEventListener("trigger", (event: any) => {
      this.notificationsService.notifyOnQueueWarning(event.detail.advQueueWarning);
    });

    this.thresholdCheckerProm = new Promise<void>(resolve => {
      this.callControllerService.state
        .pipe(
          filter(state => state.defaultIsRegistered),
          take(1)
        )
        .subscribe(() => {
          const ua: UserAgent | undefined = this.callControllerService.getOutboundUserAgent();
          if (ua instanceof UserAgentSIP) {
            this.thresholdChecker = new Checker(ua.SIP, this.warningEmitter);
          }
          resolve();
        });
    });

    // which queue the notification belongs to
    // A list of objects: { queueName : string, queueId : int }
    this.queueObjProm = new Promise<void>(resolve => {
      this.queueService.getSmartQueuesWithoutOrgId().then(queues => {
        this.queueObj = queues.reduce(
          (accum: QueueObject, queue) => {
            if (!queue.advQueueId) {
              return accum;
            }
            const queueObj = {
              name: queue.queueName,
              id: parseInt(queue.advQueueId)
            };
            accum.queueMap[queue.advQueueId] = queueObj;
            accum.queues.push(queueObj);
            return accum;
          },
          {
            queueMap: {},
            queues: []
          }
        );

        resolve();

        this.notificationsService.state
          .pipe(
            filter(state => !!state.notifications["on queue alert"]),
            take(1)
          )
          .subscribe(() => {
            this.toggleQueueWarnings(true);
          });
      });
    });
  }

  addWarning(args: EditAdvQueueWarningParams): Promise<EditAdvQueueWarningResponse> {
    return this.apiAddWarning(this.mutateToApiArgs(args)).then(resp => {
      const advQueueWarning: AdvQueueWarning = resp;

      this.queueWarnings.push(advQueueWarning);
      if (this.areNotificationsEnabled) {
        this.thresholdChecker.addWarning(advQueueWarning);
      }

      return {
        queueAddress: resp.AdvQueueAddress,
        warningId: resp.AdvQueueWarningId
      };
    });
  }

  /**
   * Helper function to turn on queue warnings.
   * This actually starts monitoring the queues and sends notifications.
   */
  toggleQueueWarnings(isOn: boolean): void {
    if (isOn) {
      Promise.all([this.thresholdCheckerProm, this.warningsProm]).then(() => {
        if (this.thresholdChecker) {
          this.warningSubscriptions = this.thresholdChecker.refreshWarnings(this.queueWarnings);
        }
      });
    } else if (this.warningSubscriptions.length > 0) {
      this.warningSubscriptions.forEach(subscription => {
        subscription.close();
      });
      this.warningSubscriptions = [];
    }
  }

  /**
   * Invariants:
   * - For the whole list of BHR shifts, a day can be set in the shift at
   *   most one time. Therefore, a single set day uniquely identifies a
   *   shift.
   * - A day can be set in the shift iff it is enabled.
   */
  localBhrDefaultShift(): QueueBhrShift {
    return {
      days: Array.from(Array(7)).map(() => {
        return {
          isInShift: false,
          isAvailable: true
        };
      }),
      allDay: true,
      time: ["09:00", "17:00"]
    };
  }

  getWarnings(): Promise<Array<AdvQueueWarning>> {
    return this.advQueueWarningService.advQueueWarningBrowse({ Limit: 25000 }).then(response => {
      if (response.status === "success") {
        this.queueWarnings = Object.values(response.data);
        return this.queueWarnings;
      } else {
        this.log.error(response.data);
        return this.queueWarnings;
      }
    });
  }

  editWarning(args: EditAdvQueueWarningParams): Promise<EditAdvQueueWarningResponse> {
    return this.apiEditWarning(this.mutateToApiArgs(args)).then(resp => {
      const advQueueWarning = resp;

      if (this.areNotificationsEnabled) {
        this.thresholdChecker.editWarning(advQueueWarning);
      }

      this.queueWarnings.some((queueWarning, index) => {
        if (queueWarning.AdvQueueWarningId === advQueueWarning.AdvQueueWarningId) {
          this.queueWarnings.splice(index, 1, advQueueWarning);
          return true;
        }
        return false;
      });
      return {
        queueAddress: resp.AdvQueueAddress,
        warningId: resp.AdvQueueWarningId
      };
    });
  }

  // this is a hack to deal with the weird design between files and avoid scope.$parent
  setOnWarningDeleteCallback(callback: (idx: number) => void): void {
    this.onWarningDeleteCallback = callback;
  }

  // warningIndex and queueAddress are not great
  deleteWarning(
    advQueueWarningId: string,
    warningIndex: number,
    queueAddress: string
  ): Promise<void | boolean> {
    // Existence of a warning ID means this is in the database.
    if (advQueueWarningId) {
      return this.advQueueWarningService
        .advQueueWarningDelete(advQueueWarningId)
        .then(response => {
          if (response.status === "success") {
            return;
          } else {
            throw new Error(JSON.stringify(response));
          }
        })
        .then(resp => {
          if (this.areNotificationsEnabled) {
            this.thresholdChecker.removeWarning({
              AdvQueueWarningId: advQueueWarningId,
              AdvQueueAddress: queueAddress
            });
          }

          this.queueWarnings.some((queueWarning, index) => {
            if (queueWarning.AdvQueueWarningId === advQueueWarningId) {
              this.queueWarnings.splice(index, 1);
              return true;
            }
            return false;
          });

          if (warningIndex >= 0) {
            this.onWarningDeleteCallback && this.onWarningDeleteCallback(warningIndex);
          }

          return resp;
        });
    }

    if (warningIndex >= 0) {
      this.onWarningDeleteCallback && this.onWarningDeleteCallback(warningIndex);
    }

    return Promise.resolve(true);
  }

  bhrToEditBhr(bhr: Record<number, Array<string>>): Array<QueueBhrShift> {
    const timeSorted: Record<string, QueueBhrShift> = {};

    for (let i = 0; i < 7; i++) {
      const day: Array<string> | undefined = bhr[i];
      if (day) {
        const tKey: string = day[0] + day[1],
          shift = timeSorted[tKey] || this.localBhrDefaultShift();

        shift.time = [day[0], day[1]];
        shift.allDay = day[0] === "00:00" && day[1] === "23:59";
        shift.days[i].isInShift = true;
        timeSorted[tKey] = shift;
      }
    }

    return Object.keys(timeSorted).map(key => {
      return timeSorted[key];
    });
  }

  getQueueObj(): Promise<QueueObject> {
    return this.queueObjProm.then(() => {
      return this.queueObj;
    });
  }

  private mutateToApiArgs(args: EditAdvQueueWarningParams): ApiEditAdvQueueWarningParams {
    return {
      AdvQueueId: args.queueId,
      ThresholdType: args.thresholdType,
      // TODO threshold values are arrays or objects. This is a hack to make the
      // single int threshold value become an object. If we make non-int
      // threshold values, we'll need to change this.
      ThresholdValue: JSON.stringify({ Value: args.thresholdValue }),
      AlertType: args.alertType,
      AlertDestination: args.alertDestination,
      CooldownSeconds: args.cooldownSeconds || 15 * 60,
      WarningBhr: JSON.stringify(args.bhr),
      ...(args.warningId && { AdvQueueWarningId: args.warningId })
    };
  }

  /**
   * Requires:
   * SessionId, QueueId,
   * ThresholdType, ThresholdValue,
   * AlertType, AlertDestination,
   * CooldownSeconds, WarningBhr
   */
  private apiAddWarning(apiArgs: ApiEditAdvQueueWarningParams): Promise<AdvQueueWarning> {
    return this.advQueueWarningService.advQueueWarningAdd(apiArgs).then(response => {
      if (response.status === "success") {
        return response.data[Object.keys(response.data)[0]];
      }

      throw new Error(JSON.stringify(response.data));
    });
  }

  /**
   * Requires:
   * SessionId, QueueId,
   * ThresholdType, ThresholdValue,
   * AlertType, AlertDestination,
   * CooldownSeconds, WarningBhr
   */
  private apiEditWarning(apiArgs: ApiEditAdvQueueWarningParams): Promise<AdvQueueWarning> {
    return this.advQueueWarningService.advQueueWarningEdit(apiArgs).then(response => {
      if (response.status === "success") {
        return response.data[Object.keys(response.data)[0]];
      }

      throw new Error(JSON.stringify(response.data));
    });
  }
}
