<div class="mat-dialog-container" class="mat-typography">
  <h2 mat-dialog-title class="mat-dialog-title">Configure Queue Email Alert Rules</h2>
  <div mat-dialog-content class="mat-dialog-content">
    @for (warning of warnings; track warning; let idx = $index) {
    <onsip-queue-warnings [warningSettings]="warning" [warningIndex]="idx"> </onsip-queue-warnings>
    }
  </div>
  <div mat-dialog-actions class="mat-dialog-actions modal-actions">
    <button mat-raised-button color="primary" (click)="createNewWarning()">New Time Alert</button>
  </div>
</div>
