<onsip-header-main>
  <onsip-header-page-title>{{
    "ONSIP_I18N.APP_SETTINGS" | translate | sentenceCase
  }}</onsip-header-page-title>
</onsip-header-main>
<onsip-profile></onsip-profile>
<mat-card class="onsip-settings-card">
  <mat-card-header>
    <mat-card-title class="mat-h2">
      {{ "ONSIP_I18N.ACCOUNT_DETAILS" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="onsip-settings-card-container onsip-settings-card-background">
    <div class="left">
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.ACCOUNT_ID" | translate }}
        </span>
        <p class="mat-body-2">{{ accountId | async }}</p>
      </div>
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.EMAIL" | translate }}
        </span>
        <p class="mat-body-2">{{ email | async }}</p>
      </div>
      @if (userProfile.extensions.length > 0) {
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.MY_EXTENSIONS" | translate }}
        </span>
        <div class="user-extensions-list">
          @for (extension of userProfile.extensions; track extension) {
          <p class="mat-body-2">{{ extension }}</p>
          }
        </div>
      </div>
      } @if (userProfile.phoneNumbers.length > 0) {
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.PHONE_NUMBERS" | translate }}
        </span>
        <div class="user-phone-numbers-list">
          @for (number of userProfile.phoneNumbers; track number) {
          <p class="mat-body-2">{{ number }}</p>
          }
        </div>
      </div>
      }
      <div class="card-section horizontal-card-section">
        <onsip-password-change [sipAddress]="sipAddress"></onsip-password-change>
      </div>
    </div>
    <div class="right">
      @if (e911Enabled | async) {
      <onsip-e911 class="e911-section" [showTitle]="true"></onsip-e911>
      }
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="onsip-settings-card">
  <mat-card-header>
    <mat-card-title class="mat-h2">
      {{ "ONSIP_I18N.APP_CONTROLS" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="onsip-settings-card-container onsip-settings-card-background">
    <div class="left">
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.APP_VOLUME" | translate }}
        </span>
        <span class="volume-container" id="onsip-user-settings-volume">
          <mat-icon aria-label="volume_mute">volume_mute</mat-icon>
          <onsip-volume-slider class="volume-slider" color="primary"></onsip-volume-slider>
          <mat-icon aria-label="volume_up">volume_up</mat-icon>
        </span>
      </div>
      <div class="card-section">
        <span class="label"> Browser notifications </span>
        <div class="notification-controls-container">
          <div class="checkbox-control-setting">
            <mat-checkbox
              color="primary"
              onsip-checkbox-thin-border
              class="user-settings-toggle"
              [ngModel]="allNotificationsEnabled"
              (change)="setNotification('all', $event.checked)"
              disableRipple
              [disabled]="isNotificationsDisabled"
            >
              {{ "ONSIP_I18N.ALL" | translate }}
            </mat-checkbox>
          </div>
          @for (type of notificationTypes; track type) {
          <div class="checkbox-control-setting">
            <mat-checkbox
              color="primary"
              onsip-checkbox-thin-border
              class="user-settings-toggle"
              [ngModel]="notifications[type]"
              (change)="setNotification(type, $event.checked)"
              disableRipple
              [disabled]="isNotificationsDisabled"
            >
              @if (type === 'from incoming calls') {
              {{ "ONSIP_I18N.FROM_INCOMING_CALLS" | translate }}
              } @if (type === 'on chat') {
              {{ "ONSIP_I18N.ON_CHAT" | translate }}
              } @if (type === 'on hangup') {
              {{ "ONSIP_I18N.ON_HANGUP" | translate }}
              } @if (type === 'on queue alert') {
              {{ "ONSIP_I18N.ON_QUEUE_ALERT" | translate }}
              } @if (type === 'on transfer') {
              {{ "ONSIP_I18N.ON_TRANSFER" | translate }}
              }
            </mat-checkbox>
          </div>
          }
        </div>
      </div>
      <div class="section-spacing card-section">
        <span class="label">{{ "ONSIP_I18N.VERSION" | translate }}</span>
        <p class="mat-body-2">{{ versionNumber }}</p>
      </div>
    </div>
    <div class="right">
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.INBOUND_CALLS_RING_FOR" | translate }}
        </span>
        <mat-form-field
          appearance="outline"
          onsip-form-field-flush-outline
          onsip-form-field-outline-white
          onsip-mat-select-small-height
          class="settings-form small-width"
        >
          <mat-select [(ngModel)]="failoverTime" (ngModelChange)="updateFailover(undefined)">
            @for (failoverTime of secondsArr; track failoverTime) {
            <mat-option [value]="failoverTime">
              {{ failoverTime }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (showFailoverAddress) {
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.IF_UNANSWERED_SEND_TO" | translate }}
        </span>
        <mat-form-field
          appearance="outline"
          onsip-form-field-flush-outline
          onsip-form-field-outline-white
          onsip-mat-select-small-height
          class="settings-form large-width"
        >
          <mat-select [(ngModel)]="failoverSelection" (ngModelChange)="updateFailover($event)">
            @for (failoverOption of failoverOptions; track failoverOption) {
            <mat-option
              [value]="failoverOption.Address"
              selected="failoverSelection === failoverOption.Address"
            >
              {{ failoverOption.Name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      } @if (showQueueWarnings) {
      <div class="section-spacing">
        <onsip-queue-warnings-settings class="user-settings-queue-warnings">
        </onsip-queue-warnings-settings>
      </div>
      } @if (thirdPartySignInService) {
      <!-- if google -->
      @if (thirdPartySignInService === 'GoogleLogin') {
      <div class="section-spacing">
        <button
          id="onsip--user-settings--disable-google-sign-in"
          mat-raised-button
          color="primary"
          class="oauth-btn oauth__setting"
          (click)="deleteThirdPartySignIn()"
        >
          {{ "ONSIP_I18N.DISABLE_GOOGLE_SIGN_IN" | translate }}
        </button>
      </div>
      }
      <!-- if slack -->
      @if (thirdPartySignInService === 'SlackLogin') {
      <div class="section-spacing">
        <button
          id="onsip--user-settings--disable-slack-sign-in"
          mat-raised-button
          color="primary"
          class="oauth-btn oauth__setting"
          (click)="deleteThirdPartySignIn()"
        >
          {{ "ONSIP_I18N.DISABLE_SLACK_SIGN_IN" | translate }}
        </button>
      </div>
      } }
      <div class="section-spacing">
        <div class="horizontal-card-section">
          <span class="label">{{ "ONSIP_I18N.HUBSPOT_INTEGRATION" | translate }}</span>
          <onsip-hubspot-authorized-toggle></onsip-hubspot-authorized-toggle>
        </div>
      </div>
      <div class="section-spacing">
        <a
          id="onsip--user-settings--open-user-feedback-modal"
          class="primary-500 mat-body-1 provide-feedback-link"
          (click)="onProvideFeedbackClick()"
        >
          {{ "ONSIP_I18N.PROVIDE_FEEDBACK" | translate }}
        </a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="onsip-settings-card">
  <mat-card-header>
    <mat-card-title class="mat-h2">
      {{ "ONSIP_I18N.CALL_PREFERENCES" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content
    class="onsip-settings-card-container onsip-settings-card-background call-preferences-container"
  >
    <div class="sip-addresses-controls">
      <div class="left">
        <div class="card-section">
          <span
            class="label"
            [matTooltip]="'ONSIP_I18N.APP_CALLING_ALLOWS_CALLS_TO_AND_FROM_THE_APP' | translate"
            matTooltipClass="onsip-tooltip-background"
            matTooltipPosition="right"
          >
            {{ "ONSIP_I18N.APP_CALLING" | translate }}
          </span>
          <mat-slide-toggle
            color="primary"
            class="user-settings-toggle"
            [checked]="appCallingEnabled"
            (change)="toggleDesktopMode($event)"
            disableRipple
            [disabled]="!isWebrtcSupported"
          >
          </mat-slide-toggle>
        </div>
        <div class="card-section">
          <span
            class="label"
            [matTooltip]="'ONSIP_I18N.DO_NOT_DISTURB_DISABLES_CALLS_IN_THE_APP' | translate"
            matTooltipClass="onsip-tooltip-background"
            matTooltipPosition="right"
          >
            {{ "ONSIP_I18N.DO_NOT_DISTURB" | translate }}
          </span>
          <mat-slide-toggle
            class="user-settings-toggle user-settings-sip-toggle"
            (change)="toggleDnd($event)"
            [checked]="dndStream | async"
            color="primary"
            [disabled]="!isWebrtcSupported || !appCallingEnabled"
          >
          </mat-slide-toggle>
        </div>
        @if (userAgentStates.length > 1) {
        <div class="card-section">
          <span class="label">
            {{ "ONSIP_I18N.SIP_ADDRESSES" | translate }}
          </span>
          <div class="sip-addresses-container">
            @for (userAgentState of userAgentStates; track userAgentState) {
            <div class="checkbox-control-setting">
              <mat-checkbox
                color="primary"
                (change)="toggleRegistration(userAgentState.aor, $event.checked)"
                [checked]="userAgentState.shouldBeRegistered"
                [disabled]="!isWebrtcSupported || !appCallingEnabled"
                onsip-checkbox-thin-border
              >
                {{ userAgentState.aor }}
              </mat-checkbox>
            </div>
            }
          </div>
        </div>
        }
      </div>
      <div class="right">
        <div class="card-section">
          <span class="label">
            {{ "ONSIP_I18N.PRIMARY_SIP_ADDRESS" | translate }}
          </span>
          <mat-form-field
            appearance="outline"
            onsip-form-field-flush-outline
            onsip-form-field-outline-white
            onsip-mat-select-small-height
            class="settings-form large-width"
          >
            <mat-select
              [ngModel]="defaultUA"
              (ngModelChange)="changeUA($event)"
              [disabled]="!isWebrtcSupported || !appCallingEnabled"
            >
              @for (userAgentState of userAgentStates; track userAgentState) {
              <mat-option [value]="userAgentState.aor">
                {{ userAgentState.aor }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="card-section horizontal-card-section">
          <span class="label">
            {{ "ONSIP_I18N.AUTOMATICALLY_ANSWER_CALLS" | translate }}
          </span>
          <mat-slide-toggle
            color="primary"
            class="user-settings-toggle"
            [checked]="autoAnswerEnabled | async"
            disableRipple
            [disabled]="!isChrome || !isWebrtcSupported || !appCallingEnabled"
            matTooltipClass="onsip-tooltip-background"
            (change)="toggleAutoAnswer($event)"
            [matTooltip]="
              !isChrome || !isWebrtcSupported || !appCallingEnabled
                ? ('ONSIP_I18N.YOUR_BROWSER_DOES_NOT_SUPPORT_THIS_FUNCTION' | translate)
                : ''
            "
          >
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="sip-username-password-details">
      <div class="left card-section">
        <span class="label">
          {{ "ONSIP_I18N.AUTH_USERNAME" | translate }}
        </span>
        <p class="mat-body-2">{{ authUsername | async }}</p>
      </div>
      <div class="right card-section">
        <span class="label">
          {{ "ONSIP_I18N.SIP_PASSWORD" | translate }}
        </span>
        <div
          (mouseenter)="showAuthPassword = true"
          (mouseleave)="showAuthPassword = false"
          class="user-settings-hover-password"
        >
          @if (!showAuthPassword) {
          <span id="onsip-user-settings-sip-password">
            {{ "ONSIP_I18N.HOVER_TO_REVEAL" | translate }}
          </span>
          } @if (showAuthPassword) {
          <span id="onsip-user-settings-sip-password">
            {{ authPassword | async }}
          </span>
          }
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
