import { LogService } from "../../../../common/services/logging";

import { timezoneArray } from "./timeZones";
import {
  QueueWarningsService,
  QueueObject,
  WarningObject,
  QueueBhrShift
} from "./queue-warnings.service";

import { Component, ViewChild, Input, OnInit } from "@angular/core";
import { EditAdvQueueWarningParams } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning";

@Component({
  selector: "onsip-queue-warnings",
  templateUrl: "./queue-warnings.component.html",
  styleUrls: ["./queue-warnings.scss"]
})
export class QueueWarningsComponent implements OnInit {
  @ViewChild("warningForm") warningForm!: any;

  @Input() warningSettings: any;
  @Input() warningIndex = -1;

  timeZones: Array<any> = timezoneArray;
  triedToSubmit = false;

  thresholdTypes: Array<string> = ["too_few_agents", "too_many_callers", "too_long_waiting"];
  thresholdPrefix: Record<string, string> = {
    too_few_agents: "there are fewer than",
    too_many_callers: "there are more than",
    too_long_waiting: "a caller has been waiting for"
  };
  thresholdSuffix: Record<string, string> = {
    too_few_agents: "agents logged in",
    too_many_callers: "callers waiting",
    too_long_waiting: "seconds"
  };
  queues: QueueObject["queues"] = [];

  constructor(private log: LogService, private queueWarningsService: QueueWarningsService) {}

  ngOnInit(): void {
    this.queueWarningsService.getQueueObj().then(queueObj => {
      this.queues = queueObj.queues;
    });

    // One shift defined by default
    if (this.warningSettings && this.warningSettings.editObj.editBhr.length === 0) {
      this.addBhrShift();
    }
  }

  /**
   * When the user checks the 24 hours checkbox, ignore the start and end
   * timestamps.
   * When the user unchecks the box, we need to validate the timestamps again
   * to ensure that the whole form is validated correctly.
   */
  validate24Hours(bhrShift: any): void {
    if (this.warningForm.controls["bhr" + bhrShift + "allDay"].value) {
      return;
    } else {
      this.validateTimeShift(bhrShift);
    }
  }

  validateTimeShift(bhrShift: any): any {
    if (
      !this.warningForm ||
      !this.warningForm.controls ||
      this.warningForm.controls["bhr" + bhrShift + "allDay"].value
    ) {
      return undefined;
    }
    if (
      !this.warningForm.controls["bhr" + bhrShift + "startTime"] ||
      !this.warningForm.controls["bhr" + bhrShift + "startTime"].value ||
      !this.warningForm.controls["bhr" + bhrShift + "endTime"] ||
      !this.warningForm.controls["bhr" + bhrShift + "endTime"].value
    ) {
      return undefined;
    }

    const startTime = this.convertToDate(
        this.warningForm.controls["bhr" + bhrShift + "startTime"].value
      ),
      endTime = this.convertToDate(this.warningForm.controls["bhr" + bhrShift + "endTime"].value);

    if (startTime && endTime) {
      if (startTime > endTime) {
        return {
          validateTimeShift: {
            valid: false
          }
        };
      }
    } else {
      return {
        validateTimeShift: {
          valid: false
        }
      };
    }
    return undefined;
  }

  timeEqual(t1: any, t2: any): boolean {
    return t1[0] === t2[0] && t1[1] === t2[1];
  }

  convertToDate(timeString: string): Date | undefined {
    try {
      const pieces = timeString.split(":");
      if (pieces.length > 2) {
        return;
      }
      const date = new Date();
      date.setHours(parseInt(pieces[0]), parseInt(pieces[1]), 0, 0);
      return date;
    } catch (e) {
      return;
    }
  }

  localToApiBhr(bhr: Array<QueueBhrShift>, timeZone: string): Record<number | "TimeZone", any> {
    return bhr.reduce(
      (accum: Record<number | "TimeZone", any>, bhrShift) => {
        for (let i = 0; i < bhrShift.days.length; i++) {
          if (bhrShift.days[i].isInShift) {
            if (i in accum) {
              throw new Error("Multiple time shifts defined for the same day.");
            } else {
              if (bhrShift.allDay) {
                accum[i] = ["00:00", "23:59"];
              } else {
                const startTime = this.convertToDate(bhrShift.time[0]),
                  endTime = this.convertToDate(bhrShift.time[1]),
                  startString = startTime
                    ? (startTime.getHours() < 10 ? "0" : "") +
                      startTime.getHours() +
                      ":" +
                      (startTime.getMinutes() < 10 ? "0" : "") +
                      startTime.getMinutes()
                    : "00:00",
                  endString = endTime
                    ? (endTime.getHours() < 10 ? "0" : "") +
                      endTime.getHours() +
                      ":" +
                      (endTime.getMinutes() < 10 ? "0" : "") +
                      endTime.getMinutes()
                    : "00:00";

                // Cloning values
                accum[i] = [startString, endString];
              }
            }
          }
        }
        return accum;
      },
      { TimeZone: timeZone }
    );
  }

  localToApiWarningObj(localObj: WarningObject): EditAdvQueueWarningParams {
    return {
      warningId: localObj.warningId,
      queueId: localObj.editObj.queue.id,
      thresholdType: localObj.editObj.thresholdType,
      thresholdValue: localObj.editObj.thresholdValue,
      alertType: localObj.editObj.alertType,
      alertDestination: localObj.editObj.alertDestination,
      cooldownSeconds: localObj.editObj.cooldownMinutes * 60,
      bhr: this.localToApiBhr(localObj.editObj.editBhr, localObj.editObj.timeZone)
    };
  }

  localFormatBhr(localObj: any, formattedBhr: any): any {
    localObj.viewObj.bhr =
      formattedBhr || this.localToApiBhr(localObj.editObj.editBhr, localObj.editObj.timeZone);
    localObj.editObj.editBhr = this.queueWarningsService.bhrToEditBhr(localObj.viewObj.bhr);

    return localObj;
  }

  saveEditToView(localObj: any, formattedBhr: any) {
    localObj = this.localFormatBhr(localObj, formattedBhr);
    localObj.viewObj.queue.id = localObj.editObj.queue.id;
    localObj.viewObj.queue.name = localObj.editObj.queue.name;
    localObj.viewObj.queue.address = localObj.editObj.queue.address;
    localObj.viewObj.thresholdType = localObj.editObj.thresholdType;
    localObj.viewObj.thresholdValue = localObj.editObj.thresholdValue;
    localObj.viewObj.alertType = localObj.editObj.alertType;
    localObj.viewObj.alertDestination = localObj.editObj.alertDestination;
    localObj.viewObj.cooldownMinutes = localObj.editObj.cooldownMinutes;

    return localObj;
  }

  _cancelEdit(localObj: any): any {
    localObj.editObj.queue.id = localObj.viewObj.queue.id;
    localObj.editObj.queue.name = localObj.viewObj.queue.name;
    localObj.editObj.thresholdType = localObj.viewObj.thresholdType;
    localObj.editObj.thresholdValue = localObj.viewObj.thresholdValue;
    localObj.editObj.alertType = localObj.viewObj.alertType;
    localObj.editObj.alertDestination = localObj.viewObj.alertDestination;
    localObj.editObj.coolDownMinutes = localObj.viewObj.coolDownMinutes;
    localObj = this.localFormatBhr(localObj, localObj.viewObj.bhr);
    localObj.editObj.timeZone = localObj.viewObj.bhr.TimeZone;
    localObj.displayMode = "view";

    return localObj;
  }

  /**
   * bhrUpdated is optional. It's presence makes us skip looking at it, since we
   * have an invariant that the updated bhr has valid days set and available.
   */
  updateBhr(bhrUpdated?: QueueBhrShift): void {
    const allBhrs: Array<QueueBhrShift> = this.warningSettings.editObj.editBhr,
      daysAvailable: any = allBhrs.reduce(
        (dayList, bhr) => {
          for (let i = 0; i < bhr.days.length; i++) {
            dayList[i] = dayList[i] && !bhr.days[i].isInShift;
          }
          return dayList;
        },
        Array.from(Array(7)).map(() => true)
      );

    allBhrs.forEach(bhr => {
      for (let i = 0; i < bhr.days.length; i++) {
        if (!bhr.days[i].isInShift) {
          bhr.days[i].isAvailable = daysAvailable[i];
        } else if (bhrUpdated && bhr.days[i].isInShift === bhrUpdated.days[i].isInShift) {
          // Day is in enabled for this shift and it equals my value, so we
          // are looking at my BHR shift and can skip it.
          break;
        }
      }
    });
  }

  addBhrShift(): void {
    const editBhr: Array<QueueBhrShift> = this.warningSettings.editObj.editBhr,
      newShift = this.queueWarningsService.localBhrDefaultShift();

    // Determine days set so far and disable those previously set
    newShift.days = editBhr.reduce((soFar, shift) => {
      for (let i = 0; i < shift.days.length; i++) {
        soFar[i].isAvailable = soFar[i].isAvailable && !shift.days[i].isInShift;
      }
      return soFar;
    }, newShift.days);

    // Now that we've made previously set days unavailable, we can enable all
    // remaining days. This must occur after setting unavailable days because
    // we do not exclude our bhr shift when checking set days.
    newShift.days.map(day => {
      day.isInShift = day.isAvailable;
    });

    editBhr.push(newShift);
    this.warningSettings.editObj.editBhr = editBhr;
    this.updateBhr(newShift);
  }

  removeBhrShift(index: number): void {
    this.warningSettings.editObj.editBhr.splice(index, 1);
    this.updateBhr(undefined);
  }

  checkPostWarning(): void {
    this.triedToSubmit = true;

    if (!this.warningSettings.editObj.cooldownMinutes) {
      this.warningSettings.editObj.cooldownMinutes = 15;
    }

    if (this.warningForm.valid) {
      const apiArgs: EditAdvQueueWarningParams = this.localToApiWarningObj(this.warningSettings);
      (this.warningSettings.saved
        ? this.queueWarningsService.editWarning(apiArgs)
        : this.queueWarningsService.addWarning(apiArgs)
      )
        .then(warningData => {
          const queueAddr: string = warningData.queueAddress,
            warningId: string = warningData.warningId;
          this.warningSettings.warningId = warningId;
          this.warningSettings.editObj.queue.address = queueAddr;
          this.warningSettings.viewObj.queue.address = queueAddr;
          this.warningSettings = this.saveEditToView(this.warningSettings, apiArgs.bhr);
          this.warningSettings = this.localFormatBhr(this.warningSettings, apiArgs.bhr);
          this.warningSettings.saved = true;
          this.warningSettings.displayMode = "view";

          this.queueWarningsService.getQueueObj().then(queueObj => {
            const queueName: string = queueObj.queueMap[this.warningSettings.editObj.queue.id].name;

            this.warningSettings.editObj.queue.name = queueName;
            this.warningSettings.viewObj.queue.name = queueName;
          });
        })
        .catch(err => {
          try {
            const errorParam: string = err.Response.Context.Request.Errors.Error.Parameter;

            if (errorParam === "ThresholdValue") {
              this.warningForm.thresholdValue.$invalid = true;
              this.warningForm.thresholdValue.$valid = false;
            }
          } catch (e) {
            console.error("warning form format error:", e);
          }

          this.log.error(err);
        });
    }
  }

  cancelEdit(): boolean {
    // this just deletes now, no idea what the thought was here
    if (this.warningSettings.saved) {
      this.deleteWarning();
    }
    return false;
  }

  condensedBhrString(bhr: any): string {
    const indexOfWeek: any = {
        0: "Mon",
        1: "Tue",
        2: "Wed",
        3: "Thu",
        4: "Fri",
        5: "Sat",
        6: "Sun"
      },
      timeGroups: Array<any> = [];
    let timeGroup: Array<any> = [], // Consecutive sequences of days with same time
      time: any;

    // Go to 8 because we will never define it and we need look-behind.
    for (let i = 0; i < 8; i++) {
      if (bhr[i]) {
        if (bhr[i - 1] && this.timeEqual(bhr[i], bhr[i - 1])) {
          // Part of a consecutive sequence of the same time
          timeGroup.push(i);
        } else {
          // The start of a new time group
          if (timeGroup.length > 0) {
            // If there was an old one, store it
            timeGroups.push({
              days: timeGroup.map(dayIndex => {
                return indexOfWeek[dayIndex];
              }),
              time
            });
          }
          timeGroup = [i];
          time = bhr[i];
        }
      } else {
        if (timeGroup.length > 0) {
          timeGroups.push({
            days: timeGroup.map(dayIndex => {
              return indexOfWeek[dayIndex];
            }),
            time
          });
        }
        timeGroup = [];
        time = undefined;
      }
    }

    let bhrStr: string = timeGroups.reduce((partStr, tg) => {
      if (tg.days.length >= 2) {
        partStr += tg.days[0] + "-" + tg.days[tg.days.length - 1];
      } else {
        partStr += tg.days[0];
      }

      partStr += ": ";
      if (tg.time[0] === "00:00" && tg.time[1] === "23:59") {
        partStr += "24 hrs";
      } else {
        partStr += tg.time[0] + "-" + tg.time[1];
      }

      partStr += ", ";
      return partStr;
    }, "");

    // Remove trailing comma and space
    bhrStr = bhrStr.replace(/, $/, "");
    return bhrStr;
  }

  deleteWarning(): void {
    const warningId: string = this.warningSettings.warningId;

    this.queueWarningsService
      .deleteWarning(warningId, this.warningIndex, this.warningSettings.viewObj.queue.address)
      .catch(err => {
        this.log.error(err);
      });
  }
}
