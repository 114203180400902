declare let window: any;

import {
  Component,
  OnInit,
  OnDestroy,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Observable, combineLatest, Subscription } from "rxjs";
import { filter, pluck, map, take } from "rxjs/operators";

import { SupportService } from "../../../common/services/support/support.service";
import { LocalStorageService } from "../shared/services/localStorage/local-storage.service";
import { AdvQueueService } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { IdentityService } from "../../../common/services/identity.service";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { AutoAnswerService } from "../shared/services/autoAnswer/auto-answer.service";
import { AppCallingService } from "../shared/services/appCalling/app-calling.service";
import { NotificationsService } from "../shared/services/notifications/notifications.service";
import { TranslateService } from "@ngx-translate/core";

import { UserAgentState } from "../../../common/libraries/sip/user-agent";
import { OnSIPURI } from "../../../common/libraries/onsip-uri";
import { E164PhoneNumber } from "../../../common/libraries/e164-phone-number";

import { Config } from "../../../common/config";

import { AvatarUploadModalComponent } from "../shared/components/imageUploader/uploadModal/avatar-upload-modal.component";
import { ModalMaterialComponent } from "../shared/components/modal/modal-material.component";
import { UserSentryReportComponent } from "./user-sentry-report.component";

import { UserSummaryContactWithAddresses } from "../../../common/services/api/resources/userSummaryContact/user-summary-contact";
import { ApiSessionService } from "../../../common/services/api/api-session.service";
import { UserAddressService } from "../../../common/services/api/resources/userAddress/user-address.service";
import { UserSummaryContactService } from "../../../common/services/api/resources/userSummaryContact/user-summary-contact.service";
import { UserService } from "../../../common/services/api/resources/user/user.service";
import { TelephoneNumberAddressService } from "@onsip/common/services/api/resources/telephoneNumberAddress/telephone-number-address.service";
import { UserOauth2AccessTokenService } from "@onsip/common/services/api/resources/userOAuth2AccessToken/user-oauth2-access-token.service";

interface FailoverInfo {
  Name: string;
  Address: string;
}

interface UserContactInfo {
  phoneNumbers: Array<string>;
  extensions: Array<string>;
}

@Component({
  selector: "onsip-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  email!: Observable<string>;
  accountId!: Observable<string>;
  authUsername!: Observable<string>;
  authPassword!: Observable<string>;
  e911Enabled!: Observable<boolean>;
  thirdPartySignInService: string | undefined;
  showAuthPassword = false;
  failoverTime = "Hangup after 2 minutes";
  showFailoverAddress = false;
  sipAddress!: string;
  notificationTypes: Array<string> = [];
  notifications: Record<string, boolean> = {};
  userProfile: UserContactInfo = { phoneNumbers: [], extensions: [] };
  secondsArr: Array<string> = ["Hangup after 2 minutes"];
  isWebrtcSupported = true;
  isChrome = true;
  isNotificationsDisabled: boolean =
    !window.Notification || window.Notification.permission === "denied";
  showQueueWarnings = false;
  userAgentStates: Array<UserAgentState> = [];
  failoverSelection: string | undefined;
  failoverOptions: Array<FailoverInfo> = [];
  versionNumber: string = Config.VERSION_NUMBER;
  autoAnswerEnabled!: Observable<boolean>;
  appCallingEnabled = false;
  allNotificationsEnabled = false;
  defaultUA = "";
  dndStream: Observable<boolean> = this.callControllerService.getDoNotDisturbObservable();

  private foreverAddress = "notAddress";
  private tokenId: string | undefined;

  private unsubscriber = new Subscription();

  constructor(
    private supportService: SupportService,
    private localStorageService: LocalStorageService,
    private callControllerService: CallControllerService,
    private queueService: AdvQueueService,
    private userOauth2AccessTokenService: UserOauth2AccessTokenService,
    private apiSession: ApiSessionService,
    private identityService: IdentityService,
    private autoAnswerService: AutoAnswerService,
    private appCallingService: AppCallingService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private userAddress: UserAddressService,
    private userSummaryContact: UserSummaryContactService,
    private userService: UserService,
    private telephoneNumberAddress: TelephoneNumberAddressService,
    private cdrRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.email = this.userService.selfUser.pipe(pluck("contact", "email"));
    this.accountId = this.userService.selfUser.pipe(pluck("accountId"));
    this.authUsername = this.userService.selfUser.pipe(pluck("username"));
    this.authPassword = this.userService.selfUser.pipe(pluck("password"));
    this.e911Enabled = this.userService.selfUser.pipe(pluck("e911Provisioning"));

    this.unsubscriber.add(
      combineLatest([
        this.identityService.state.pipe(filter(state => state.addresses.length > 0)),
        this.userSummaryContact.state.pipe(
          filter(state => !state.loading),
          map(state => state.state)
        ),
        this.userAddress.state.pipe(
          filter(state => !state.loading),
          map(state => state.state)
        )
      ]).subscribe(([identity, userSummaryContactBrowse, userAddresses]) => {
        const primaryUserAddress = identity.defaultIdentity;
        this.defaultUA = primaryUserAddress ? primaryUserAddress.aor : "";

        if (primaryUserAddress) {
          const userSummaryContact = userSummaryContactBrowse[primaryUserAddress.userId];

          const userAddress = Object.values(userAddresses).find(
            address => address.userAddressId === primaryUserAddress.userAddressId
          );

          if (userSummaryContact && userAddress) {
            const summary: UserSummaryContactWithAddresses = userSummaryContact;
            summary.previousDefaultAddress = userAddress.previousDefaultAddress;
            summary.defaultAddress = userAddress.defaultAddress;
            summary.timeout = userAddress.timeout;
            summary.sipAddress = userAddress.aor;
            summary.domain = userAddress.domain;
            summary.authUsername = userAddress.authUsername;
            summary.authPassword = userAddress.authPassword;
            this.loadUserProfile(summary);
          }
        }
      })
    );

    this.unsubscriber.add(
      this.apiSession.state
        .pipe(
          filter(state => !!state.thirdPartySignInInfo),
          pluck("thirdPartySignInInfo"),
          take(1)
        )
        .subscribe(info => {
          if (info) {
            this.thirdPartySignInService = info.Service;
            this.tokenId = info.UserOAuth2AccessTokenId;
          }
        })
    );

    this.autoAnswerEnabled = this.autoAnswerService.state.pipe(map(state => state.enabled));
    this.unsubscriber.add(
      this.appCallingService.state.subscribe(state => (this.appCallingEnabled = state.enabled))
    );

    this.unsubscriber.add(
      this.notificationsService.state.subscribe(state => {
        this.notifications = state.notifications;
        this.notificationTypes = Object.keys(state.notifications);
        this.allNotificationsEnabled = Object.values(state.notifications).every(val => !!val);
      })
    );

    for (let i = 0; i < 24; i++) {
      const seconds: number = (i + 1) * 5;
      this.secondsArr.push(seconds + " seconds");
    }

    this.isWebrtcSupported = this.supportService.isWebrtcSupported();
    this.isChrome = this.supportService.getBrowser().name === "chrome";

    this.telephoneNumberAddress
      .telephoneNumberAddressBrowseWithoutOrgId({
        Limit: 25000
      })
      .then(response => {
        if (response.status === "success") {
          const telephoneNumberAddresses = Object.values(response.data);
          for (const eachNumber of telephoneNumberAddresses) {
            const addressStr: string = eachNumber.username + "@" + eachNumber.domain;

            this.failoverOptions.push({
              Name: eachNumber.name + " (" + addressStr + ")",
              Address: addressStr
            });
          }

          this.checkFailoverOptionsForDupes();
        }
      });

    this.queueService.getSmartQueuesWithoutOrgId().then(queues => {
      this.showQueueWarnings = queues.length > 0;
      this.cdrRef.markForCheck();
    });

    this.unsubscriber.add(
      this.callControllerService.state.subscribe(state => {
        this.userAgentStates = state.userAgents;
        this.cdrRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  onProvideFeedbackClick(): void {
    this.dialog.open(UserSentryReportComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"]
    });
  }

  openUploadAvatarModal(): void {
    this.dialog.open(AvatarUploadModalComponent, {
      panelClass: ["onsip-dialog-universal-style"]
    });
  }

  changeUA(aor: string): void {
    if (this.callControllerService.hasUserAgent(aor)) {
      this.identityService.setDefaultIdentity(aor);
    }
  }

  setNotification(type: string, checked: boolean) {
    this.notificationsService.setNotification(type, checked);
  }

  toggleRegistration(aor: string, register: boolean): void {
    this.callControllerService.switchUserAgentRegisterState(aor, register);
    this.localStorageService.setUserAgent(aor, { register });
  }

  toggleAutoAnswer(shouldEnable: MatSlideToggleChange): void {
    if (shouldEnable.checked) {
      this.autoAnswerService.enable();
    } else {
      this.autoAnswerService.disable();
    }
  }

  toggleDesktopMode(shouldEnable: MatSlideToggleChange): void {
    if (shouldEnable.checked) {
      this.appCallingService.enable();
    } else {
      this.appCallingService.disable();
    }
  }

  /** Do Not Disturb Slider callback */
  toggleDnd(sliderChange: MatSlideToggleChange) {
    this.callControllerService.setDoNotDisturb(sliderChange.checked);
    this.localStorageService.setDoNotDisturb(sliderChange.checked);
  }

  deleteThirdPartySignIn(): void {
    const serviceName: string = (this.thirdPartySignInService || "").replace("Login", "");

    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: this.translate.instant("ONSIP_I18N.DISCONNECT_VALUE_ACCOUNT", {
          value: serviceName
        }),
        message: this.translate.instant(
          "ONSIP_I18N.WOULD_YOU_LIKE_TO_DISCONNECT_YOUR_VALUE_ACCOUNT_FROM_YOUR_ONSIP_ACCOUNT_THIS_WILL_SIGN_YOU_OUT",
          { value: serviceName }
        ),
        primaryBtnText: this.translate.instant("ONSIP_I18N.DISCONNECT"),
        primaryBtnFlat: true
      }
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(response => {
          if (response && response.doPrimaryAction && this.tokenId) {
            this.userOauth2AccessTokenService.UserOAuth2AccessTokenDelete(this.tokenId).then(() => {
              this.thirdPartySignInService = undefined;
              this.platformCloseWindow();
            });
          }
        })
    );
  }

  updateFailover(newValue: string | undefined): void {
    this.failoverSelection = newValue || this.failoverSelection;

    if (!this.sipAddress) {
      throw new Error("updateFailover: no sip address set");
    }

    if (this.failoverTime === "Hangup after 2 minutes") {
      this.showFailoverAddress = false;
      this.failoverSelection = this.foreverAddress;

      this.userAddress.userAddressEditDefaultAddress({
        Address: this.sipAddress,
        Timeout: 30,
        DefaultAddress: ""
      });
    } else {
      const timeParam: number = parseInt(this.failoverTime.replace(" seconds", ""));

      this.showFailoverAddress = true;
      if (!this.failoverSelection || this.failoverSelection === this.foreverAddress) {
        this.failoverSelection = this.foreverAddress;
        this.failoverOptions.unshift({
          Name: "Please select a backup sip address",
          Address: this.foreverAddress
        });

        this.checkFailoverOptionsForDupes();
      } else {
        this.userAddress.userAddressEditDefaultAddress({
          Address: this.sipAddress,
          DefaultAddress: this.failoverSelection,
          Timeout: timeParam
        });
      }
    }
  }

  private loadUserProfile(userSummary: UserSummaryContactWithAddresses): void {
    if (userSummary.sipAddress) this.sipAddress = userSummary.sipAddress;

    const phoneNumbers: Array<string> = [];
    const extensions: Array<string> = [];

    const e164Aliases = userSummary.e164Aliases || [];
    e164Aliases.forEach(e164Alias => {
      const parsedE164Num = new E164PhoneNumber(e164Alias);
      if (parsedE164Num.isValid) {
        const phoneNumber = parsedE164Num.e164DisplayNumber;
        phoneNumbers.push(phoneNumber);
      }
    });

    const contactPhone = userSummary.contactPhone || "";
    const e164Num = new E164PhoneNumber(contactPhone);
    if (e164Num.isValid) {
      const phoneNumber = e164Num.e164DisplayNumber;
      if (!this.userProfile.phoneNumbers.some(num => num === phoneNumber)) {
        phoneNumbers.push(phoneNumber);
      }
    }

    const allAliases = userSummary.aliases || [];
    allAliases.forEach(alias => {
      if (!alias.startsWith("sip:")) {
        alias = "sip:" + alias + "@" + userSummary.domain;
      }

      const uri: OnSIPURI | undefined = OnSIPURI.parseString(alias);
      if (uri && uri.user && uri.isExtension()) {
        extensions.push(uri.user);
      }
    });
    this.userProfile.phoneNumbers = phoneNumbers;
    this.userProfile.extensions = extensions;

    if (userSummary.defaultAddress?.username) {
      const failover = userSummary.defaultAddress,
        failoverTimeout = userSummary.timeout,
        failoverAddress = failover.username + "@" + failover.domain,
        failoverName = failover.name + " (" + failoverAddress + ")";

      if (failoverTimeout) {
        this.showFailoverAddress = true;
      }
      this.failoverTime = failoverTimeout + " seconds";

      this.failoverOptions.unshift({
        Name: failoverName,
        Address: failoverAddress
      });

      this.checkFailoverOptionsForDupes();

      this.failoverSelection = this.failoverOptions[0].Address;
    } else {
      this.failoverTime = "Hangup after 2 minutes";
      this.showFailoverAddress = false;
    }

    if (userSummary.previousDefaultAddress?.username) {
      const prevName: string = userSummary.previousDefaultAddress.name,
        prevDomain: string = userSummary.previousDefaultAddress.domain,
        prevUsername: string = userSummary.previousDefaultAddress.username,
        prevAddress: string = prevUsername + "@" + prevDomain,
        failoverCompleteName: string = prevName + " (" + prevAddress + ")";

      this.failoverOptions.unshift({
        Name: failoverCompleteName,
        Address: prevAddress
      });

      this.checkFailoverOptionsForDupes();
    }
    this.cdrRef.markForCheck();
  }

  private platformCloseWindow(): void {
    if (Config.IS_DESKTOP || Config.IS_WEB) {
      document.cookie =
        "sessionId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
      document.cookie =
        "userId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
      if (Config.IS_WEB) {
        window.location.href = ".";
      } else {
        window.electron.sendMessage("log-out");
      }
    }
  }

  // Note: only removes one duplicate and then breaks out of loop, should be enough for our purposes
  private checkFailoverOptionsForDupes(): void {
    // dupe = duplicate
    const countObj: Record<string, number> = {};
    let removedDupeArr: Array<FailoverInfo> | undefined;

    for (let i = 0; i < this.failoverOptions.length; i++) {
      const option = this.failoverOptions[i];
      if (countObj[option.Address]) {
        const beginning: Array<FailoverInfo> = this.failoverOptions.slice(0, i),
          ending: Array<FailoverInfo> = this.failoverOptions.slice(
            i + 1,
            this.failoverOptions.length
          );

        removedDupeArr = beginning.concat(ending);
        break;
      } else {
        countObj[option.Address] = 1;
      }
    }

    if (removedDupeArr) {
      this.failoverOptions = removedDupeArr;
    }
  }
}
