<onsip-header-main>
  <onsip-header-page-title>
    {{ "ONSIP_I18N.QUEUE_DASHBOARD" | translate | sentenceCase }}
  </onsip-header-page-title>
  <onsip-header-action-bar>
    <mat-form-field appearance="outline">
      <input
        matInput
        [formControl]="search"
        [placeholder]="'ONSIP_I18N.SEARCH' | translate"
        autocomplete="off"
      />
      <mat-icon color="primary" matSuffix>search</mat-icon>
    </mat-form-field>
    @if (hasQueue | async) {
    <button
      mat-stroked-button
      onsip-header-secondary-action
      id="onsip--queue-realtime--queue-report-button"
      color="primary"
      (click)="openQueueReport()"
    >
      {{
        hasQueueReport
          ? ("ONSIP_I18N.CLOSE_REPORTS" | translate)
          : ("ONSIP_I18N.VIEW_REPORTS" | translate)
      }}
    </button>
    }
  </onsip-header-action-bar>
</onsip-header-main>
<!-- Queue Report -->
@if ((hasQueue | async) === false) {
<div class="empty-state">
  <img [src]="'resources/img/call-history-empty-state.svg'" alt="No Queues" />
  <h4>{{ "ONSIP_I18N.THERE_ARE_NO_QUEUES" | translate }}</h4>
  <span class="mat-body-2">{{
    "ONSIP_I18N.CREATE_A_NEW_QUEUE_BY_GOING_TO_THE_ADMIN_PORTAL" | translate
  }}</span>
</div>
} @if (hasQueue | async) {
<div class="queue-status-body">
  @if (hasQueueReport) {
  <div class="queue-report-container">
    <onsip-queue-historical></onsip-queue-historical>
    <mat-divider onsip-dotted-divider></mat-divider>
  </div>
  }
  <div
    class="queue-table-container"
    [hidden]="!((tableLoaded | async) && queueTableData.filteredData.length > 0)"
  >
    <!-- Desktop View -->
    <onsip-table-two
      multiTemplateDataRows
      [dataSource]="queueTableData"
      class="queue-realtime-table"
      [hoverable]="true"
      [gridTemplateColumns]="'2.75fr repeat(6, 1fr)'"
    >
      <!-- Queue Name Column -->
      <ng-container matColumnDef="queue-name">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.NAME" | translate }}</mat-header-cell>
        <mat-cell
          class="queue-cell-name"
          *matCellDef="let queue"
          (click)="
            expandedElements.includes(queue)
              ? expandedElements.splice(expandedElements.indexOf(queue), 1)
              : expandedElements.push(queue)
          "
        >
          {{ queue.name }}
        </mat-cell>
      </ng-container>
      <!-- Max Wait Column -->
      <ng-container matColumnDef="queue-max-wait">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.MAX_WAIT" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let queue">
          @if (!queue.displayWaitTime || queue.displayWaitTime === waitDefaultTime) {
          <div class="queue-max-wait">
            {{ queue.displayWaitTime || waitDefaultTime }}
          </div>
          } @else {
          <div class="queue-max-wait">
            <onsip-timer
              [referencePoint]="queue.displayWaitTime"
              [hoursZeroes]="false"
            ></onsip-timer>
          </div>
          }
        </mat-cell>
      </ng-container>
      <!-- Waiting Column -->
      <ng-container matColumnDef="queue-waiting">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.WAITING" | translate }}</mat-header-cell>
        <mat-cell class="caller-cells" *matCellDef="let queue">
          <onsip-realtime-caller-table
            [displayType]="'waiting'"
            [callerData]="queue.overview.waiting"
          >
          </onsip-realtime-caller-table>
        </mat-cell>
      </ng-container>
      <!-- On Call Column -->
      <ng-container matColumnDef="queue-on-call">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.ON_CALL" | translate }}</mat-header-cell>
        <mat-cell class="caller-cells" *matCellDef="let queue">
          <onsip-realtime-caller-table
            [displayType]="'on call'"
            [callerData]="queue.overview.onCall"
          >
          </onsip-realtime-caller-table>
        </mat-cell>
      </ng-container>
      <!-- Abandoned Column -->
      <ng-container matColumnDef="queue-abandoned">
        <mat-header-cell *matHeaderCellDef>{{
          "ONSIP_I18N.ABANDONED" | translate
        }}</mat-header-cell>
        <mat-cell class="caller-cells" *matCellDef="let queue">
          <onsip-realtime-caller-table
            [displayType]="'abandoned'"
            [callerData]="queue.overview.abandoned"
          >
          </onsip-realtime-caller-table>
        </mat-cell>
      </ng-container>
      <!-- Completed Column -->
      <ng-container matColumnDef="queue-completed">
        <mat-header-cell *matHeaderCellDef>{{
          "ONSIP_I18N.COMPLETED" | translate
        }}</mat-header-cell>
        <mat-cell class="caller-cells" *matCellDef="let queue">
          <onsip-realtime-caller-table
            [displayType]="'completed'"
            [callerData]="queue.overview.completed"
          >
          </onsip-realtime-caller-table>
        </mat-cell>
      </ng-container>
      <!-- Failover Column -->
      <ng-container matColumnDef="queue-failover">
        <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.FAILOVER" | translate }}</mat-header-cell>
        <mat-cell class="caller-cells" *matCellDef="let queue">
          <onsip-realtime-caller-table
            [displayType]="'failover'"
            [callerData]="queue.overview.failed"
          >
          </onsip-realtime-caller-table>
        </mat-cell>
      </ng-container>
      <!-- Expanded Detail Column -->
      <ng-container matColumnDef="expanded">
        <onsip-table-shelf-cell
          *matCellDef="let element"
          [expanded]="expandedElements.includes(element)"
          [override]="true"
          class="queue-expanded-cell"
        >
          @if (expandedElements.includes(element)) {
          <div class="caller-agent-table">
            <onsip-realtime-agent-table [selectedQueue]="element"> </onsip-realtime-agent-table>
          </div>
          }
        </onsip-table-shelf-cell>
      </ng-container>
      <!-- Mobile View -->
      <ng-container matColumnDef="mobile">
        <cdk-cell *cdkCellDef="let snapshot">
          <div class="queue-status-item">
            <span
              class="queue-status-mobile-name mat-body-1"
              id="onsip--queue-realtime--queue-snapshot-{{ snapshot.name }}"
              (click)="
                expandedElements.includes(snapshot)
                  ? expandedElements.splice(expandedElements.indexOf(snapshot), 1)
                  : expandedElements.push(snapshot)
              "
            >
              <span class="queue-status-mobile-queue-name">{{ snapshot.name }}</span>
              @if (expandedElements.includes(snapshot)) {
              <button mat-icon-button>
                <mat-icon aria-label="show agents">arrow_drop_up</mat-icon>
              </button>
              } @if (!expandedElements.includes(snapshot)) {
              <button mat-icon-button>
                <mat-icon aria-label="hide agents">arrow_drop_down</mat-icon>
              </button>
              }
            </span>
            <span class="queue-status-mobile-display-wait">
              {{ "ONSIP_I18N.MAX_WAIT" | translate }}&nbsp; @if (!snapshot.displayWaitTime ||
              snapshot.displayWaitTime === waitDefaultTime) {
              <div class="queue-max-wait">
                <b>{{ snapshot.displayWaitTime || waitDefaultTime }}</b>
              </div>
              } @else {
              <div class="queue-max-wait">
                <b
                  ><onsip-timer
                    [referencePoint]="snapshot.displayWaitTime"
                    [hoursZeroes]="false"
                  ></onsip-timer
                ></b>
              </div>
              }
            </span>
            @if (expandedElements.includes(snapshot)) {
            <div class="caller-agent-table">
              <onsip-realtime-agent-table [selectedQueue]="snapshot"> </onsip-realtime-agent-table>
            </div>
            }
            <table
              mat-table
              multiTemplateDataRows
              [dataSource]="[
                {
                  name: 'waiting',
                  data: snapshot.overview.waiting,
                  displayName: 'ONSIP_I18N.WAITING' | translate
                },
                {
                  name: 'on call',
                  data: snapshot.overview.onCall,
                  displayName: 'ONSIP_I18N.ON_CALL' | translate
                },
                {
                  name: 'abandoned',
                  data: snapshot.overview.abandoned,
                  displayName: 'ONSIP_I18N.ABANDONED' | translate
                },
                {
                  name: 'completed',
                  data: snapshot.overview.completed,
                  displayName: 'ONSIP_I18N.COMPLETED' | translate
                },
                {
                  name: 'failover',
                  data: snapshot.overview.failed,
                  displayName: 'ONSIP_I18N.FAILOVER_1' | translate
                }
              ]"
              class="queue-status-table"
            >
              <ng-container matColumnDef="overview-name">
                <td mat-cell *matCellDef="let element" class="overview-name even-column">
                  <p>{{ element.displayName }}</p>
                </td>
              </ng-container>
              <ng-container matColumnDef="overview-data">
                <td mat-cell *matCellDef="let element" class="mobile-columns">
                  <onsip-realtime-caller-table
                    [displayType]="element.name"
                    [callerData]="element.data"
                  >
                  </onsip-realtime-caller-table>
                </td>
              </ng-container>
              <tr
                mat-row
                *matRowDef="let element; columns: ['overview-name', 'overview-data']"
                class="queue-status-row"
              ></tr>
            </table>
          </div>
        </cdk-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="(isMobile | async) ? null : displayedColumns"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; when: showDesktopRow.bind(this)"
      ></mat-row>
      <onsip-table-shelf
        *matRowDef="let row; columns: ['expanded']; when: showDesktopRow.bind(this)"
      ></onsip-table-shelf>
      <!-- using onsip-row because it doesn't add any row styling, but we can add a new custom row for this too or just use cdk-row -->
      <onsip-row
        *matRowDef="let row; columns: ['mobile']; when: showMobileRow.bind(this)"
      ></onsip-row>
    </onsip-table-two>
    <mat-paginator
      [hidden]="queueTableData.data.length <= pageSize"
      [length]="queueTableData.data.length"
      [pageSize]="pageSize"
      [hidePageSize]="true"
      onsip-paginator-primary-icon
    >
    </mat-paginator>
  </div>
  <div class="empty-search" [hidden]="queueTableData.filteredData.length !== 0">
    <img
      [src]="'resources/img/queue-status-page-icons/BuildingQueueSearch.svg'"
      alt="Loading Search"
    />
    <img
      class="radio-wave-line"
      [src]="'resources/img/queue-status-page-icons/RadioWaveLines.svg'"
      alt="Loading Lines"
    />
    <h4>{{ "ONSIP_I18N.THERE_ARE_NO_MATCHING_RESULTS" | translate }}</h4>
  </div>
  <div class="loading-state" [hidden]="tableLoaded | async">
    <img [src]="'resources/img/queue-status-page-icons/QueueLady.svg'" alt="Queue Avatar" />
    <img
      class="speech-bubble"
      [src]="'resources/img/queue-status-page-icons/SmallSpeechBubble.svg'"
      alt="Speech Bubble"
    />
    <h4>{{ "ONSIP_I18N.QUEUES_ARE_LOADINGPLEASE_WAIT" | translate }}</h4>
  </div>
</div>
}
