import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "onsip-voicemail-upload-form",
  templateUrl: "./voicemail-upload-form.component.html",
  styleUrls: ["./voicemail-upload-form.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoicemailUploadFormComponent {
  @Input() greetingFile = "";
  @Output() fileUpload: EventEmitter<File> = new EventEmitter();

  onFileChange(fileInput: HTMLInputElement) {
    const uploadedFile = (fileInput as HTMLInputElement & { files: FileList }).files[0];
    this.greetingFile = uploadedFile.name;
    this.fileUpload.emit(uploadedFile);
  }
}
