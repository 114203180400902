import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "../../modules/material/material.module";
import { PipesModule } from "../shared/pipes/pipes.module";
import { ModalModule } from "../shared/components/modal/modal.module";
import { AvatarModule } from "../shared/components/avatar/avatar.module";
import { NewCallModule } from "../shared/components/newCall/new-call.module";

import { QueueRealtimeComponent } from "./realtime/queue-realtime.component";
import { QueueHistoricalComponent } from "./historical/queue-historical.component";
import { RealtimeAgentTableComponent } from "./realtime/realtime-agent-table/realtime-agent-table.component";
import { RealtimeCallerTableComponent } from "./realtime/realtime-caller-table/realtime-caller-table.component";
import { QueueUpsellComponent } from "./queueUpsell/queue-upsell.component";

import { QueueWarningsComponent } from "./warnings/queue-warnings.component";
import { QueueWarningsModalComponent } from "./warnings/settings/queue-warnings-modal.component";
import { QueueWarningsSettingsComponent } from "./warnings/settings/queue-warnings-settings.component";
import { ValidateTimeShiftDirective } from "./warnings/validate-time-shift.directive";
import { TableModule } from "../shared/components/table/onsip-table.module";
import { TranslateModule } from "@ngx-translate/core";
import { CarouselModule } from "../shared/components/carousel/carousel.module";

import { PortalModule } from "@angular/cdk/portal";
import { TimerModule } from "../shared/components/timer/timer.module";

@NgModule({
  imports: [
    PortalModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    ModalModule,
    AvatarModule,
    TranslateModule,
    TableModule,
    CarouselModule,
    NewCallModule,
    TimerModule
  ],
  declarations: [
    QueueHistoricalComponent,
    QueueRealtimeComponent,
    QueueWarningsSettingsComponent,
    QueueWarningsModalComponent,
    QueueWarningsComponent,
    RealtimeAgentTableComponent,
    RealtimeCallerTableComponent,
    ValidateTimeShiftDirective,
    QueueUpsellComponent
  ],
  exports: [
    QueueHistoricalComponent,
    QueueRealtimeComponent,
    QueueWarningsSettingsComponent,
    RealtimeAgentTableComponent,
    RealtimeCallerTableComponent,
    QueueUpsellComponent
  ]
})
export class QueueModule {}
