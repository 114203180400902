import { Component } from "@angular/core";

import { LogService } from "../../../../../common/services/logging";

import { MatDialog } from "@angular/material/dialog";
import { QueueWarningsModalComponent } from "./queue-warnings-modal.component";

@Component({
  selector: "onsip-queue-warnings-settings",
  templateUrl: "./queue-warnings-settings.component.html"
})
export class QueueWarningsSettingsComponent {
  constructor(private log: LogService, private dialog: MatDialog) {}

  openQueueWarningModal(): void {
    try {
      this.dialog.open(QueueWarningsModalComponent, {
        panelClass: ["mat-typography", "onsip-dialog-universal-style"]
      });
    } catch (err: any) {
      this.log.warn(err);
    }
  }
}
