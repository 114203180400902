import { Config } from "../../config";
// @ts-ignore: this dep is untyped, so ts yells at us because it can't find any declarations
import { fetch as polyfillFetch } from "whatwg-fetch";
declare const polyfillFetch: any; // this dep is untyped- tell typescript it's an any

import * as traverse from "traverse";
/** ts doesn't correctly pull the traverse namespace - so we trivially assert it */
function typeHackAssertTraverse(esm: any): asserts esm is <T>(obj: T) => traverse.Traverse<T> {}
typeHackAssertTraverse(traverse);

import {
  OnsipApiResponseBody,
  OnsipApiResponse,
  OnsipApiBrowseResponse
} from "./apiResponse/response-body-new";
import { InvalidOnsipAPIRequest, OnsipAPIError, OnsipAPIParameter } from "./apiResponse/context";
import { ApiFailure } from "./api-failure";

import { ApiBrowseAction, ApiActionType } from "./api-actions";
import { ObservedApiParameters } from "./util/api-action-description";
import { BehaviorSubject } from "rxjs";
//
// DEPRECATED - PRIVATE - DO NOT USE
// This is ONLY to be used by the ApiService.
// This is TypeScript a port of the onsip-api-action NPM module (which we would like to leave behind to die).
//

// TODO What's the best value for MAX_PIECE_WIDTH here? Empirically, from the tests, 1000 seems to be relatively fast...
const MAX_PIECE_WIDTH = 25000; // 25000 is the server-side max Limit, according to Nader

// error message if there are any 500 errors
export const INTERNAL_SERVER_ERROR = "Internal Server Error";

// subject used to store any 500 errors
export const serverErrorSubject = new BehaviorSubject("");

export interface IApiError {
  action: string;
  message: string;
  code: string;
  parameter: string | undefined;
  Response: OnsipApiResponse;
}
/**
 * Error thrown when the API request completed with one or more errors.
 */
export class ApiError extends Error implements IApiError {
  /**
   * Constructor
   * @param message Error message.
   * @param code The error code associated with the first api error.
   * @param parameter The request parameter associated with the first api error (if it exists).
   * @param Response The API "Response".
   */
  constructor(
    public action: string,
    public message: string,
    public code: string,
    public parameter: string | undefined,
    public Response: OnsipApiResponse
  ) {
    super(`${action} - ${message}`);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

/**
 * implemented as an "opaque" type
 * see: https://codemix.com/opaque-types-in-javascript (skip to the 2nd half)
 *
 */
type CleanObject<T> = T & { __TYPE__: "CleanObject" };

/**
 * Makes an API request (except "Browse" actions without a "Limit" query parameter).
 * If the API reqeust is successful (a 200 Ok is received from api server) and
 * the API request completed with no errors, returns a promise which resolves
 * with a JSON object embodying the API "Response". Otherwise, it rejects.
 *
 * FIXME: TODO: This function does not handle API Exception
 *  - http://api.onsip.com/webservices/schema/rng/Exception.rng
 *  - https://developer.onsip.com/docs/admin-api
 *
 *
 * @param actionName API action.
 * @param queryParameters API action parameters.
 * @reject {ApiError} The API request completed with one or more errors.
 * @reject {ApiFailure} The API server returned an non-200 response.
 * @reject {TypeError} Network error is encounted (not able to complete request with server).
 */
export function genericApiAction<
  T,
  A extends ApiActionType,
  S extends string,
  P extends string = ""
>(queryParameters: ObservedApiParameters): Promise<OnsipApiResponse<T, A, S, P>> {
  const cleanParameters = cleanObject<ObservedApiParameters>({
    ...queryParameters,
    Output: "json",
    AppUserAgent: "OnSIP_App/" + Config.VERSION_NUMBER + "/" + getPlatform() // same as useragentstring in ua config
  });

  if (cleanParameters.Action.search(/Browse$/) >= 0 && !cleanParameters.Limit) {
    const errorMessage =
      "Error running " +
      cleanParameters.Action +
      ":\n" +
      "Don't use action() for *Browse calls, use browse() instead.\n" +
      "If you *must* use action(), pass a Limit parameter.\n";

    return Promise.reject(new Error(errorMessage));
  }

  const xhrOptions = {
    method: "POST",
    body: querystringify(cleanParameters),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };
  return (fetch || polyfillFetch)(Config.ADMIN_API_URL, xhrOptions)
    .then(wrapAPIException)
    .then(tryJSONparse)
    .then(fixEmptyStrings)
    .then(detectAPIErrors)
    .then(typeUnsafePluck("Response")) as Promise<OnsipApiResponse<T, A, S, P>>;
}

/**
 * onsipApiBrowse aims to make an api browse using a genericApiActions,
 * then returns the lowest "level" of data in the response as an array
 * @param actionName API action.
 * @param queryParameters API action parameters.
 */
export function onsipApiBrowse<T, A extends ApiBrowseAction, S extends string, P extends string>(
  queryParameters: ObservedApiParameters
): Promise<Array<OnsipApiBrowseResponse<T, A, S, P>>> {
  const cleanQueryParameters = cleanObject(queryParameters);

  if (!("Limit" in cleanQueryParameters)) {
    // If there's no Limit parameter supplied, do an onsipApiCount and find it
    return onsipApiCount(cleanQueryParameters).then(Limit =>
      onsipApiBrowse({ ...cleanQueryParameters, Limit })
    );
  } else if ((cleanQueryParameters.Limit as number) > MAX_PIECE_WIDTH) {
    // if the supplied Limit parameter is too big, we need to do the browse in several calls
    // break the given params into an array of params describing several calls that span the entire length of the given Limit (which is over the max)
    const parametersList: Array<ObservedApiParameters> = [];
    let offset = (cleanQueryParameters.Offset as number) || 0;
    let remaining = cleanQueryParameters.Limit as number;
    while (remaining > 0) {
      const limit = Math.min(MAX_PIECE_WIDTH, remaining);
      parametersList.push({
        ...cleanQueryParameters,
        Offset: offset,
        Limit: limit,
        CalcFound: false
      });
      offset += limit;
      remaining -= limit;
    }
    const browsePiecePromises = parametersList.map(genericApiAction);
    // execute the several api calls in parallel via Promise.all, then stitch the results together
    return Promise.all(browsePiecePromises as Array<Promise<OnsipApiBrowseResponse<T, A, S, P>>>);
  } else {
    // this is the actual browse
    return Promise.all([
      genericApiAction<T, A, S, P>(queryParameters) as Promise<OnsipApiBrowseResponse<T, A, S, P>>
    ]);
  }
}

/**
 * Does a browse action with the supplied actionName with an additional parameter that returns the number of entries in the database.
 * This is used as a helper to find the limit for browses where the limit is not originally supplied
 */
function onsipApiCount(queryParameters: ObservedApiParameters): Promise<number> {
  const countParameters = {
    ...queryParameters, // this first so subsequent params are overridden if they exist in queryParameters
    Offset: 0,
    Limit: 1,
    CalcFound: true
  };
  const actionName = queryParameters.Action as ApiBrowseAction;
  return genericApiAction<any, typeof actionName, "", "">(countParameters).then(response => {
    typeHackAssertTraverse(traverse);
    const found = traverse(response).get(
      traverse(response)
        .paths()
        // we guarantee "@attributes" is going to be found somewhere, so assert away undefined
        .find((path: Array<string>) => path[path.length - 1] === "@attributes") as Array<string>
    ).Found;
    return Number(found);
  });
}

/*----------****** Internal helper functions ******/

/** The JSON API represents "" as {}, so fix it. */
// https://jnctn.lighthouseapp.com/projects/119540/tickets/568-jiffyphone-historical-events-csv-sometimes-shows-object-object
function fixEmptyStrings<T>(obj: T): T {
  typeHackAssertTraverse(traverse);
  traverse(obj).forEach(function (value: any) {
    if (isEmpty(value)) {
      // @ts-ignore: noImplicitThis
      this.update("");
    }
  });
  return obj;
}

function asArray<T>(maybeListOrItem: T | Array<T>): Array<T> {
  return ([] as Array<T>).concat(maybeListOrItem || []);
}

// http://developer.onsip.com/admin-api/#xml-response-format
function detectAPIErrors(body: OnsipApiResponseBody): OnsipApiResponseBody {
  const unknownApiError = (apiBody: OnsipApiResponseBody) => {
    const action = getApiActionName(apiBody);
    const name = action ? action : "UNDEFINED";

    return { ...new Error(`Action: ${name} has no errors`), action };
  };
  if (body.Response.Context.Request.IsValid !== "true") {
    // Not all api calls return an error even if IsValid !== true
    if (!body.Response.Context.Request.Errors) {
      throw unknownApiError(body);
    }
    throw wrapAPIError(body, body.Response.Context.Request.Errors.Error);
  }

  if (body.Response.Context.Action.IsCompleted !== "true") {
    // THIS IS AN EDGE CASE: we can have valid request with errors.
    // my assumption is we have valid parameters and the api uses these parameters to search for new internal parameters
    // that may be faulty or do not exist.
    if ((body.Response.Context.Request as unknown as InvalidOnsipAPIRequest).Errors) {
      throw wrapAPIError(
        body,
        (body.Response.Context.Request as unknown as InvalidOnsipAPIRequest).Errors.Error
      );
    }

    if (!body.Response.Context.Action.Errors) {
      throw unknownApiError(body);
    }

    throw wrapAPIError(body, body.Response.Context.Action.Errors.Error);
  }
  return body;
}

// https://github.com/ianstormtaylor/is-empty
function isEmpty(val: any): boolean {
  const has = Object.prototype.hasOwnProperty;
  const toString = Object.prototype.toString;

  // Null and Undefined...
  // eslint-disable-next-line no-null/no-null
  if (val === null) {
    return true;
  }

  // Booleans...
  if ("boolean" === typeof val) {
    return false;
  }

  // Numbers...
  if ("number" === typeof val) {
    return val === 0;
  }

  // Strings...
  if ("string" === typeof val) {
    return val.length === 0;
  }

  // Functions...
  if ("function" === typeof val) {
    return val.length === 0;
  }

  // Arrays...
  if (Array.isArray(val)) {
    return val.length === 0;
  }

  // Errors...
  if (val instanceof Error) {
    return val.message === "";
  }

  // Objects...
  if (val.toString === toString) {
    switch (val.toString()) {
      // Maps, Sets, Files and Errors...
      case "[object File]":
      case "[object Map]":
      case "[object Set]": {
        return val.size === 0;
      }

      // Plain objects...
      case "[object Object]": {
        for (const key of Object.keys(val as object)) {
          if (has.call(val, key)) {
            return false;
          }
        }

        return true;
      }
    }
  }

  // Anything else...
  return false;
}

// https://github.com/stephenplusplus/propprop
function typeUnsafePluck<T extends {}>(propertyName: keyof T): (item: T) => T[typeof propertyName] {
  // @ts-ignore: NB: THIS IS AN EXTREMELY UNSAFE TYPE ASSERTION TO REMOVE undefined FROM THIS TYPE
  return (item: T): T[typeof propertyName] => {
    if (propertyName in item) {
      return item[propertyName];
    }
  };
}

function querystringify(obj: any): string {
  // eslint-disable-next-line no-null/no-null
  const mangledObj = Object.create(null);

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (!Array.isArray(value)) {
      mangledObj[key] = value;
    } else {
      value.forEach((val, i) => {
        const mangledKey = key + "[" + i + "]";
        const mangledValue = val;
        mangledObj[mangledKey] = mangledValue;
      });
    }
  });

  return querystringEncode(mangledObj);
}

// https://github.com/Gozala/querystring
function stringifyPrimitive(v: string | boolean | number): string {
  switch (typeof v) {
    case "string":
      return v;

    case "boolean":
      return v ? "true" : "false";

    case "number":
      return isFinite(v) ? v.toString() : "";

    default:
      return "";
  }
}

function querystringEncode(obj: any, separator?: string, equals?: string, name?: string): string {
  separator = separator || "&";
  equals = equals || "=";
  // eslint-disable-next-line no-null/no-null
  if (obj === null) {
    obj = undefined;
  }

  if (typeof obj === "object") {
    return Object.keys(obj)
      .map(key => {
        const keyString = encodeURIComponent(stringifyPrimitive(key)) + equals;
        if (Array.isArray(obj[key])) {
          return obj[key]
            .map((v: string) => keyString + encodeURIComponent(stringifyPrimitive(v)))
            .join(separator);
        } else {
          return keyString + encodeURIComponent(stringifyPrimitive(obj[key]));
        }
      })
      .join(separator);
  } else if (!name) {
    return "";
  } else {
    return (
      encodeURIComponent(stringifyPrimitive(name)) +
      equals +
      encodeURIComponent(stringifyPrimitive(obj))
    );
  }
}

function tryJSONparse(response: Response): Promise<OnsipApiResponseBody> {
  return response.json();
}

export function getApiActionName(
  bodyOrResponse: OnsipApiResponse | OnsipApiResponseBody
): ApiActionType | undefined {
  let parameters: Array<OnsipAPIParameter> = [];
  const isBody = (_bodyOrResponse: any): _bodyOrResponse is OnsipApiResponseBody =>
    !!_bodyOrResponse.Response;
  if (isBody(bodyOrResponse)) {
    return getApiActionName(bodyOrResponse.Response);
  }
  if (bodyOrResponse.Context.Request.Parameters) {
    parameters = asArray(bodyOrResponse.Context.Request.Parameters.Parameter);
  }
  const action = parameters.find(param => param.Name === "Action");
  // Parameter only exists on Request errors. Compare the following:
  // http://api.onsip.com/webservices/schema/rng/Request.rng
  // http://api.onsip.com/webservices/schema/rng/Action.rng
  return action?.Value as ApiActionType;
}

function wrapAPIError(
  body: OnsipApiResponseBody,
  apiErrors: OnsipAPIError | Array<OnsipAPIError>
): ApiError {
  apiErrors = asArray(apiErrors);
  const apiError = apiErrors[0];
  const action = getApiActionName(body);
  return new ApiError(
    action ? action : "ACTION NAME UNDEFINED",
    apiError.Message,
    apiError.Code,
    apiError.Parameter,
    body.Response
  );
}

async function wrapAPIException(response: Response): Promise<Response> {
  if (response.ok) {
    return response;
  } else if (response.status === 500) {
    serverErrorSubject.next(INTERNAL_SERVER_ERROR);
    throw new ApiFailure(INTERNAL_SERVER_ERROR);
  } else {
    return response.text().then((text: string) => {
      throw new ApiFailure(text);
    });
  }
}

// previously, we used JSON.parse(JSON.stringify(x)) to clean up objects. This got rid of all
// undefineds and empty functions. Here we more deliberately mimic that behavior
export function cleanObject<T extends Record<string, any>>(val: T): CleanObject<T> {
  Object.keys(val).forEach((key: keyof T) => {
    const value = (val as any)[key];
    if (value === undefined || ("function" === typeof value && value.length === 0)) delete val[key];
    else if (!isEmpty(value) && typeof value === "object") cleanObject<typeof value>(val[key]);
  });
  return val as CleanObject<T>;
}

function getPlatform(): string {
  if (Config.IS_WEB) {
    return "web";
  }

  if (Config.IS_DESKTOP && Config.IS_WIN) {
    return "windows";
  }

  if (Config.IS_DESKTOP && Config.IS_MAC) {
    return "mac";
  }

  if (Config.IS_DESKTOP && Config.IS_LINUX) {
    return "linux";
  }

  if (Config.IS_ANDROID) {
    return "android";
  }

  if (Config.IS_IOS) {
    return "ios";
  }

  return "impossible";
}
