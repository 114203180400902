import { Directive, HostListener } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { VoicemailSettingsModalComponent } from "../../voicemail/settingsModal/voicemail-settings-modal.component";

@Directive({
  selector: "[onsipVoicemailSettingsModalOpen]"
})
export class VoicemailSettingsModalOpenDirective {
  constructor(private dialog: MatDialog) {}

  @HostListener("click")
  onClick() {
    this.dialog.open(VoicemailSettingsModalComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      autoFocus: false
    });
  }
}
