import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { withLatestFrom, filter, map } from "rxjs/operators";
import {
  ApiAddAction,
  ApiBrowseAction,
  ApiDeleteAction,
  ApiEditAction,
  ApiGenericAction,
  ApiReadAction
} from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { userId } from "../../apiParams/user-id";
import {
  APIE911Location,
  E911Location,
  E911LocationType,
  apiE911LocationToE911Location as clean,
  E911LocationParams,
  EditE911LocationParams
} from "./e911-location";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { E911Location };

const debug = false;

@Injectable({ providedIn: "root" })
export class E911Service extends ApiResourceService<E911Location> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "E911Location", "e911LocationId");
    debug && this.state.subscribe(state => console.warn("E911LocationService", state));
  }

  get selfE911Locations(): Observable<Array<E911Location>> {
    return this.state.pipe(
      filter(state => !state.loading),
      withLatestFrom(this.store.state.pipe(userId())),
      map(([e911, userId]) => {
        const allE911Locations = Object.values(e911.state);
        return allE911Locations
          .filter(e911Loc => e911Loc.locationType === "Shared")
          .concat(allE911Locations.filter(e911Loc => e911Loc.userId === userId));
      })
    );
  }

  /** e911LocationBrowse to get all locations in org. Can only be used by admins */
  e911LocationBrowse(params?: {
    Limit?: number;
    LocationType?: E911LocationType;
  }): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.E911LocationBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: params?.Limit || 250,
        LocationType: params?.LocationType || undefined
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.E911LocationBrowse);
  }

  /** e911LocationBrowse for any user. Only gets shared and private locations for given user */
  e911LocationBrowseWithUserId(params?: {
    Limit?: number;
    LocationType?: E911LocationType;
    UserId?: string;
  }): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.E911LocationBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        Limit: params?.Limit || 250,
        LocationType: params?.LocationType || undefined
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.E911LocationBrowse);
  }

  e911LocationRead(E911LocationId: string): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.E911LocationRead,
        SessionId: this.store.state.pipe(sessionId()),
        E911LocationId
      }
    });
    return this.promiseState.toPromise(ApiReadAction.E911LocationRead);
  }

  e911LocationEdit(params: EditE911LocationParams): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.E911LocationEdit,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiEditAction.E911LocationEdit);
  }

  e911LocationAdd(params: E911LocationParams): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.E911LocationAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAddAction.E911LocationAdd);
  }

  /** This is a E911 location add specifically for non-admin user to add their own private location
   * The API has to take in a user Id and org Id for this to work
   */
  e911LocationAddWithoutOrgId(
    OrganizationId: string,
    params: E911LocationParams
  ): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.E911LocationAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAddAction.E911LocationAdd);
  }

  e911LocationDelete(E911LocationId: string): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.E911LocationDelete,
        SessionId: this.store.state.pipe(sessionId()),
        E911LocationId
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.E911LocationDelete);
  }

  e911LocationAssign(params: {
    Address: string;
    E911LocationId: string;
  }): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.E911LocationAssign,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiGenericAction.E911LocationAssign);
  }

  e911LocationDeassign(Address: string): ApiPromiseState<E911Location> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.E911LocationDeassign,
        SessionId: this.store.state.pipe(sessionId()),
        Address
      }
    });
    return this.promiseState.toPromise(ApiGenericAction.E911LocationDeassign);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.E911LocationBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<APIE911Location>>(
              response,
              action,
              "E911Location",
              "E911Locations"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiReadAction.E911LocationRead:
      case ApiEditAction.E911LocationEdit:
      case ApiAddAction.E911LocationAdd:
      case ApiAddAction.E911LocationAddWithoutOrgId:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<APIE911Location>(response, action, "E911Location")].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiDeleteAction.E911LocationDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedLocation = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "E911LocationId")?.Value;
        deletedLocation &&
          this.store.mergeStateUpdate(this.resourceName, { [deletedLocation]: undefined }, action);
        break;
      case ApiGenericAction.E911LocationAssign:
      case ApiGenericAction.E911LocationDeassign:
        if (response.Context.Request.Parameters) {
          const assignedAddress = onsipApiArrayToArray(
            response.Context.Request.Parameters,
            "Parameter"
          ).find(param => param.Name === "Address")?.Value;
          assignedAddress && this.store.mergeStateUpdate(this.resourceName, {}, action);
        }
        break;
    }
  }
}
