import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { FormControl, FormGroup, AbstractControl, ValidatorFn } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

import { Observable, BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { pluck, map, filter } from "rxjs/operators";

import { UserCustomizationService } from "../../../../common/services/api/resources/userCustomization/user-customization.service";

import { AvatarUploadModalComponent } from "../../shared/components/imageUploader/uploadModal/avatar-upload-modal.component";

import { UserInfo } from "../../../../common/services/api/apiResponse/user-info";
import { IdentityService } from "../../../../common/modules/core";
import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { UserService } from "../../../../common/services/api/resources/user/user.service";

interface ProfileItem {
  value: string;
  field: keyof UserInfo["personalPageInfo"];
  isSocialMedia: boolean;
  urlPrefix?: string;
  regex?: RegExp;
}

interface ProfileGroup extends FormGroup {
  controls: { [key: string]: FormControl };
} // typing hack

@Component({
  selector: "onsip-profile",
  templateUrl: "profile.component.html",
  styleUrls: ["./profile.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnDestroy {
  blurbEditing = new BehaviorSubject<boolean>(false);
  titleEditing = new BehaviorSubject<boolean>(false);
  mediaEditing = new BehaviorSubject<boolean>(false);
  myAvatar!: Observable<string>;

  items: Array<ProfileItem> = [
    { value: "", field: "title", isSocialMedia: false },
    { value: "", field: "blurb", isSocialMedia: false },
    {
      value: "",
      field: "linkedin",
      urlPrefix: "https://www.linkedin.com/in/",
      regex: /linkedin/,
      isSocialMedia: true
    },
    {
      value: "",
      field: "facebook",
      urlPrefix: "https://www.facebook.com/",
      regex: /facebook/,
      isSocialMedia: true
    },
    {
      value: "",
      field: "twitter",
      urlPrefix: "https://www.twitter.com/",
      regex: /twitter/,
      isSocialMedia: true
    }
  ];

  socialMediaFormGroup = new FormGroup({
    linkedin: new FormControl("", [this.checkSocialMediaLinks("linkedin")]),
    facebook: new FormControl("", [this.checkSocialMediaLinks("facebook")]),
    twitter: new FormControl("", [this.checkSocialMediaLinks("twitter")])
  }) as ProfileGroup;

  get title() {
    return this.items[0];
  }
  get blurb() {
    return this.items[1];
  }

  email!: Observable<string>;
  hideOtherMediaForms = new BehaviorSubject<boolean>(false);
  link = "";
  maxBlurbLength = 500;
  name!: Observable<string>;
  publishEmail = false;

  private exposePii!: Observable<boolean>;
  private unsubscriber = new Subscription();

  constructor(
    private userCustomizationService: UserCustomizationService,
    private identity: IdentityService,
    private noAuthOrgSummary: NoAuthOrganizationSummaryService,
    private dialog: MatDialog,
    private userService: UserService,
    private cdrRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.email = this.userService.selfUser.pipe(pluck("contact", "email"));
    this.name = this.userService.selfUser.pipe(pluck("contact", "name"));
    this.exposePii = this.userService.selfUser.pipe(pluck("exposePii"));

    this.unsubscriber.add(
      this.exposePii.subscribe(res => {
        this.publishEmail = res;
      })
    );

    this.unsubscriber.add(
      combineLatest({
        teamPageDomain: this.noAuthOrgSummary.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0]?.teamPageDomain)
        ),
        idState: this.identity.state.pipe(filter(state => state.addresses.length > 0))
      }).subscribe(({ teamPageDomain, idState }) => {
        this.link = `${teamPageDomain}/${idState.addresses[0].username}`;
        this.cdrRef.markForCheck();
      })
    );

    this.unsubscriber.add(
      this.userCustomizationService.selfUser.subscribe(userCustomization => {
        const info = userCustomization.userInfo?.personalPageInfo;
        if (info) {
          this.items.forEach(item => {
            item.value = info[item.field];
            if (
              item.field === "linkedin" ||
              item.field === "facebook" ||
              item.field === "twitter"
            ) {
              this.socialMediaFormGroup.controls[item.field].setValue(item.value);
            }
          });
        }
      })
    );

    this.myAvatar = this.userCustomizationService.selfUser.pipe(
      map(userCustomization => userCustomization.userAvatarUrl)
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  openUploadAvatarModal(): void {
    this.dialog.open(AvatarUploadModalComponent, {
      panelClass: ["onsip-dialog-universal-style"]
    });
  }

  toggleMediaEdit() {
    if (this.mediaEditing.value) {
      this.socialMediaFormGroup.updateValueAndValidity();
      if (this.socialMediaFormGroup.valid) this.submit();
      this.mediaEditing.next(false);
    } else {
      this.mediaEditing.next(true);
    }
  }

  toggleTitleInfoEdit(): void {
    if (this.titleEditing.value) {
      this.submit();
      this.titleEditing.next(false);
    } else {
      this.titleEditing.next(true);
    }
  }

  toggleBlurbInfoEdit(): void {
    if (this.blurbEditing.value) {
      this.submit();
      this.blurbEditing.next(false);
    } else {
      this.blurbEditing.next(true);
    }
  }

  onCheckboxChange(event: MatSlideToggleChange): void {
    this.userService.userEditExposePii({ Enabled: event.checked }).then(state => {
      if (state.status === "error") {
        const err = state.data.message;
        console.error("Profile Component Api Service Error: ", err);
      }
    });
  }

  private checkSocialMediaLinks(itemName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = new RegExp(itemName.toLowerCase());
      const error: boolean = /http|www|\.com/.test(control.value) || regex.test(control.value);
      // eslint-disable-next-line no-null/no-null
      return error ? { badLink: { value: control.value } } : null;
    };
  }

  private submit(): void {
    const params: Record<string, string> = {};
    this.items.forEach(item => {
      if (
        this.socialMediaFormGroup.controls[item.field] &&
        this.socialMediaFormGroup.controls[item.field].valid
      ) {
        item.value = this.socialMediaFormGroup.controls[item.field].value;
      }
      params[item.field] = item.value;
    });
    this.userCustomizationService.userCustomizationPersonalPageInfoEdit(params);
  }
}
