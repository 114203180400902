import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { PhoneComponent } from "./phone.component";
import { HomeMainComponent } from "./home/home-main.component";
import { QueueRealtimeComponent } from "../queue/realtime/queue-realtime.component";
import { ConferencePageComponent } from "../videoConference/page/conference-page.component";
import { CallPageComponent } from "../call/page/call-page.component";
import { ContactPageComponent } from "../contact/contact-page.component";
import { UserSettingsComponent } from "../userSettings/user-settings.component";
import { ContactOnSIPComponent } from "../contactOnsip/contact-onsip.component";
import { DeveloperComponent } from "../developer/developer.component";
import { DeveloperGridComponent } from "../developer/grid/developer-grid.component";
import { QueueUpsellComponent } from "../queue/queueUpsell/queue-upsell.component";

import { AdminGuard } from "../shared/guards/adminGuard.guard";
import { PhoneGuard } from "../shared/guards/phoneGuard.guard";
import { QueueGuard } from "../shared/guards/queueGuard.guard";
import { CallResolver } from "../shared/resolvers/call-resolver.resolver";
import { TestShellNavComponent } from "@onsip/web/features/developer/developer-shell-navigation/test-shell-nav/test-shell-nav.component";
import { AdminSupportComponent } from "../adminSupport/admin-support.component";
import { AgentAdminGuard } from "../shared/guards/agentAdminGuard.guard";
import { AgentAdminViewComponent } from "../agentAdmin/agent-admin-view.component";
import { SuperUserViewComponent } from "../superUser/super-user-view.component";
import { SuperUserGuard } from "../shared/guards/superUserGuard.guard";
import { AccountAdminGuard } from "../shared/guards/accountAdminGuard.guard";
import { AgentSpoofingGuard } from "../shared/guards/agentSpoofingGuard.guard";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        canActivate: [PhoneGuard],
        component: PhoneComponent,
        children: [
          {
            path: "",
            redirectTo: "home",
            pathMatch: "full"
          },
          {
            path: "home",
            canActivate: [AgentSpoofingGuard],
            component: HomeMainComponent,
            data: { title: "My Dashboard" }
          },
          {
            path: "queue-dashboard",
            canActivate: [QueueGuard, AgentSpoofingGuard],
            component: QueueRealtimeComponent,
            data: { title: "Queue Dashboard" }
          },
          {
            path: "video-conference",
            component: ConferencePageComponent,
            data: { title: "Video Conference" }
          },
          {
            path: "app-settings",
            canActivate: [AgentSpoofingGuard],
            component: UserSettingsComponent,
            data: { title: "App Settings" }
          },
          {
            path: "developer",
            component: DeveloperComponent,
            data: { title: "Developer" },
            children: [
              {
                path: "first-test-nav",
                component: TestShellNavComponent,
                data: { title: "First test shell nav page", text: "First test shell nav page" }
              },
              {
                path: "second-test-nav",
                component: TestShellNavComponent,
                data: { title: "Second test shell nav page", text: "Second test shell nav page" }
              },
              {
                path: "third-test-nav",
                component: TestShellNavComponent,
                data: { title: "Third test shell nav page", text: "Third test shell nav page" }
              }
            ]
          },
          {
            path: "grid",
            component: DeveloperGridComponent,
            data: { title: "Grid Demo" }
          },
          {
            path: "contact",
            component: ContactPageComponent,
            data: {}
          },
          {
            path: "call",
            component: CallPageComponent,
            data: {},
            resolve: { callUuid: CallResolver },
            runGuardsAndResolvers: "always"
          },
          {
            path: "contact-sales",
            component: ContactOnSIPComponent,
            data: { title: "Contact Sales", dept: "sales" }
          },
          {
            path: "contact-support",
            canActivate: [AccountAdminGuard],
            runGuardsAndResolvers: "always",
            component: AdminSupportComponent
          },
          {
            path: "call-manager",
            canActivate: [AgentSpoofingGuard],
            component: QueueUpsellComponent,
            data: { title: "Call Manager" }
          },
          {
            path: "sayso",
            canActivate: [AdminGuard, AgentSpoofingGuard],
            loadChildren: () => import("../sayso/sayso.module").then(m => m.SaysoModule)
          },
          {
            path: "account-settings",
            canActivate: [AccountAdminGuard],
            runGuardsAndResolvers: "always",
            loadChildren: () =>
              import("../accountSettings/account-settings.module").then(
                m => m.AccountSettingsModule
              )
          },
          {
            path: "administrators",
            canActivate: [AccountAdminGuard],
            runGuardsAndResolvers: "always",
            loadChildren: () =>
              import("../administrators/administrators.module").then(m => m.AdministratorsModule)
          }
        ]
      },
      {
        path: "agent-view",
        canActivate: [AgentAdminGuard],
        component: AgentAdminViewComponent
        //loadChildren: () => import("../agentAdmin/agent-admin.module").then(m => m.AgentAdminModule)
      },
      {
        path: "super-user-view",
        canActivate: [SuperUserGuard],
        component: SuperUserViewComponent
      }
    ])
  ],
  exports: [RouterModule]
})
export class phoneRoutingModule {}
