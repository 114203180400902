import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { PageEvent } from "@angular/material/paginator";

import { Subscription, BehaviorSubject } from "rxjs";
import { filter, take, delay, pluck } from "rxjs/operators";

import { UserAddress } from "../../../../../common/services/api/resources/userAddress/user-address";

import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { IdentityService } from "../../../../../common/services/identity.service";
import {
  isEndCallEvent,
  isConnectedCallEvent
} from "../../../../../common/libraries/sip/call-event";
import { AnalyticsService } from "../../../shared/components/analytics/analytics.service";
import { EavesdropService } from "../eavesdrop.service";
import { SubscriptionControllerService } from "../../../../../common/services/subscription-controller.service";
import { RealtimeAgentTableService } from "./realtime-agent-table.service";
import { AppCallingService } from "../../../shared/services/appCalling/app-calling.service";
import {
  QueueSnapshot,
  EavesdropType,
  QueueAgent,
  EavesdropInfo
} from "../queue-realtime-types.component";

@Component({
  selector: "onsip-realtime-agent-table",
  templateUrl: "./realtime-agent-table.component.html",
  styleUrls: ["./realtime-agent-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RealtimeAgentTableComponent implements OnInit, OnDestroy {
  @Input() selectedQueue!: QueueSnapshot;

  pageSize = 10;
  currentPage: BehaviorSubject<number> = new BehaviorSubject(0);
  filteredQueueAgents: Array<QueueAgent> = [];
  displayedQueueAgents: Array<QueueAgent> = [];
  eavesdroppedCallUuid: string | undefined;
  eavesdropInfo: EavesdropInfo = {};
  isMobile = false; // less than 600px width
  eavesdropConnectingState = false;
  hasDropdownEavesdrop = false;
  openEavesdropDropdown = false;
  currentAgent: QueueAgent | undefined;

  private canMakeCalls = true;

  private unsubscriber = new Subscription();

  constructor(
    private analyticsService: AnalyticsService,
    private callControllerService: CallControllerService,
    private eavesdropService: EavesdropService,
    private identityService: IdentityService,
    private appCallingService: AppCallingService,
    private subscriptionControllerService: SubscriptionControllerService,
    private realtimeAgentTableService: RealtimeAgentTableService,
    private breakPointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // set eavesdropUuid to existing eavesdrop call if one exists
    this.eavesdroppedCallUuid = this.eavesdropService.getEavesdropUuid();
    this.eavesdropInfo = this.eavesdropService.getEavesdropInfo();

    this.unsubscriber.add(
      this.callControllerService
        .getCallEventObservable()
        .pipe(
          filter(isEndCallEvent),
          filter(event => !!this.eavesdroppedCallUuid && this.eavesdroppedCallUuid === event.uuid)
        )
        .subscribe(() => {
          this.openEavesdropDropdown = false;
          this.eavesdroppedCallUuid = undefined;
          this.currentAgent = undefined;
          this.eavesdropInfo = {};
        })
    );
    this.unsubscriber.add(
      this.callControllerService
        .getCallEventObservable()
        .pipe(
          filter(isConnectedCallEvent),
          filter(event => !!this.eavesdroppedCallUuid && this.eavesdroppedCallUuid === event.uuid),
          // need delay to wait for dtmf
          delay(1500)
        )
        .subscribe(() => {
          this.runDTMFAfterCall();
          this.realtimeAgentTableService.permitEavesdrop();
        })
    );

    this.unsubscriber.add(
      this.appCallingService.state
        .pipe(pluck("enabled"))
        .subscribe(isEnabled => (this.canMakeCalls = isEnabled))
    );

    this.unsubscriber.add(
      this.realtimeAgentTableService.state.subscribe(state => {
        this.eavesdropConnectingState = state.otherActiveEavesdropState;
      })
    );
    this.unsubscriber.add(
      this.subscriptionControllerService.state.subscribe(() => {
        this.filteredQueueAgents = this.selectedQueue.orderedAgents;
        if (this.currentAgent) {
          const eavesdroppedAgent = this.filteredQueueAgents.find(agent =>
            this.currentAgent ? agent.name === this.currentAgent.name : false
          );
          if (eavesdroppedAgent && eavesdroppedAgent.callStatus !== "On Call") {
            this.stopEavesdrop();
          }
        }
        if (this.currentPage.value === 0 && this.filteredQueueAgents) {
          this.displayedQueueAgents = this.filteredQueueAgents.slice(0, this.pageSize);
        }
        this.currentPage.next(this.currentPage.value);
        this.cdRef.markForCheck();
      })
    );
    this.unsubscriber.add(
      this.currentPage.subscribe(current => {
        if (this.filteredQueueAgents) {
          const startIdx = current * this.pageSize;
          const endIdx = startIdx + this.pageSize;
          this.displayedQueueAgents = this.filteredQueueAgents.slice(startIdx, endIdx);
        }
      })
    );
    this.unsubscriber.add(
      this.breakPointObserver
        .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
        .subscribe(result => {
          this.hasDropdownEavesdrop = result.matches;
        })
    );
    this.unsubscriber.add(
      this.breakPointObserver.observe(Breakpoints.XSmall).subscribe(result => {
        this.isMobile = result.matches;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  onPageChanged(e: PageEvent): void {
    this.currentPage.next(e.pageIndex);
  }

  // handles mobile view dropdown click
  onEavesdropDropdownClick(agent: QueueAgent): void {
    if (!this.openEavesdropDropdown && !this.currentAgent) {
      this.openEavesdropDropdown = true;
      this.currentAgent = agent;
    } else if (
      this.openEavesdropDropdown &&
      this.currentAgent &&
      this.currentAgent.uri !== agent.uri
    ) {
      this.openEavesdropDropdown = true;
      this.currentAgent = agent;
    } else {
      this.openEavesdropDropdown = false;
      this.currentAgent = undefined;
    }
  }
  // allow only one open dropdown per queue
  canEavesdropDropdown(agent: QueueAgent): boolean {
    if (this.currentAgent) {
      return this.openEavesdropDropdown && this.currentAgent.uri === agent.uri;
    } else {
      return false;
    }
  }

  handleEavesdropIcons(agent: QueueAgent, type: EavesdropType, text: string): string {
    if (!this.canEavesdrop(agent) || this.eavesdropConnectingState) {
      return `resources/img/queue-status-page-icons/Grey${text}.svg`;
    } else if (this.eavesdropInfo.status === type && this.eavesdropInfo.agentUri === agent.uri) {
      return `resources/img/queue-status-page-icons/${text}Cancel.svg`;
    } else {
      return `resources/img/queue-status-page-icons/Black${text}.svg`;
    }
  }

  canEavesdrop(agent: QueueAgent): boolean {
    return "" === this.eavesdropButtonTitle(agent);
  }

  eavesdropButtonTitle(agent: QueueAgent): string {
    if (this.callControllerService.hasUserAgent(agent.uri)) {
      return "You cannot listen in on your own calls.";
    }

    if (!this.canMakeCalls) {
      return "You must have app calling enabled.";
    }

    if (!this.eavesdroppedCallUuid) {
      return "";
    }
    return "";
  }

  eavesdrop(selectedQueue: QueueSnapshot, agent: QueueAgent, type: EavesdropType): void {
    this.unsubscriber.add(
      this.callControllerService.state
        .pipe(
          filter(state => state.userAgents.length > 0),
          take(1)
        )
        .subscribe(() => {
          const identity: UserAddress | undefined = this.identityService.getDefaultIdentity();

          if (!identity) {
            return;
          }

          const options: { extraHeaders: Array<string> } = {
            extraHeaders: [this.getAppCommandHeader("eavesdrop", [agent.uri])]
          };

          // at most one eavesdrop call, which goes at the start of the list
          // existing eavesdrop call on the same queue is stopped before attempting a new call
          this.stopEavesdrop()
            .then(() => {
              this.eavesdropInfo = {
                agentUri: agent.uri,
                status: type,
                queueName: selectedQueue.name
              };
              this.eavesdropService.setEavesdropInfo(this.eavesdropInfo);
              this.currentAgent = agent;
              this.analyticsService.sendQueueEvent(
                "Eavesdrop",
                agent.uri,
                selectedQueue.uri,
                undefined
              );
            })
            .then(() => {
              this.eavesdropService
                .createEavesdropCall(this.selectedQueue.uri, options)
                .then(uuid => {
                  this.eavesdroppedCallUuid = uuid;
                  this.setDocumentTitle("Eavesdropping on " + agent.name);
                });
            });
        })
    );
  }

  stopEavesdrop(): Promise<void> {
    this.setDocumentTitle("Queue Dashboard");
    if (this.eavesdroppedCallUuid) {
      return this.callControllerService.endCall(this.eavesdroppedCallUuid);
    } else {
      return Promise.resolve();
    }
  }

  runDTMFAfterCall(): void {
    if (this.currentAgent && this.eavesdropInfo.agentUri && this.eavesdropInfo.status) {
      if (this.eavesdropInfo.status === "agent") this.bargeAgent(this.eavesdropInfo.agentUri);
      if (this.eavesdropInfo.status === "both") this.bargeBoth(this.eavesdropInfo.agentUri);
    }
  }

  bargeAgent(agentUri: string): void {
    this.bargeEavesdropped(1, "agent", agentUri);
  }

  bargeCaller(agentUri: string): void {
    this.bargeEavesdropped(2, "caller", agentUri);
  }

  bargeBoth(agentUri: string): void {
    this.bargeEavesdropped(3, "both", agentUri);
  }

  unbarge(agentUri: string): void {
    this.bargeEavesdropped(0, "eavesdrop", agentUri);
  }

  onEavesdropClick(agent: QueueAgent, type: EavesdropType) {
    if (!this.eavesdroppedCallUuid || this.eavesdropInfo.agentUri !== agent.uri) {
      this.realtimeAgentTableService.setActiveEavesdrop();
      this.eavesdrop(this.selectedQueue, agent, type);
    } else if (this.eavesdropInfo.status !== type) {
      if (type === "eavesdrop") this.unbarge(agent.uri);
      if (type === "agent") this.bargeAgent(agent.uri);
      if (type === "both") this.bargeBoth(agent.uri);
    } else {
      this.stopEavesdrop();
      if (this.hasDropdownEavesdrop) this.onEavesdropDropdownClick(agent);
    }
  }

  remoteLogOut(queueURI: string, agentURI: string): void {
    // TODO should we listen for the MESSAGE request sent back to us?
    this.analyticsService.sendQueueEvent("Agent Logout", agentURI, queueURI, undefined);

    this.sendAppCommand(queueURI, "log_out", [agentURI]);
  }

  /* We need the agent display name since the app engine makes the login call
   * from the person doing the remote log in on behalf of the agent. So, we
   * need to be tricky and pretend we are someone else by passing along these
   * values.
   */
  remoteLogIn(queueURI: string, agentURI: string, agentDisplayName: string): void {
    // TODO should we listen for the MESSAGE request sent back to us?
    this.analyticsService.sendQueueEvent("Agent Login", agentURI, queueURI, undefined);

    this.sendAppCommand(queueURI, "log_in", [agentURI, agentDisplayName]);
  }

  private sendAppCommand(appURI: string, cmd: string, params: Array<string>): void {
    this.unsubscriber.add(
      this.identityService.state
        .pipe(
          filter(state => !!state.defaultIdentity),
          take(1)
        )
        .subscribe(() => {
          const identity: UserAddress | undefined = this.identityService.getDefaultIdentity(),
            cmdObj = this.getAppCommandObject(cmd, params),
            toJSON: object = {
              remote_app_command: [cmdObj]
            },
            msg: string = JSON.stringify(toJSON);

          if (!identity) {
            return;
          }

          this.callControllerService.sendMessage(appURI, msg, "application/json");
        })
    );
  }

  private getAppCommandObject(cmd: string, params: Array<string>): Record<string, Array<string>> {
    const cmdObj: Record<string, Array<string>> = {};

    // if (typeof params === "string" || params.length === undefined) {
    //   params = [params];
    // }

    cmdObj[cmd] = params;
    return cmdObj;
  }

  private getAppCommandHeader(cmd: string, params: Array<string>): string {
    let cmdObj: object = this.getAppCommandObject(cmd, params);

    cmdObj = [cmdObj];
    return "X-App-Command: " + JSON.stringify(cmdObj);
  }

  private bargeEavesdropped(
    dtmfVal: number,
    eavesdropStatus: EavesdropType,
    agentUri: string
  ): void {
    const remoteDisplayName = this.eavesdropInfo.queueName;

    if (!this.eavesdroppedCallUuid) {
      throw new Error("Cannot barge into a call that is not yet eavesdropped.");
    }

    this.eavesdropInfo.status = eavesdropStatus;
    this.callControllerService.playDTMF(this.eavesdroppedCallUuid, dtmfVal.toString());

    if (eavesdropStatus !== "eavesdrop") {
      if (eavesdropStatus === "agent") {
        this.analyticsService.sendQueueEvent(
          "Whisper",
          agentUri,
          remoteDisplayName || "",
          undefined
        );
      } else if (eavesdropStatus === "both") {
        this.analyticsService.sendQueueEvent("Barge", agentUri, remoteDisplayName || "", undefined);
      }
    } else {
      this.analyticsService.sendQueueEvent("Listen", agentUri, remoteDisplayName || "", undefined);
    }
  }

  private setDocumentTitle(title: string): void {
    document.title = title.length ? "OnSIP - " + title : "OnSIP";
  }
}
