<!-- VOICEMAIL SETTINGS -->
<form
  class="voicemail-settings-container mat-typography"
  name="home-voicemail-settings"
  [formGroup]="formGroup"
  (submit)="saveBox()"
>
  <div mat-dialog-title class="voicemail-settings-header-container">
    <h2>{{ "ONSIP_I18N.VOICEMAIL_SETTINGS" | translate }}</h2>
  </div>
  <div mat-dialog-content>
    <div class="voicemail-settings voicemail-number">
      <h4>{{ "ONSIP_I18N.NUMBER" | translate }}:</h4>
      <strong>{{ mailboxNumber }}</strong>
    </div>
    <div class="voicemail-settings voicemail-notify-email">
      <h4>{{ "ONSIP_I18N.NOTIFY_EMAIL" | translate }}:</h4>
      <mat-form-field appearance="outline">
        <mat-select formControlName="notifyEmail">
          @for (notifyEmailOption of notifyEmailOptions; track notifyEmailOption) {
          <mat-option [value]="notifyEmailOption.value">
            {{ notifyEmailOption.label }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    @if (formGroup.controls.notifyEmail.value !== 'no') {
    <div class="voicemail-settings voicemail-notification-email">
      <h4>{{ "ONSIP_I18N.NOTIFICATION_EMAIL" | translate }}:</h4>
      <mat-form-field appearance="outline">
        <input matInput type="text" name="email" autocomplete="off" formControlName="email" />
        <mat-error>{{ "ONSIP_I18N.EMAIL_ADDRESS_IS_NOT_VALID" | translate }}</mat-error>
      </mat-form-field>
    </div>
    }
    <div class="voicemail-settings voicemail-password">
      <h4>{{ "ONSIP_I18N.PASSWORD" | translate }}:</h4>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          name="password"
          autocomplete="off"
          formControlName="password"
        />
        <mat-error>{{
          "ONSIP_I18N.PASSWORD_MUST_BE_BETWEEN_1_AND_10_MILLION" | translate
        }}</mat-error>
      </mat-form-field>
    </div>
    <div class="voicemail-settings voicemail-notify-phone">
      <h4>{{ "ONSIP_I18N.NOTIFY_PHONES" | translate }}:</h4>
      <mat-slide-toggle #mwiToggle color="primary" formControlName="mwi">
        {{ (mwiToggle.checked ? "ONSIP_I18N.ON" : "ONSIP_I18N.OFF") | translate }}
      </mat-slide-toggle>
    </div>
    <div class="voicemail-settings voicemail-custom-greeting">
      <h4>{{ "ONSIP_I18N.CUSTOM_GREETING" | translate }}:</h4>
      <mat-slide-toggle
        color="primary"
        [checked]="hasMessages"
        (change)="toggleUnavailableMessage($event.checked)"
      >
        {{ (hasMessages ? "ONSIP_I18N.ON" : "ONSIP_I18N.OFF") | translate }}
      </mat-slide-toggle>
    </div>
    <div class="voicemail-settings voicemail-upload-form">
      @if (hasMessages) {
      <onsip-voicemail-upload-form
        [greetingFile]="formGroup.controls.greetingFile.value"
        (fileUpload)="handleFiles($event)"
      >
      </onsip-voicemail-upload-form>
      }
    </div>
    <div class="voicemail-settings voicemail-announce-duration">
      <h4>{{ "ONSIP_I18N.ANNOUNCE_DURATION" | translate }}:</h4>
      <mat-slide-toggle #announceDurationToggle color="primary" formControlName="announceDuration">
        {{ (announceDurationToggle.checked ? "ONSIP_I18N.ON" : "ONSIP_I18N.OFF") | translate }}
      </mat-slide-toggle>
    </div>
    <div class="voicemail-settings voicemail-date-time">
      <h4>{{ "ONSIP_I18N.ANNOUNCE_DATE_AND_TIME" | translate }}:</h4>
      <mat-slide-toggle
        #announceDateAndTimeToggle
        color="primary"
        formControlName="announceDateAndTime"
      >
        {{ (announceDateAndTimeToggle.checked ? "ONSIP_I18N.ON" : "ONSIP_I18N.OFF") | translate }}
      </mat-slide-toggle>
    </div>
    <div class="voicemail-settings voicemail-caller-id">
      <h4>{{ "ONSIP_I18N.ANNOUNCE_CALLER_ID" | translate }}:</h4>
      <mat-slide-toggle #announceCallerIdToggle color="primary" formControlName="announceCallerId">
        {{ (announceCallerIdToggle.checked ? "ONSIP_I18N.ON" : "ONSIP_I18N.OFF") | translate }}
      </mat-slide-toggle>
    </div>
    <onsip-spacer></onsip-spacer>
  </div>
  <div mat-dialog-actions class="button-container modal-actions">
    <button mat-button color="primary" type="button" (click)="dialogRef.close()">
      {{ "ONSIP_I18N.CANCEL" | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit">
      {{ "ONSIP_I18N.SAVE" | translate }}
    </button>
  </div>
</form>
