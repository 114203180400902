<mat-card>
  <mat-card-content>
    <div class="queue-warning">
      <form
        class="queue-warning__edit-form"
        #warningForm="ngForm"
        (ngSubmit)="checkPostWarning()"
        data-*ngIf="warningSettings.displayMode === 'edit'"
      >
        <div class="queue-warning-row">
          For queue
          <mat-form-field appearance="outline">
            <mat-select name="queue" [(ngModel)]="warningSettings.editObj.queue.id">
              @for (queue of queues; track queue) {
              <mat-option [value]="queue.id">{{ queue.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div
          data-*ngIf="!warningForm.value.queue &amp;&amp; warningForm.submitted"
          class="queue-warning__error"
        >
          Please select a queue
        </div>
        <div class="queue-warning-row">
          <span>
            send alert on
            <mat-form-field appearance="outline">
              <mat-select name="thresholdType" [(ngModel)]="warningSettings.editObj.thresholdType">
                @for (thresholdType of thresholdTypes; track thresholdType) {
                <mat-option [value]="thresholdType">
                  {{ thresholdType | underscoresToSpaces }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span data-*ngIf="warningSettings.editObj.thresholdType">
            when
            {{ thresholdPrefix[warningSettings.editObj.thresholdType] }}
            <mat-form-field style="width: 100px !important" appearance="outline">
              <input
                matInput
                type="number"
                placeholder="0"
                name="thresholdValue"
                class="queue-warning-threshold-value"
                [(ngModel)]="warningSettings.editObj.thresholdValue"
                required
              />
            </mat-form-field>
            {{ thresholdSuffix[warningSettings.editObj.thresholdType] }}
          </span>
        </div>
        <div
          data-*ngIf="warningForm.controls.thresholdValue &amp;&amp; (warningForm.controls.thresholdValue.touched || triedToSubmit) &amp;&amp; warningForm.controls.thresholdValue.invalid"
          class="queue-warning__error"
        >
          Please select a threshold type and enter a value
        </div>
        <div class="queue-warning-row">
          <span> Send via email </span>
          <span>
            to
            <mat-form-field appearance="outline" class="email-field">
              <input
                matInput
                type="email"
                name="alertDestination"
                [(ngModel)]="warningSettings.editObj.alertDestination"
                required
              />
            </mat-form-field>
          </span>
        </div>
        <div
          data-*ngIf="warningForm.controls.alertDestination &amp;&amp; (warningForm.controls.alertDestination.touched || triedToSubmit) &amp;&amp; warningForm.controls.alertDestination.invalid"
          class="queue-warning__error"
        >
          Please enter a valid email
        </div>
        @for ( myBhrShift of warningSettings.editObj.editBhr; track idx; let idx = $index) {
        <div class="queue-warning-row">
          <span class="queue-warning-bhr-shift">
            <mat-checkbox
              class="queue-warning__bhr-day__checkbox"
              color="primary"
              labelPosition="before"
              [ngClass]="{
                'queue-warning__bhr-day--on': myBhrShift.days[0].isInShift,
                'queue-warning__bhr-day--available': myBhrShift.days[0].isAvailable
              }"
              name="{{ 'bhr' + idx + 'monday' }}"
              [(ngModel)]="myBhrShift.days[0].isInShift"
              (ngModelChange)="updateBhr(myBhrShift)"
              [disabled]="!myBhrShift.days[0].isAvailable"
            >
              Mon
            </mat-checkbox>
            <mat-checkbox
              class="queue-warning__bhr-day__checkbox"
              color="primary"
              labelPosition="before"
              [ngClass]="{
                'queue-warning__bhr-day--on': myBhrShift.days[1].isInShift,
                'queue-warning__bhr-day--available': myBhrShift.days[1].isAvailable
              }"
              name="{{ 'bhr' + idx + 'tuesday' }}"
              [(ngModel)]="myBhrShift.days[1].isInShift"
              (ngModelChange)="updateBhr(myBhrShift)"
              [disabled]="!myBhrShift.days[1].isAvailable"
            >
              Tue
            </mat-checkbox>
            <mat-checkbox
              class="queue-warning__bhr-day__checkbox"
              color="primary"
              labelPosition="before"
              [ngClass]="{
                'queue-warning__bhr-day--on': myBhrShift.days[2].isInShift,
                'queue-warning__bhr-day--available': myBhrShift.days[2].isAvailable
              }"
              name="{{ 'bhr' + idx + 'wednesday' }}"
              [(ngModel)]="myBhrShift.days[2].isInShift"
              (ngModelChange)="updateBhr(myBhrShift)"
              [disabled]="!myBhrShift.days[2].isAvailable"
            >
              Wed
            </mat-checkbox>
            <mat-checkbox
              class="queue-warning__bhr-day__checkbox"
              color="primary"
              labelPosition="before"
              [ngClass]="{
                'queue-warning__bhr-day--on': myBhrShift.days[3].isInShift,
                'queue-warning__bhr-day--available': myBhrShift.days[3].isAvailable
              }"
              name="{{ 'bhr' + idx + 'thursday' }}"
              [(ngModel)]="myBhrShift.days[3].isInShift"
              (ngModelChange)="updateBhr(myBhrShift)"
              [disabled]="!myBhrShift.days[3].isAvailable"
            >
              Thr
            </mat-checkbox>
            <mat-checkbox
              class="queue-warning__bhr-day__checkbox"
              color="primary"
              labelPosition="before"
              [ngClass]="{
                'queue-warning__bhr-day--on': myBhrShift.days[4].isInShift,
                'queue-warning__bhr-day--available': myBhrShift.days[4].isAvailable
              }"
              name="{{ 'bhr' + idx + 'friday' }}"
              [(ngModel)]="myBhrShift.days[4].isInShift"
              (ngModelChange)="updateBhr(myBhrShift)"
              [disabled]="!myBhrShift.days[4].isAvailable"
            >
              Fri
            </mat-checkbox>
            <mat-checkbox
              class="queue-warning__bhr-day__checkbox"
              color="primary"
              labelPosition="before"
              [ngClass]="{
                'queue-warning__bhr-day--on': myBhrShift.days[5].isInShift,
                'queue-warning__bhr-day--available': myBhrShift.days[5].isAvailable
              }"
              name="{{ 'bhr' + idx + 'saturday' }}"
              [(ngModel)]="myBhrShift.days[5].isInShift"
              (ngModelChange)="updateBhr(myBhrShift)"
              [disabled]="!myBhrShift.days[5].isAvailable"
            >
              Sat
            </mat-checkbox>
            <mat-checkbox
              class="queue-warning__bhr-day__checkbox"
              color="primary"
              labelPosition="before"
              [ngClass]="{
                'queue-warning__bhr-day--on': myBhrShift.days[6].isInShift,
                'queue-warning__bhr-day--available': myBhrShift.days[6].isAvailable
              }"
              name="{{ 'bhr' + idx + 'sunday' }}"
              [(ngModel)]="myBhrShift.days[6].isInShift"
              (ngModelChange)="updateBhr(myBhrShift)"
              [disabled]="!myBhrShift.days[6].isAvailable"
            >
              Sun
            </mat-checkbox>
          </span>
          <span class="queue-warning-bhr-time">
            <mat-label>for</mat-label>
            <mat-checkbox
              class="queue-warning-24-hours"
              color="primary"
              labelPosition="before"
              name="{{ 'bhr' + idx + 'allDay' }}"
              [(ngModel)]="myBhrShift.allDay"
            >
              24 hours
            </mat-checkbox>
            <mat-label>or</mat-label>
            <span
              class="queue-warning-bhr-time-section"
              [ngClass]="myBhrShift.allDay ? 'queue-warning__section--disabled' : 'nothing'"
            >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="from"
                  type="time"
                  name="{{ 'bhr' + idx + 'startTime' }}"
                  class="queue-warning__input-text"
                  [(ngModel)]="myBhrShift.time[0]"
                  [disabled]="myBhrShift.allDay"
                  onsipValidateTimeShift
                  [timeShiftValidator]="validateTimeShift"
                  [bhrShift]="idx"
                  size="7"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="to"
                  type="time"
                  name="{{ 'bhr' + idx + 'endTime' }}"
                  class="queue-warning__input-text"
                  [(ngModel)]="myBhrShift.time[1]"
                  [disabled]="myBhrShift.allDay"
                  onsipValidateTimeShift
                  [timeShiftValidator]="validateTimeShift"
                  [bhrShift]="idx"
                  size="7"
                />
              </mat-form-field>
            </span>
          </span>
          <span class="queue-warning-remove-shift">
            <button
              class="modal__button modal__button--orange"
              mat-button
              color="warn"
              type="button"
              (click)="removeBhrShift(idx)"
            >
              Remove Shift
            </button>
          </span>
        </div>
        }
        <!-- <queue-bhr-shift
        *ngFor="let myBhrShift of warningSettings.editObj.editBhr; let idx = index; trackBy:trackByIndex;"
        [myBhrShift]="myBhrShift"
        [allBhrShifts]="warningSettings.editObj.editBhr"
        [validator]="validator"
        [(ngModel)]="warningSettings.editObj.editBhr['bhr' + idx]"
        name="{{'bhr' + idx}}"
        [shiftName]="'bhr' + idx"
        ngDefaultControl>
      </queue-bhr-shift>
      -->
        <div class="queue-warning-row">
          <span> all times in </span>
          <!-- TODO this timezone stuff should be in a directive -->
          <mat-form-field>
            <mat-select name="timeZone" [(ngModel)]="warningSettings.editObj.timeZone">
              @for (timeZone of timeZones; track timeZone) {
              <mat-option [value]="timeZone.zoneKey">{{ timeZone.zoneName }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="queue-warning-row queue-warning-set-time-button">
          <!-- Need type="button" to not submit form when clicked -->
          <button mat-button color="primary" type="button" (click)="addBhrShift()">
            Set Times For Other Days
          </button>
        </div>
        <div class="queue-warning-edit-delete-container">
          @if (warningSettings.saved) {
          <button mat-button color="warn" (click)="cancelEdit()">Delete</button>
          }
          <button
            mat-raised-button
            class="queue-warning__warning-setting-save onsip-call-begin-background"
            [disabled]="warningForm.invalid"
          >
            {{ warningSettings.saved ? "Update" : "Save" }}
          </button>
        </div>
      </form>
      @if (warningSettings.displayMode === 'view') {
      <div class="queue-warning__view">
        <div class="queue-warning-row">For queue {{ warningSettings.viewObj.queue.name }}</div>
        <div class="queue-warning-row">
          when the queue has
          <span class="queue-warning-emph">
            {{ warningSettings.viewObj.thresholdType | underscoresToSpaces }}
          </span>
          <span data-*ngIf="warningSettings.viewObj.thresholdValue">
            ({{ warningSettings.viewObj.thresholdValue }})
          </span>
          send email to
          <span class="queue-warning-emph">
            {{ warningSettings.viewObj.alertDestination }}
          </span>
        </div>
        <div class="queue-warning-row">
          Alert during
          <span class="queue-warning-emph">
            {{ condensedBhrString(warningSettings.viewObj.bhr) }}
          </span>
          <span class="queue-warning-emph"> ({{ warningSettings.viewObj.bhr.TimeZone }}) </span>
        </div>
        <button
          mat-button
          color="warn"
          class="queue-warning-button-relative"
          (click)="warningSettings.displayMode = 'edit'"
        >
          Edit
        </button>
      </div>
      }
      <!-- <button mat-raised-button
    color="warn"
    class="queue-warning-delete"
    (click)="deleteWarning()">
    Delete
  </button> -->
    </div>
  </mat-card-content>
</mat-card>
