<input
  #fileInput
  type="file"
  (change)="onFileChange(fileInput)"
  accept="audio/x-wav"
  class="voicemail-upload-input"
  name="UnavailableGreeting"
/>
<div>
  <button
    mat-raised-button
    type="button"
    color="primary"
    id="voicemail-upload-button"
    (click)="fileInput.click()"
  >
    {{ "ONSIP_I18N.CHOOSE_FILE" | translate }}
  </button>
  @if (greetingFile) {
  <span class="voicemail-upload-value">
    {{ greetingFile }}
  </span>
  }
</div>
<div class="voicemail-upload-hints">
  <mat-hint class="voicemail-upload-instructions">
    {{ "ONSIP_I18N.MUST_BE_A_WAV_FILE" | translate }}
  </mat-hint>
  <mat-hint class="voicemail-upload-disclaimer">
    {{ "ONSIP_I18N.WILL_DELETE_EXISTING_CUSTOM_GREETING_IF_TOGGLE_OFF" | translate }}
  </mat-hint>
</div>
