import { DateTime } from "luxon";
import { Component, OnInit, OnDestroy } from "@angular/core";

import { timezoneArray } from "../timeZones";
import {
  QueueWarningsService,
  WarningSharedObject,
  WarningEditObject,
  WarningViewObject,
  WarningObject
} from "../queue-warnings.service";
import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import { Subscription } from "rxjs";
import { AdvQueueWarningService } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning.service";
import { AdvQueueWarning } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning";

@Component({
  selector: "onsip-queue-warnings-modal",
  templateUrl: "./queue-warnings-modal.component.html",
  styleUrls: ["./queue-warnings-modal.scss"]
})
export class QueueWarningsModalComponent implements OnInit, OnDestroy {
  warnings: Array<WarningObject> = [];
  private myTZ = "";
  private myEmail = "";
  private unsubscriber = new Subscription();

  constructor(
    private queueWarningsService: QueueWarningsService,
    private userService: UserService,
    private advQueueWarningService: AdvQueueWarningService
  ) {}

  ngOnInit(): void {
    this.myTZ = this.myDefTZ();
    // TODO Should we make the user select the queue per warning, or should the
    // user select the queue one level above this, and then we configure warnings
    // in a submenu?

    // A list of objects: { queueName : string, queueId : int }
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.advQueueWarningService.advQueueWarningBrowse({ Limit: 25000 }).then(response => {
          if (response.status === "success") {
            this.myEmail = user.contact.email;
            this.warnings = Object.values(response.data).map(apiWarning => {
              const warning: WarningObject = this.localWarningObj(apiWarning, undefined, "view");
              warning.saved = true;
              return warning;
            });
            if (this.warnings.length < 1) {
              this.warnings = [this.localWarningObj(undefined, this.myEmail, undefined)];
            }
          }
        });
      })
    );

    this.queueWarningsService.setOnWarningDeleteCallback(this.deleteWarning.bind(this));
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  deleteWarning(index: number): void {
    this.warnings.splice(index, 1);
  }

  createNewWarning(): void {
    this.warnings.push(this.localWarningObj(undefined, this.myEmail, undefined));
  }

  // TODO this timezone stuff should be in a directive
  myDefTZ(): string {
    const myZoneOffset = new Date().getTimezoneOffset() / 60;
    let zoneString = myZoneOffset < 0 ? "" : "-";
    zoneString +=
      Math.abs(myZoneOffset) >= 10 ? Math.abs(myZoneOffset) : "0" + Math.abs(myZoneOffset);
    zoneString += ":";
    if (myZoneOffset % 0.5 === 0 && myZoneOffset % 1 !== 0) {
      zoneString += "30";
    } else {
      zoneString += "00";
    }

    const sameOffsets = timezoneArray.filter(timeZone => {
      if (DateTime.local().isInDST) {
        return timeZone.dstOffset === zoneString;
      } else {
        return timeZone.zoneOffset === zoneString;
      }
    });

    if (sameOffsets.length === 1) {
      return sameOffsets[0].zoneKey;
    } else if (sameOffsets.length <= 0) {
      return "";
    } else {
      // Multiple zones with same offset. See if there is a preferred one.
      for (const sameOffset of sameOffsets) {
        if (sameOffset.mainZone) {
          return sameOffset.zoneKey;
        }
      }
      // Didn't find a preferred one. Return the first one.
      // Normally, these are sorted alphanumerically.
      return sameOffsets[0].zoneKey;
    }
  }

  copyFrom(
    obj: AdvQueueWarning,
    key: keyof AdvQueueWarning,
    defaultValue: string | undefined,
    transform: ((...args: Array<any>) => any) | undefined
  ): string | number {
    if (!transform) {
      transform = (val: string) => val;
    }
    return obj[key] ? transform(obj[key]) : defaultValue;
  }

  private sharedLocalProps(
    fromApiObj: AdvQueueWarning | undefined,
    alertDest: string | undefined
  ): WarningSharedObject {
    const cpFromApi = (
      key: keyof AdvQueueWarning,
      def: string | undefined,
      tFn: ((...args: Array<any>) => any) | undefined
    ): string | number | JSON => {
      return fromApiObj ? this.copyFrom(fromApiObj, key, def, tFn) : def || "";
    };

    return {
      queue: {
        id: cpFromApi("AdvQueueId", undefined, parseInt) as number,
        name: cpFromApi("AdvQueueName", "", undefined) as string,
        address: cpFromApi("AdvQueueAddress", "", undefined) as string
      },
      thresholdType: cpFromApi("ThresholdType", undefined, undefined) as string,
      thresholdValue: cpFromApi(
        "ThresholdValue",
        undefined,
        (val: string) => JSON.parse(val).Value
      ) as JSON,
      alertType: cpFromApi("AlertType", "email", undefined) as string,
      alertDestination: cpFromApi("AlertDestination", alertDest || "", undefined) as string,
      cooldownMinutes: fromApiObj ? parseInt(fromApiObj.CooldownSeconds) / 60 : 15
    };
  }

  private localWarningObj(
    fromApiObj: AdvQueueWarning | undefined,
    alertDest: string | undefined,
    displayMode: "view" | "edit" | undefined
  ): WarningObject {
    return {
      editObj: this.localWarningEditObj(fromApiObj, alertDest),
      viewObj: this.localWarningViewObj(fromApiObj, alertDest),
      // edit or view
      displayMode: displayMode || "edit",
      warningId: fromApiObj ? fromApiObj.AdvQueueWarningId : undefined,
      saved: false
    };
  }

  private localWarningEditObj(
    fromApiObj: AdvQueueWarning | undefined,
    alertDest: string | undefined
  ): WarningEditObject {
    const editObj = this.sharedLocalProps(fromApiObj, alertDest) as WarningEditObject;
    let apiBhr;

    if (fromApiObj) {
      apiBhr = JSON.parse(fromApiObj.WarningBhr);
    }

    editObj.editBhr = fromApiObj
      ? this.queueWarningsService.bhrToEditBhr(JSON.parse(fromApiObj.WarningBhr))
      : [];
    editObj.timeZone = apiBhr ? apiBhr.TimeZone : this.myTZ;
    return editObj;
  }

  private localWarningViewObj(
    fromApiObj: AdvQueueWarning | undefined,
    alertDest: string | undefined
  ): WarningViewObject {
    const viewObj = this.sharedLocalProps(fromApiObj, alertDest) as WarningViewObject;
    viewObj.bhr = fromApiObj ? JSON.parse(fromApiObj.WarningBhr) : {};
    return viewObj;
  }
}
