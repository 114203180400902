import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Validators, NonNullableFormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import {
  VoicemailService,
  AdvVoicemailbox
} from "../../../../common/services/api/resources/voicemail/voicemail.service";
import { IdentityService } from "../../../../common/services/identity.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Form } from "../../shared/interfaces/form.interface";

interface VoicemailSettingInfo {
  notifyEmail: NotifyEmailOption;
  email: string;
  password: number | undefined;
  mwi: boolean;
  greetingFile: string;
  announceDuration: boolean;
  announceDateAndTime: boolean;
  announceCallerId: boolean;
}

const enum NotifyEmailOption {
  NO = "no",
  YES = "yes",
  EMAIL = "email",
  YES_THEN_DELETE = "yes-then-delete"
}

@Component({
  selector: "onsip-voicemail-settings-modal",
  templateUrl: "./voicemail-settings-modal.component.html",
  styleUrls: ["./voicemail-settings-modal.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoicemailSettingsModalComponent implements OnInit, OnDestroy {
  voicemailbox!: AdvVoicemailbox;
  formGroup!: FormGroup<Form<VoicemailSettingInfo>>;

  defaultUserAgentAor = "";
  mailboxNumber: number | undefined;
  transferAddressUsername!: string | undefined;

  // template stuff
  notifyEmailOptions!: Array<{ value: NotifyEmailOption; label: string }>;
  hasMessages = false;

  /** uploaded greeting file to be saved. storing the file separately since it will be too long/complex for the formGroup */
  uploadedFile: File | undefined;
  /** original greeting file. we can skip the greeting api step if this was not changed */
  originalGreetingFile = "";
  private unsubscriber = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<VoicemailSettingsModalComponent>,
    private voicemailService: VoicemailService,
    private identityService: IdentityService,
    private translate: TranslateService,
    private formBuilder: NonNullableFormBuilder,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.notifyEmailOptions = [
      { label: this.translate.instant("ONSIP_I18N.NO_EMAIL"), value: NotifyEmailOption.NO },
      { label: this.translate.instant("ONSIP_I18N.EMAIL"), value: NotifyEmailOption.EMAIL },
      {
        label: this.translate.instant("ONSIP_I18N.EMAIL_WITH_WAV_ATTACHMENT"),
        value: NotifyEmailOption.YES
      },
      {
        label: this.translate.instant("ONSIP_I18N.EMAIL_WITH_DELETED_WAV_ATTACHMENT"),
        value: NotifyEmailOption.YES_THEN_DELETE
      }
    ];

    const notifyEmailControl = this.formGroup.get("notifyEmail");
    notifyEmailControl &&
      this.unsubscriber.add(
        notifyEmailControl.valueChanges
          .pipe(
            distinctUntilChanged(
              (prev, next) => (prev !== NotifyEmailOption.NO) === (next !== NotifyEmailOption.NO)
            )
          )
          .subscribe(value => {
            if (value !== NotifyEmailOption.NO) {
              this.formGroup.get("email")?.setValidators([Validators.email, Validators.required]);
            } else {
              this.formGroup.get("email")?.setValidators([Validators.email]);
              this.formGroup.get("email")?.setValue("");
            }
          })
      );

    this.unsubscriber.add(
      this.identityService.state.subscribe(state => {
        this.defaultUserAgentAor = state.defaultIdentity ? state.defaultIdentity.aor : "";
      })
    );

    this.unsubscriber.add(
      this.voicemailService.selfUserVoicemailbox.subscribe(voicemailbox => {
        if (!voicemailbox) return;
        this.voicemailbox = voicemailbox;

        this.transferAddressUsername =
          this.voicemailbox.transferAddress && this.voicemailbox.transferAddress.username
            ? this.voicemailbox.transferAddress.username
            : undefined;

        this.hasMessages = !!this.voicemailbox.voicemail?.greetings?.UnavailableGreeting;
        if (
          voicemailbox.voicemail?.greetings &&
          Object.keys(voicemailbox.voicemail?.greetings || {}).length
        ) {
          if (voicemailbox.voicemail?.greetings?.UnavailableGreeting) {
            const unavailableGreeting = voicemailbox.voicemail?.greetings?.UnavailableGreeting.Path;
            this.originalGreetingFile = unavailableGreeting;
            this.formGroup.controls.greetingFile.setValue(unavailableGreeting);
          } else if (voicemailbox.voicemail?.greetings?.NameGreeting) {
            const nameGreeting = voicemailbox.voicemail?.greetings?.NameGreeting.Path;
            this.originalGreetingFile = nameGreeting;
            this.formGroup.controls.greetingFile.setValue(nameGreeting);
          }
        }

        this.formGroup
          .get("notifyEmail")
          ?.setValue(
            this.voicemailbox.email.length > 0
              ? this.voicemailbox.emailOptions.attach === NotifyEmailOption.NO
                ? NotifyEmailOption.EMAIL
                : (this.voicemailbox.emailOptions.attach as NotifyEmailOption)
              : NotifyEmailOption.NO
          );

        this.formGroup.get("mwi")?.setValue(!!this.voicemailbox.mwi);
        this.formGroup.get("announceDuration")?.setValue(this.voicemailbox.sayDuration);
        this.formGroup.get("announceDateAndTime")?.setValue(this.voicemailbox.sayDateTime);
        this.formGroup.get("announceCallerId")?.setValue(this.voicemailbox.sayCallerId);
        this.formGroup.get("email")?.setValue(voicemailbox.email);
        this.formGroup.get("password")?.setValue(voicemailbox.password as unknown as number);
        this.mailboxNumber = parseInt(voicemailbox.mailbox);
      })
    );
  }

  private initForm() {
    this.formGroup = this.formBuilder.group<Form<VoicemailSettingInfo>>({
      notifyEmail: this.formBuilder.control(NotifyEmailOption.NO),
      email: this.formBuilder.control("", { validators: Validators.email }),
      password: this.formBuilder.control(undefined, {
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(10000000),
          Validators.pattern("^([1-9][0-9]{0,6}|10000000)$")
        ]
      }),
      mwi: this.formBuilder.control(false),
      greetingFile: this.formBuilder.control(""),
      announceDuration: this.formBuilder.control(false),
      announceDateAndTime: this.formBuilder.control(false),
      announceCallerId: this.formBuilder.control(false)
    });
  }

  handleFiles(file: File): void {
    const greetingFile = this.formGroup.get("greetingFile");
    greetingFile?.setValue(file.name);
    greetingFile?.markAsTouched();
    greetingFile?.markAsDirty();
    this.uploadedFile = file;
  }

  saveBox(): void {
    const formValue = this.formGroup.getRawValue();
    if (this.formGroup.get("greetingFile")?.touched && this.formGroup.get("greetingFile")?.dirty) {
      if (formValue.greetingFile !== this.originalGreetingFile) {
        // add or replace
        this.voicemailService
          .advVoicemailboxGreetingUpload(
            "UnavailableGreeting",
            this.voicemailbox.advVoicemailboxId,
            this.uploadedFile
          )
          .then(result => {
            if (result.status === "error") {
              this.snackbar.openSnackBar(
                "To save, make sure file is wav format and less than 1 min long",
                "error"
              );
            }
          });
      } else {
        this.voicemailService.advVoicemailboxGreetingUpload(
          "UnavailableGreeting",
          this.voicemailbox.advVoicemailboxId
        );
      }
    }

    if (this.hasUpdatedVoicemailParams(formValue)) {
      const parameters = {
        // params not modified in the settings modal
        AdvVoicemailboxId: this.voicemailbox.advVoicemailboxId,
        Name: this.voicemailbox.name,
        Mailbox: this.voicemailbox.mailbox,
        Pager: this.voicemailbox.pager,
        Timezone: this.voicemailbox.timezone,
        TransferAddressUsername: this.transferAddressUsername || "",
        // params that are modified in the settings modal
        "EmailOptions[attach]":
          formValue.notifyEmail === NotifyEmailOption.EMAIL
            ? NotifyEmailOption.NO
            : formValue.notifyEmail,
        Email: formValue.email,
        Password: formValue.password as number,
        Mwi: formValue.mwi ? this.defaultUserAgentAor : "",
        SayDuration: formValue.announceDuration,
        SayDateTime: formValue.announceDateAndTime,
        SayCallerId: formValue.announceCallerId
      };

      this.voicemailService
        .advVoicemailboxEdit(parameters)
        .then(res => {
          if (res.status !== "error") {
            this.handleSuccess();
          } else {
            const error = res.status === "error" ? res.data.message : "";
            this.handleError(error);
          }
        })
        .catch(() => {
          this.handleError();
        });
    }
  }

  toggleUnavailableMessage(checked: boolean): void {
    this.hasMessages = checked;
    if (!checked) {
      const greetingFile = this.formGroup.get("greetingFile");
      greetingFile?.setValue("");
      greetingFile?.markAsTouched();
      greetingFile?.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private hasUpdatedVoicemailParams(params: VoicemailSettingInfo): boolean {
    const paramNotifyEmail =
      params.notifyEmail === NotifyEmailOption.EMAIL ? NotifyEmailOption.NO : params.notifyEmail;
    return (
      this.voicemailbox.emailOptions.attach !== paramNotifyEmail ||
      this.voicemailbox.email !== params.email ||
      !!this.voicemailbox.mwi !== params.mwi ||
      parseInt(this.voicemailbox.password) !== params.password ||
      this.voicemailbox.sayCallerId !== params.announceCallerId ||
      this.voicemailbox.sayDateTime !== params.announceDateAndTime ||
      this.voicemailbox.sayDuration !== params.announceDuration
    );
  }

  private handleSuccess(): void {
    this.snackbar.openSnackBar(
      this.translate.instant("ONSIP_I18N.YOUR_CHANGES_HAVE_BEEN_SAVED"),
      "success"
    );
    this.dialogRef.close();
  }

  private handleError(error?: string) {
    error =
      error ||
      (this.translate.instant("ONSIP_I18N.THERE_WAS_AN_ERROR_SAVING_YOUR_INFORMATION") as string);
    this.snackbar.openSnackBar(error, "error");
    this.voicemailService.clearErrors();
  }
}
