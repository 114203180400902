import { ApiContact, apiContactToContact, Contact } from "../../apiResponse/contact";
import {
  ApiCreditCard,
  apiCreditCardToCreditCard,
  CreditCard
} from "../../apiResponse/credit-card";
import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "../../apiResponse/xml-json";

export declare interface ApiAccount {
  AccountId: string;
  AgentId?: string;
  Balance: string;
  BalanceSeconds: string;
  Contact: ApiContact;
  Created: string;
  CreditCard?: ApiCreditCard;
  CreditLimit: string;
  EscrowSecondsCredit: string;
  EscrowSecondsUpdate: string;
  InvoicedPrepaid: OnsipAPIBoolean;
  MeteredUserPricing: OnsipAPIBoolean;
  Modified: string;
  NoPackageAssignment: OnsipAPIBoolean;
  NoPrepaidCredit: OnsipAPIBoolean;
  NoTermination: OnsipAPIBoolean;
  PerUserPricing: OnsipAPIBoolean;
  Productcode: string;
  Promocode: string;
  Receivable: string;
  ReceivableIncentiveMonth: OnsipAPIBoolean;
  Recharge: OnsipAPIBoolean;
  RechargeLevel: string;
  RechargeThreshold: string;
  ResellerBilling: OnsipAPIBoolean;
  UnlimitedRebornUserPricing: OnsipAPIBoolean;
  UnlimitedUltimateUserPricing: OnsipAPIBoolean;
  UnlimitedUserPricing: OnsipAPIBoolean;
  UserAddressLimit: string;
}

export declare interface Account {
  accountId: string;
  agentId?: string;
  balance: string;
  balanceSeconds: string;
  contact: Contact;
  creditCard?: CreditCard;
  creditLimit: string;
  escrowSecondsCredit: string;
  escrowSecondsUpdate: string;
  invoicedPrepaid: boolean;
  meteredUserPricing: boolean;
  noPackageAssignment: boolean;
  noPrepaidCredit: boolean;
  noTermination: boolean;
  perUserPricing: boolean;
  productcode: string;
  promocode: string;
  receivable: string;
  receivableIncentiveMonth: boolean;
  recharge: boolean;
  rechargeLevel: string;
  rechargeThreshold: string;
  resellerBilling: boolean;
  unlimitedRebornUserPricing: boolean;
  unlimitedUltimateUserPricing: boolean;
  unlimitedUserPricing: boolean;
  userAddressLimit: string;
  created: string;
}

export function apiAccountToAccount(apiAccount: ApiAccount): Account {
  const account: Account = {
    accountId: apiAccount.AccountId,
    balance: apiAccount.Balance,
    balanceSeconds: apiAccount.BalanceSeconds,
    contact: apiContactToContact(apiAccount.Contact),
    creditLimit: apiAccount.CreditLimit,
    escrowSecondsCredit: apiAccount.EscrowSecondsCredit,
    escrowSecondsUpdate: apiAccount.EscrowSecondsUpdate,
    invoicedPrepaid: onsipApiBooleanToBoolean(apiAccount.InvoicedPrepaid),
    meteredUserPricing: onsipApiBooleanToBoolean(apiAccount.MeteredUserPricing),
    noPackageAssignment: onsipApiBooleanToBoolean(apiAccount.NoPackageAssignment),
    noPrepaidCredit: onsipApiBooleanToBoolean(apiAccount.NoPrepaidCredit),
    noTermination: onsipApiBooleanToBoolean(apiAccount.NoTermination),
    perUserPricing: onsipApiBooleanToBoolean(apiAccount.PerUserPricing),
    productcode: apiAccount.Productcode,
    promocode: apiAccount.Promocode,
    receivable: apiAccount.Receivable,
    receivableIncentiveMonth: onsipApiBooleanToBoolean(apiAccount.ReceivableIncentiveMonth),
    recharge: onsipApiBooleanToBoolean(apiAccount.Recharge),
    rechargeLevel: apiAccount.RechargeLevel,
    rechargeThreshold: apiAccount.RechargeThreshold,
    resellerBilling: onsipApiBooleanToBoolean(apiAccount.ResellerBilling),
    unlimitedRebornUserPricing: onsipApiBooleanToBoolean(apiAccount.UnlimitedRebornUserPricing),
    unlimitedUltimateUserPricing: onsipApiBooleanToBoolean(apiAccount.UnlimitedUltimateUserPricing),
    unlimitedUserPricing: onsipApiBooleanToBoolean(apiAccount.UnlimitedUserPricing),
    userAddressLimit: apiAccount.UserAddressLimit,
    created: apiAccount.Created
  };

  if (apiAccount.AgentId) {
    account.agentId = apiAccount.AgentId;
  }

  return apiAccount.CreditCard
    ? { ...account, ...{ creditCard: apiCreditCardToCreditCard(apiAccount.CreditCard) } }
    : account;
}

export type AddAccountParams = AddAccountBasicPlan | AddAccountUnlimitedPlan;

export interface AddAccountContacts {
  Name: string;
  Company?: string;
  Email: string;
  Phone: string;
  Address: string;
  City: string;
  State: string;
  Zipcode: string;
  Domain: string;
  Username: string;
}

interface AddAccountBasicPlan extends AddAccountContacts {
  PackageId: string;
}

interface AddAccountUnlimitedPlan extends AddAccountContacts {
  PerUserPricing: "unlimited";
}
