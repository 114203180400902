<table
  mat-table
  class="agents-details-table"
  multiTemplateDataRows
  [dataSource]="displayedQueueAgents"
>
  <ng-container matColumnDef="agent-name">
    <td mat-cell *matCellDef="let agent" class="wide-column">
      <span class="agent-name-block">
        <onsip-avatar [avatarUrl]="agent.avatarUrl" [name]="agent.name"></onsip-avatar>
        <div class="presence-icons">
          @if (agent.callStatus === 'Available') {
          <mat-icon class="agent-presence-available">check</mat-icon>
          } @if (agent.callStatus === 'On Call') {
          <mat-icon class="agent-presence-oncall">call</mat-icon>
          }
        </div>
        <div class="agent-name-time">
          <div class="agent-name-display">{{ agent.name }} &nbsp;</div>
          @if (agent.eventTime && agent.callStatus === 'On Call') {
          <div class="agent-call-time">
            <onsip-timer [referencePoint]="agent.eventTime" [hoursZeroes]="false"></onsip-timer>
          </div>
          }
        </div>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="agent-last-login">
    <td mat-cell *matCellDef="let agent">
      <span class="agent-last-login-text">
        {{ "ONSIP_I18N.LAST_LOGIN" | translate }}: &nbsp;
        <div [innerHTML]="agent.lastLogin | datetime"></div>
      </span>
    </td>
  </ng-container>
  <!-- Desktop View -->
  <ng-container matColumnDef="agent-detailed-buttons">
    <td mat-cell *matCellDef="let agent" class="agent-call-action-buttons">
      @if (agent.callStatus === 'On Call') {
      <div>
        @if (!hasDropdownEavesdrop) {
        <span>
          <button
            mat-button
            color="primary"
            id="onsip--realtime-agent-table--{{ agent.name }}-listen-in"
            tabindex="0"
            class="agent-listen-in"
            [disabled]="!canEavesdrop(agent) || eavesdropConnectingState"
            [title]="eavesdropButtonTitle(agent)"
            [class.selected]="
              eavesdropInfo.status === 'eavesdrop' && this.eavesdropInfo.agentUri === agent.uri
            "
            (click)="onEavesdropClick(agent, 'eavesdrop')"
          >
            <img
              [src]="handleEavesdropIcons(agent, 'eavesdrop', 'ListenIn')"
              alt="Eavesdrop Image"
            />
            &nbsp;
            {{
              (eavesdropInfo.status === "eavesdrop" && this.eavesdropInfo.agentUri === agent.uri
                ? "ONSIP_I18N.CANCEL"
                : "ONSIP_I18N.LISTEN_IN"
              ) | translate
            }}
          </button>
          <button
            mat-button
            color="primary"
            id="onsip--realtime-agent-table--{{ agent.name }}-whisper"
            tabindex="0"
            class="agent-whisper"
            [disabled]="!canEavesdrop(agent) || eavesdropConnectingState"
            [class.selected]="
              eavesdropInfo.status === 'agent' && this.eavesdropInfo.agentUri === agent.uri
            "
            (click)="onEavesdropClick(agent, 'agent')"
          >
            <img [src]="handleEavesdropIcons(agent, 'agent', 'Whisper')" alt="Whisper Image" />
            &nbsp;
            {{
              (eavesdropInfo.status === "agent" && this.eavesdropInfo.agentUri === agent.uri
                ? "ONSIP_I18N.CANCEL"
                : "ONSIP_I18N.WHISPER"
              ) | translate
            }}
          </button>
          <button
            mat-button
            color="primary"
            class="agent-barge"
            id="onsip--realtime-agent-table--{{ agent.name }}-barge"
            tabindex="0"
            [disabled]="!canEavesdrop(agent) || eavesdropConnectingState"
            [class.selected]="
              eavesdropInfo.status === 'both' && this.eavesdropInfo.agentUri === agent.uri
            "
            (click)="onEavesdropClick(agent, 'both')"
          >
            <img [src]="handleEavesdropIcons(agent, 'both', 'Barge')" alt="Barge Image" />
            &nbsp;
            {{
              (eavesdropInfo.status === "both" && this.eavesdropInfo.agentUri === agent.uri
                ? "ONSIP_I18N.CANCEL"
                : "ONSIP_I18N.BARGE"
              ) | translate
            }}
          </button>
        </span>
        }
        <!-- Mobile View -->
        @if (hasDropdownEavesdrop) {
        <span class="eavesdrop-dropdown">
          <button
            mat-icon-button
            id="onsip--realtime-agent-table--toggle-eavesdrop"
            tabindex="0"
            [matMenuTriggerFor]="menu"
            class="eavesdrop-menu-button"
            (click)="onEavesdropDropdownClick(agent)"
            aria-label="eavesdrop buttons"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-template matMenuContent>
              <button
                mat-menu-item
                id="onsip--realtime-agent-table--{{ agent.name }}-listen-in"
                tabindex="0"
                class="agent-listen-in"
                [disabled]="!canEavesdrop(agent) || eavesdropConnectingState"
                [title]="eavesdropButtonTitle(agent)"
                [class.selected]="
                  eavesdropInfo.status === 'eavesdrop' && this.eavesdropInfo.agentUri === agent.uri
                "
                (click)="onEavesdropClick(agent, 'eavesdrop'); $event.stopPropagation()"
              >
                <img
                  [src]="handleEavesdropIcons(agent, 'eavesdrop', 'ListenIn')"
                  alt="Eavesdrop Image"
                />
                &nbsp;
                {{
                  (eavesdropInfo.status === "eavesdrop" && this.eavesdropInfo.agentUri === agent.uri
                    ? "ONSIP_I18N.CANCEL"
                    : "ONSIP_I18N.LISTEN_IN"
                  ) | translate
                }}
              </button>
              <button
                mat-menu-item
                id="onsip--realtime-agent-table--{{ agent.name }}-whisper"
                tabindex="0"
                class="agent-whisper"
                [disabled]="!canEavesdrop(agent) || eavesdropConnectingState"
                [class.selected]="
                  eavesdropInfo.status === 'agent' && this.eavesdropInfo.agentUri === agent.uri
                "
                (click)="onEavesdropClick(agent, 'agent'); $event.stopPropagation()"
              >
                <img [src]="handleEavesdropIcons(agent, 'agent', 'Whisper')" alt="Whisper Image" />
                &nbsp;
                {{
                  (eavesdropInfo.status === "agent" && this.eavesdropInfo.agentUri === agent.uri
                    ? "ONSIP_I18N.CANCEL"
                    : "ONSIP_I18N.WHISPER"
                  ) | translate
                }}
              </button>
              <button
                mat-menu-item
                id="onsip--realtime-agent-table--{{ agent.name }}-barge"
                tabindex="0"
                class="agent-barge"
                [disabled]="!canEavesdrop(agent) || eavesdropConnectingState"
                [class.selected]="
                  eavesdropInfo.status === 'both' && this.eavesdropInfo.agentUri === agent.uri
                "
                (click)="onEavesdropClick(agent, 'both'); $event.stopPropagation()"
              >
                <img [src]="handleEavesdropIcons(agent, 'both', 'Barge')" alt="Barge Image" />
                &nbsp;
                {{
                  (eavesdropInfo.status === "both" && this.eavesdropInfo.agentUri === agent.uri
                    ? "ONSIP_I18N.CANCEL"
                    : "ONSIP_I18N.BARGE"
                  ) | translate
                }}
              </button>
            </ng-template>
          </mat-menu>
        </span>
        }
      </div>
      } @if (agent.loggedIn && agent.callStatus !== 'On Call') {
      <button
        mat-button
        color="primary"
        id="onsip--realtime-agent-table--{{ agent.name }}-logout"
        tabindex="0"
        (click)="remoteLogOut(selectedQueue.uri, agent.uri)"
      >
        @if (!isMobile) {
        <div>{{ "ONSIP_I18N.LOG_OUT" | translate }}</div>
        } @if (isMobile) {
        <img [src]="'resources/img/queue-status-page-icons/LogOut.svg'" alt="Log Out Image" />
        }
      </button>
      } @if (!agent.loggedIn && agent.callStatus !== 'On Call') {
      <button
        mat-button
        color="primary"
        id="onsip--realtime-agent-table--{{ agent.name }}-login"
        tabindex="0"
        (click)="remoteLogIn(selectedQueue.uri, agent.uri, agent.name)"
      >
        @if (!isMobile) {
        <div>{{ "ONSIP_I18N.LOG_IN" | translate }}</div>
        } @if (isMobile) {
        <img [src]="'resources/img/queue-status-page-icons/LogInAgent.svg'" alt="Log In Image" />
        }
      </button>
      }
    </td>
  </ng-container>
  <tr
    mat-row
    *matRowDef="let agent; columns: ['agent-name', 'agent-last-login', 'agent-detailed-buttons']"
    class="agent-row"
  ></tr>
</table>
@if (selectedQueue.orderedAgents && selectedQueue.orderedAgents.length > pageSize) {
<mat-paginator
  #paginator
  [length]="selectedQueue.orderedAgents.length"
  [pageSize]="pageSize"
  (page)="onPageChanged($event)"
  [hidePageSize]="true"
  onsip-paginator-primary-icon
></mat-paginator>
} @if (filteredQueueAgents.length === 0) {
<div class="empty-state">
  <span class="mat-body-1">{{ "ONSIP_I18N.THERE_ARE_NO_QUEUE_AGENTS" | translate }}</span>
</div>
}
