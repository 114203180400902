import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  FormControl,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from "@angular/material/dialog";
import { Router, RouterModule } from "@angular/router";

import { Observable, Subscription } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

import { listOfStates } from "../../../../../common/interfaces/state";
import {
  E911Location,
  E911LocationParams
} from "../../../../../common/services/api/resources/e911/e911-location";
import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import {
  UserAddress,
  UserAddressService
} from "../../../../../common/services/api/resources/userAddress/user-address.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { UserAddressE911Info } from "../e911Table/e911-table.component";
import { LoadingModalComponent } from "../../../shared/components/loadingModal/loading-modal.component";
import { SnackbarService } from "../../../shared/components/snackbar/snackbar.service";
import { E911Service } from "../../../../../common/services/api/resources/e911/e911.service";
import { views } from "../../../../app/phone/views";
import { AdminE911BaseFormComponent } from "../e911-base-form.component";
import { CommonModule } from "@angular/common";
import { OnsipFormsModule } from "@onsip/web/features/shared/components/forms/onsip-forms.module";
import { SelectionListDeluxeModule } from "@onsip/web/features/shared/components/pickers/selectionListDeluxe/selection-list-deluxe.module";
import { MatStepperModule } from "@angular/material/stepper";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { FormEventTrackingModule } from "@onsip/web/features/shared/components/analytics/form-event-tracking.module";
import { PipesModule } from "@onsip/web/features/shared/pipes/pipes.module";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { ApiPromiseSubject } from "@onsip/common/services/api/api-promise-state.service";

enum AddLocationModalSteps {
  "AddLocationForm",
  "AssignUsersChecklist"
}

@Component({
  templateUrl: "./add-location-modal.component.html",
  styleUrls: ["./add-location-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    OnsipFormsModule,
    SelectionListDeluxeModule,
    MatStepperModule,
    MatDialogModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    FormEventTrackingModule,
    PipesModule,
    RouterModule,
    MatListModule
  ]
})
export class AdminE911AddLocationModalComponent
  extends AdminE911BaseFormComponent
  implements OnInit, OnDestroy
{
  /** determine current page of stepper: 0 for Add Location Form Field, 1 for Assign Users Checklist */
  currentIndex = AddLocationModalSteps.AddLocationForm;

  listOfStates = listOfStates.sort(([, symbolA], [, symbolB]) => (symbolA < symbolB ? -1 : 1));

  usersList!: Array<UserAddressE911Info>;
  addWithUsersFlag = false;

  isMobile!: Observable<boolean>;

  /** hidden form field solely used for autocompleting user's state */
  autoCompleteState = new FormControl();

  addPrivateLocation = false;
  protected currentE911Params!: E911LocationParams;
  protected unsubscriber = new Subscription();
  protected loadingModal!: MatDialogRef<LoadingModalComponent>;
  private e911LocCache!: Record<string, E911Location>;
  private currentE911LocationId!: string;

  constructor(
    protected translate: TranslateService,
    protected fb: NonNullableFormBuilder,
    private userService: UserService,
    private userAddressService: UserAddressService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<AdminE911AddLocationModalComponent>,
    private router: Router,
    private e911Service: E911Service,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA)
    private data: { addPrivateLocation: boolean; userId: string; orgId: string }
  ) {
    super(translate, fb);
  }

  // hack: this is technically outside of the form so formControlName doesn't play nice- it's related to the stepper
  get assignUsersFormControl(): FormControl {
    return this.additionalParams.controls.assignUsers as FormControl;
  }

  ngOnInit() {
    this.additionalParams.addControl("locationType", new FormControl("", Validators.required));
    this.additionalParams.addControl("assignUsers", new FormControl([]));
    if (this.data) {
      this.addPrivateLocation = this.data.addPrivateLocation;
      this.additionalParams.patchValue({
        locationType: "Private"
      });
      this.unsubscriber.add(
        this.userAddressService.selfUser
          .pipe(withLatestFrom(this.userService.selfUser))
          .subscribe(([userAdd, user]) => {
            if (userAdd.length) {
              const userE911Info: UserAddressE911Info = {
                aor: userAdd[0].aor,
                e911LocationId: userAdd[0].e911LocationId,
                name: userAdd[0].name,
                userId: user.userId,
                e911Provisioning: user.e911Provisioning || false
              };
              this.additionalParams.patchValue({
                assignUsers: [userE911Info]
              });
            }
          })
      );
    }
    this.unsubscriber.add(
      this.userAddressService.orgState
        .pipe(withLatestFrom(this.userService.orgState))
        .subscribe(([userAdds, users]) => {
          this.usersList = [];
          users.forEach(user => {
            const userAddress = userAdds.find(userAdd => user.userId === userAdd.userId);
            if (userAddress) {
              this.usersList.push({
                aor: userAddress.aor,
                e911LocationId: userAddress.e911LocationId,
                name: userAddress.name,
                userId: userAddress.userId,
                e911Provisioning: user.e911Provisioning || false
              });
            }
          });
          this.cdRef.markForCheck();
        })
    );

    this.isMobile = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map(result => result.matches));

    // use cache to figure what the newly created e911Location is
    this.unsubscriber.add(
      this.e911Service.orgState.subscribe(e911Locs => {
        if (!this.e911LocCache) {
          this.e911LocCache = {};
          e911Locs.forEach(e911Loc => (this.e911LocCache[e911Loc.e911LocationId] = e911Loc));
        } else {
          e911Locs.forEach(e911Loc => {
            if (!this.e911LocCache[e911Loc.e911LocationId]) {
              this.e911LocCache[e911Loc.e911LocationId] = e911Loc;
              this.currentE911LocationId = e911Loc.e911LocationId;
            }
          });
        }
      })
    );
    this.unsubscriber.add(
      this.autoCompleteState.valueChanges.subscribe(value => {
        this.e911FormGroup.controls.state.setValue(value);
      })
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  getDisplayedAddress(): string {
    let primaryAddress: string = this.e911FormGroup.controls.address.value;
    const secondaryAddressType = this.e911FormGroup.controls.secondaryAddressType.value;
    const secondaryAddressNumber = this.e911FormGroup.controls.secondaryAddressNumber.value;
    if (secondaryAddressType || secondaryAddressNumber) {
      primaryAddress = primaryAddress.concat(", ");
      if (secondaryAddressType) primaryAddress = primaryAddress.concat(secondaryAddressType);
      if (secondaryAddressNumber) {
        primaryAddress = primaryAddress.concat(` ${secondaryAddressNumber}`);
      }
    }
    return primaryAddress;
  }

  goBack(): void {
    this.currentIndex = AddLocationModalSteps.AddLocationForm;
  }

  goNext(): void {
    if (this.addPrivateLocation) {
      this.addWithUsers();
    } else {
      this.currentIndex = AddLocationModalSteps.AssignUsersChecklist;
    }
  }

  saveForm(): void {
    if (this.e911FormGroup.valid) {
      if (this.addWithUsersFlag) {
        this.addWithUsers();
      } else {
        this.addWithoutUsers();
      }
    }
  }

  setAddUsersFlag(value: boolean): void {
    this.addWithUsersFlag = value;
  }

  addWithoutUsers(): void {
    this.loadingModal = this.dialog.open(LoadingModalComponent);
    this.handleAddE911LocationParams(() => this.closingSteps());
  }

  addWithUsers(): void {
    // touch form field to show error if field is empty
    this.assignUsersFormControl.markAsTouched();
    if (this.assignUsersFormControl.valid) {
      this.loadingModal = this.dialog.open(LoadingModalComponent);
      this.handleAddE911LocationParams(() => this.handleAssigningUsers());
    }
  }

  private handleAddE911LocationParams(nextSteps: () => void): void {
    // splitting address by the first space to get street number and name for api
    const e911Address: string = this.e911FormGroup.controls.address.value;
    const StreetNumber = e911Address.substring(0, e911Address.indexOf(" "));
    const StreetName = e911Address.substring(e911Address.indexOf(" ") + 1);
    let params: E911LocationParams = {
      City: this.e911FormGroup.controls.city.value,
      Country: "US", // US only option for now
      LocationName: this.e911FormGroup.controls.name.value,
      LocationType: this.additionalParams.controls.locationType.value,
      State: this.e911FormGroup.controls.state.value,
      StreetName,
      StreetNumber,
      Zipcode: this.e911FormGroup.controls.zipcode.value
    };
    if (this.e911FormGroup.controls.secondaryAddressType.value) {
      params = { ...params, AddressType: this.e911FormGroup.controls.secondaryAddressType.value };
    }
    if (this.e911FormGroup.controls.secondaryAddressNumber.value) {
      params = {
        ...params,
        AddressTypeNumber: this.e911FormGroup.controls.secondaryAddressNumber.value
      };
    }
    if (this.e911FormGroup.controls.email.value) {
      params = {
        ...params,
        NotificationEmail: this.e911FormGroup.controls.email.value
      };
    }
    if (this.additionalParams.controls.locationType.value === "Private") {
      params = {
        ...params,
        UserId: this.additionalParams.controls.assignUsers.value[0].userId
      };
    }
    this.currentE911Params = params;
    if (this.addPrivateLocation) {
      this.e911Service.e911LocationAddWithoutOrgId(this.data.orgId, params).then(result => {
        this.handleAddLocationResult(result, nextSteps);
      });
    } else {
      this.e911Service.e911LocationAdd(params).then(result => {
        this.handleAddLocationResult(result, nextSteps);
      });
    }
  }

  private handleAddLocationResult(
    result: ApiPromiseSubject<E911Location>,
    nextSteps: () => void
  ): void {
    if (result.status === "success") {
      const e911Location = Object.values(result.data)[0];
      this.currentE911LocationId = e911Location.e911LocationId;
      nextSteps();
    } else {
      // turn on api error flag if there are any
      // this will show the contact support error message on top of the component
      this.hasApiError = true;
      this.handleApiErrors(result.data);
      this.currentE911LocationId = "";
      this.loadingModal.close();
      const errorMessage = result.data.message;
      this.snackbar.openSnackBar(errorMessage, "error");
      this.e911Service.clearErrors();
      this.currentIndex = AddLocationModalSteps.AddLocationForm;
      this.cdRef.markForCheck();
    }
  }

  private handleAssigningUsers(): void {
    const pendingUsers: Array<UserAddressE911Info> = this.assignUsersFormControl.value;
    if (pendingUsers.length === 0 || !this.currentE911LocationId) {
      this.closingSteps();
      return;
    }
    Promise.all(
      pendingUsers.map(user => {
        if (user.e911Provisioning) {
          return this.e911Service.e911LocationAssign({
            Address: user.aor,
            E911LocationId: this.currentE911LocationId
          });
        } else {
          return this.userService
            .userEditE911Provisioning({ Enabled: true, UserId: user.userId })
            .then(result => {
              if (result.status === "success") {
                return this.e911Service.e911LocationAssign({
                  Address: user.aor,
                  E911LocationId: this.currentE911LocationId
                });
              } else {
                this.snackbar.openSnackBar(
                  `Could not provision E911 to ${user.name}. Please try again.`,
                  "error"
                );
                this.userService.clearErrors();
                return Promise.reject(result.data.message);
              }
            });
        }
      })
    ).then(async results => {
      if (results.every(res => res.status === "success")) {
        // call read api on current location to get updated assigned user count
        await this.e911Service.e911LocationRead(this.currentE911LocationId);
        if (this.addPrivateLocation) {
          this.userAddressService.userAddressBrowse().then(result => {
            this.handleUserAddressResult(result);
          });
        } else {
          this.userAddressService.userAddressBrowseWithOrgId().then(result => {
            this.handleUserAddressResult(result);
          });
        }
      }
    });
  }

  private handleUserAddressResult(result: ApiPromiseSubject<UserAddress>) {
    if (result.status === "success") {
      this.closingSteps();
    } else {
      this.snackbar.openSnackBar(
        "Could not update users with new E911 locations. Please try again",
        "error"
      );
      this.userAddressService.clearErrors();
    }
  }

  private closingSteps(): void {
    this.dialogRef.close();
    if (!this.addPrivateLocation) this.router.navigate([views.E911_TABLE]);
    // close loading modal
    this.loadingModal.close();
    this.snackbar.openSnackBar(
      this.translate.instant("ONSIP_I18N.VALUE_HAS_BEEN_ADDED", { value: "Location" }),
      "success"
    );
  }
}
